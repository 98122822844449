/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'statue-of-liberty': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M370.164 482v-62h-30v-72.436c17.17-12.577 27.334-23.841 30-26.351v-62.574l71.672-71.672-63.639-63.64-16.062 16.062C354.001 127.684 340.467 120 325.164 120h-2.58a44.79 44.79 0 002.58-15V90h30V60h-38.787l16.82-16.82-21.213-21.213-16.82 16.82V0h-30v38.787l-16.82-16.82-21.213 21.213L243.951 60h-38.787v30h30v15a44.79 44.79 0 002.58 15H70.164v45c0 24.813 20.187 45 45 45h15v24.271l60 30V390h20.147c3.294 0 6.578-.082 9.853-.227V420h-30v62h-60v30h300v-30zm-255-302c-8.271 0-15-6.729-15-15v-15h30v30zm105 179.75v-37.478c43.985-1.946 85.707-17.088 120-42.209v28.587c-32.922 30.917-75.014 48.817-120 51.1zm93.949-130.801l18.724 18.724c-31.734 26.9-70.958 42.455-112.672 44.574v-37.474a219.837 219.837 0 0093.948-25.824zm85.297-41.982l-42.427 42.426-21.213-21.213 42.427-42.426zM265.164 105V90h30v15c0 8.271-6.729 15-15 15s-15-6.729-15-15zm60 45c7.147 0 13.133 5.028 14.63 11.73l-41.798 41.798a189.798 189.798 0 01-77.832 21.188V150zm-165 65.729V150h30v80.729zm90 170.634c20.765-3.824 40.87-10.614 60-20.348V420h-60zm-30 63.637h120v32h-120z"/><path pid="1" d="M160.164 75c0-6.448-1.829-17.677-17.646-40.643-9.616-13.962-17.219-22.425-27.354-34.354-10.168 11.969-17.747 20.406-27.354 34.354C71.993 57.323 70.164 68.552 70.164 75c0 24.813 20.187 45 45 45s45-20.187 45-45zm-45 15c-8.271 0-15-6.729-15-14.977.15-4.257 6.488-15.543 15-27.457 8.506 11.906 14.841 23.185 15 27.449-.008 8.264-6.734 14.985-15 14.985z"/>'
  }
})
