<template>
  <div class="carousel carousel--catalog">
    <button class="carousel__button carousel__button--previous" title="Previous" aria-label="Previous" @click="prev()">
      <svgicon name="chevron-left" class="icon"></svgicon>
    </button>
    <slick ref="slick" :options="slickOptions">
      <div v-for="product in els" itemscope="itemscope" itemtype="http://schema.org/Product" class="product__summary">
        <a :href="product.route" itemprop="url" class="product__summary__link" :title="product.title + ' - ' + product.type">
          <div class="product__summary__media">
            <div class="product__summary__media__wrapper">
              <img :src="product.picture" :alt="product.title + ' - ' + product.type" itemprop="image" class="product__summary__media__image">
            </div>
          </div>
          <div class="product__summary__info">
            <p itemprop="name" class="product__summary__name">{{ product.title }}</p>
            <p class="product__summary__type">{{ product.type }}</p>
          </div>
        </a>
      </div>
    </slick>
    <button class="carousel__button carousel__button--next" title="Next" aria-label="Next" @click="next()">
      <svgicon name="chevron-right" class="icon"></svgicon>
    </button>
  </div>
</template>

<script>

  import Slick from 'vue-slick'

  export default {

    name: 'ProductSlider',
    props: ['els'],

    data() {
      return {
        slickOptions: {
          arrows: false,
          infinite: true,
          slidesToShow: 8,
          slidesToScroll: 4,
          zIndex: 1,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 3,
                swipe: true,
                touchThreshold: 10,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                swipe: true,
                touchThreshold: 10,
              },
            },
            {
              breakpoint: 544,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                swipe: true,
                touchThreshold: 10,
              },
            },
          ],
        },
        imagePath: process.env.IMAGES,
        inspiration: this.type==='inspiration'
      }
    },

    components: {Slick},

    methods: {
      next() {
        this.$refs.slick.next()
      },
      prev() {
        this.$refs.slick.prev()
      },
    },

    mounted() {
    },

  }

</script>
