/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-right': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M6.2 3.2L19 16 6.2 28.8 9.4 32l16.4-16L9.4 0 6.2 3.2z"/>'
  }
})
