<template>
  <div class="adnkronos-app">
    <loading-module :loading="loading">
      <template v-slot:PageContent>
        <div class="container flex flex-row flex-wrap">
          <div class="w-full lg:w-8/12 lg:border-r-1 border-black-200 p-x-24 lg:p-r-32">
            <h3 class="text-2xl font-light text-left m-b-36">
              Le ultime news
            </h3>
            <article class="relative flex items-center m-b-48 cursor-pointer" v-for="el in els.slice(0, 10)" @click="showModal(el)">
              <div class="hidden sm:block flex-none w-3/12">
                <img decoding="async" :src="el.media.url" class="object-cover max-w-full rounded-8 w-full h-128 shadow-sm">
              </div>
              <div class="flex-auto w-0 sm:p-l-24">
                <span class="uppercase text-micro font-light tracking-widest">{{ el.category }}</span>
                <h4 class="text-lg leading-tight hover:underline">
                  {{ el.title }}
                </h4>
                <div class="text-sm m-t-6 line-clamp-3">{{ stripper(el.description) }}</div>
              </div>
            </article>
            <div class="flex flex-row items-center justify-center m-t-64">
              <div class="lpbutton lpbutton-mini">
                <a class="uppercase" href="https://www.adnkronos.com/">Leggi più notizie</a>
              </div>
            </div>
          </div>
          <div class="hidden lg:block lg:w-4/12 p-l-32">
            <h3 class="text-xl md:text-2xl font-light text-left m-b-36">
              In primo piano
            </h3>
            <article class="relative flex items-center m-b-16 cursor-pointer" v-for="el in els.slice(10)" @click="showModal(el)">
              <div class="flex-auto w-0">
                <span class="uppercase text-micro font-light tracking-widest leading-none">{{ el.category }}</span>
                <h4 class="text-base leading-tight hover:underline">
                  {{ el.title }}
                </h4>
              </div>
            </article>
          </div>
        </div>
      </template>
    </loading-module>
    <modal :name="uid"
           :id="uid"
           :ref="uid"
           classes="max-w-800 m-x-auto"
           height="95%"
           width="95%"
           @before-close="beforeClose">
      <div class="block overflow-y-scroll h-full">
        <div class="relative flex flex-col w-full">
          <div class="flex flex-row w-full justify-end">
            <button class="rounded-full m-t-8 m-r-8" @click.prevent="close()">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m12 10.586l4.95-4.95l1.415 1.415l-4.95 4.95l4.95 4.95l-1.415 1.414l-4.95-4.95l-4.95 4.95l-1.413-1.415l4.95-4.95l-4.95-4.95L7.05 5.638l4.95 4.95Z"/></svg>
            </button>
          </div>
          <template v-if="selected">
            <div class="container">
              <span class="uppercase text-micro font-light tracking-widest">{{ selected.category }}</span>
              <h4 class="text-2xl font-bold">{{ selected.title }}</h4>
              <div class="m-t-8 m-b-16">
                <p class="text-sm">{{ dater(selected.date) }}</p>
                <p class="text-xs" style="color:rgb(240,21,57)">Redazione Adnkronos</p>
              </div>
            </div>
            <div class="block relative w-full">
              <img decoding="async" :src="selected.media.url.replace('/format/medium/', '/format/big/')" class="object-cover max-w-full w-full h-auto shadow-sm">
            </div>
            <div class="container prose m-t-32">
              <div v-html="selected.description" />
            </div>
            <div class="flex flex-row items-center justify-center m-t-32 m-b-48">
              <div class="lpbutton lpbutton-mini">
                <a class="uppercase" :href="selected.link">Leggi su Adnkronos</a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'ADNKronos',
  data() {
    return {
      els: '',
      selected: '',
      loading: false,
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      this.updating = true
        axios.get('/api/news').then((response) => {
          this.els = response.data
          this.loading = false
        }).catch((error) => {
          console.warn('Error')
        })
    },
    showModal(e) {
        this.selected = e
        this.$modal.show(this.uid)
        const scroll = window.pageYOffset
        document.body.style.overflow = 'hidden'
        window.scrollTo(0, scroll)
    },
    // opened(e) {
    //   this.$nextTick(() => {
    //     this.ModalLazyLoad = new LazyLoad({
    //       container: e.ref,
    //       elements_selector: '.lazy',
    //       class_loaded: 'lazy__loaded',
    //       class_loading: 'lazy__loading',
    //       load_delay: 0,
    //       use_native: false
    //     })
    //   })
    // },
    beforeClose() {
      document.body.style.overflow = 'auto'
    },
    close() {
      document.body.style.overflow = 'auto'
      this.$modal.hide(this.uid)
      this.selected = false
    },
    dater(value) {
      return moment.unix(value).format("DD MMM YYYY | hh:mm")
    },
    stripper(value) {
      return value.replace(/(<([^>]+)>)/gi, "");
    }
  }
}
</script>
