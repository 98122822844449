/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apple-pay': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<circle pid="0" cx="256" cy="256" r="256" _fill="#f2f2f2"/><g _fill="#353535"><path pid="1" d="M153.311 291.892c-9.697 22.173-23.698 41.064-37.512 51.921-24.848 19.529-35.495-5.319-58.567-5.319-15.987 0-26.008 11.922-38.922 12.758C6.502 321.807.003 289.656.003 255.999c0-32.559 6.081-63.697 17.168-92.348 18.004 1.735 23.448 10.951 40.061 10.951 18.338 0 23.071-11.243 46.143-11.243 14.973 0 33.479 9.206 46.488 25.36-19.446 8.683-33.008 28.202-33.008 50.887-.001 24 15.181 44.459 36.456 52.286z"/><path pid="2" d="M49.349 161.267c0 1.488.077 2.958.196 4.416 28.579-.945 51.462-24.394 51.462-53.204 0-1.488-.077-2.958-.196-4.416-28.579.944-51.462 24.394-51.462 53.204zM184.098 172.584c0-4.615 4.102-8.718 10.512-8.718h50c31.539 0 56.154 15.128 56.154 56.41v2.051c0 41.026-25.385 56.666-57.693 56.666h-31.795v63.333c0 5.897-6.666 8.975-13.59 8.975-6.666 0-13.589-3.077-13.589-8.975l.001-169.742zm27.179 15.384v69.487h31.795c18.974 0 30.513-10.77 30.513-33.333v-3.077c0-22.564-11.539-33.076-30.513-33.076l-31.795-.001zM397.41 277.967v64.358c0 4.872-5.64 8.975-13.078 8.975-6.922 0-12.307-4.102-12.307-8.975v-8.974c-8.975 10.513-21.281 19.999-38.719 19.999-18.717 0-34.101-10.769-34.101-33.845 0-28.974 24.36-36.667 60-36.667h11.282v-4.615c0-13.077-6.666-27.692-25.385-27.692-18.717 0-23.333 7.693-28.461 7.693-6.41 0-9.744-7.435-9.744-13.59 0-9.231 21.283-16.41 38.719-16.41 37.691 0 51.794 24.103 51.794 49.743zm-26.923 19.744h-6.41c-19.486 0-37.692 2.051-37.692 18.462 0 11.538 7.18 16.667 16.666 16.667 12.82 0 27.436-13.334 27.436-25.642v-9.487zM401.488 244.378c-.769-1.025-1.025-2.051-1.025-3.589 0-6.924 10.512-12.821 17.691-12.821 3.59 0 6.923 1.539 8.206 4.872l32.308 83.333 27.692-83.333c1.539-3.333 4.615-4.872 7.691-4.872 6.41 0 17.949 5.641 17.949 13.334 0 1.025 0 2.307-.512 3.076L473.54 344.634c-11.539 30.257-24.102 64.359-60.512 64.359-6.923 0-12.564-1.795-12.564-11.539 0-6.153 2.051-11.538 7.435-11.538 13.076 0 33.076-13.077 36.667-42.051l-43.078-99.487z"/></g>'
  }
})
