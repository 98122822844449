/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'temple': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M458.667 261.777c29.408 0 53.333-23.925 53.333-53.333v-6.703h-30v6.703c0 12.866-10.467 23.333-23.333 23.333h-19.453l-20.591-57.654v-52.458h10.02c29.408 0 53.333-23.926 53.333-53.334v-6.702h-30v6.702c0 12.866-10.467 23.334-23.333 23.334H406.81L255.5.023 104.19 91.666H83.357c-12.866 0-23.333-10.468-23.333-23.334V61.63h-30v6.702c0 29.408 23.925 53.334 53.333 53.334h10.02v52.458l-20.591 57.654H53.333C40.467 231.777 30 221.31 30 208.444v-6.703H0v6.703c0 29.408 23.925 53.333 53.333 53.333h15.024v140.136H0v110.064h512V401.913h-68.357V261.777zm-70.044-100.056h-30.048v-40.056h30.048zm-60.048-40.055v40.056h-145.15v-40.056zM255.5 35.097l93.401 56.568H162.099zm-102.075 86.569v40.056h-30.048v-40.056zm-34.477 70.055h274.104l14.306 40.056H104.642zm204.599 210.192V261.777h30.048v140.136zM188.453 261.777v140.136h-30.048V261.777zm105.094 41.476c-10.745-7.212-23.662-11.428-37.547-11.428s-26.802 4.215-37.547 11.428v-41.476h75.094zm-75.094 56.119c0-20.703 16.843-37.547 37.547-37.547s37.547 16.844 37.547 37.547v42.541h-75.094zM98.357 261.777h30.048v140.136H98.357zM482 481.977H30v-50.064h452zm-68.357-80.064h-30.048V261.777h30.048z"/>'
  }
})
