/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'credit-card': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M55.783 8H4.217A4.222 4.222 0 0 0 0 12.217v35.566A4.222 4.222 0 0 0 4.217 52h51.566A4.222 4.222 0 0 0 60 47.783V12.217A4.222 4.222 0 0 0 55.783 8zM58 47.783A2.22 2.22 0 0 1 55.783 50H4.217A2.22 2.22 0 0 1 2 47.783V12.217A2.22 2.22 0 0 1 4.217 10h51.566A2.22 2.22 0 0 1 58 12.217v35.566z"/><path pid="1" d="M16 37H7a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2zM29 37h-9a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2zM8 42H7a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM14 42h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM19 42h-1a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2zM25 42h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM28.29 42.29c-.181.19-.29.45-.29.71 0 .26.109.52.29.71.189.18.45.29.71.29s.52-.11.71-.29c.18-.19.29-.45.29-.71 0-.26-.11-.52-.29-.71-.38-.37-1.061-.37-1.42 0zM48 33a6.953 6.953 0 0 0-3.5.947A6.953 6.953 0 0 0 41 33c-3.859 0-7 3.14-7 7s3.141 7 7 7a6.953 6.953 0 0 0 3.5-.947A6.953 6.953 0 0 0 48 47c3.859 0 7-3.14 7-7s-3.141-7-7-7zm-2 7a4.98 4.98 0 0 1-1.5 3.562 4.98 4.98 0 0 1 0-7.124A4.98 4.98 0 0 1 46 40zm-10 0c0-2.757 2.243-5 5-5 .631 0 1.23.13 1.787.345C41.68 36.583 41 38.212 41 40s.68 3.417 1.787 4.655A4.946 4.946 0 0 1 41 45c-2.757 0-5-2.243-5-5zm12 5c-.631 0-1.23-.13-1.787-.345C47.32 43.417 48 41.788 48 40s-.68-3.417-1.787-4.655A4.946 4.946 0 0 1 48 35c2.757 0 5 2.243 5 5s-2.243 5-5 5zM8.255 30h13.49A2.258 2.258 0 0 0 24 27.745v-9.49A2.258 2.258 0 0 0 21.745 16H8.255A2.258 2.258 0 0 0 6 18.255v9.49A2.258 2.258 0 0 0 8.255 30zM12 24H8v-2h4v2zm-4 3.745V26h4v1.745c0 .087.016.17.026.255H8.255A.255.255 0 0 1 8 27.745zM22 24h-4v-1.745c0-.141.114-.255.255-.255h3.49c.087 0 .17-.016.255-.026V24zm-.255 4h-3.771c.01-.085.026-.168.026-.255V26h4v1.745a.255.255 0 0 1-.255.255zm0-10c.141 0 .255.114.255.255v1.49a.255.255 0 0 1-.255.255h-3.49A2.258 2.258 0 0 0 16 22.255v5.49a.255.255 0 0 1-.255.255h-1.49a.255.255 0 0 1-.255-.255v-9.49c0-.141.114-.255.255-.255h7.49zm-13.49 0h3.771c-.01.085-.026.168-.026.255V20H8v-1.745c0-.141.114-.255.255-.255z"/>'
  }
})
