<template>
  <div class="flex flex-1 items-center justify-center space-x-12">
    <span class="font-sans text-sm">Pagina:</span>
    <input type="text"
           v-validate="'required|integer'"
           v-model="page"
           :placeholder="current"
           name="page"
           v-on:keyup.enter="paginate()"
           class="inline-block border-1 border-gray-100 rounded-4 min-h-36 w-36 p-x-8 font-sans text-base leading-none text-primary outline-none text-center"></input>
    <span>di {{ total }}</span>
  </div>
</template>

<script>

import _debounce from 'lodash/debounce'

export default {

  inherit: true,
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      page: this.current,
      locked: true,
    }
  },
  watch: {
    page: _debounce(function (e) {
      if (e > 0 && e !== this.current && e <= this.total) {
        this.locked = false
      }
    }, 500)
  },
  methods: {
    paginate() {
      if (this.locked || this.page === this.current) return;
      window.location.href = this.link + this.page;
    }
  }
}

</script>
