/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dunes': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M512 381.71v-65.772l-1.258-2.88c-15.16-34.628-43.117-67.18-80.855-94.132-38.149-27.242-86.375-48.73-139.461-62.13l-13.586-3.429-4.348 13.32c-7.77 23.817-18.344 45.508-31.933 65.403-32.153-10.543-70.922-12.512-113.004-5.64-43.559 7.113-86.418 23.187-120.68 45.257L0 276.141v105.57zM30.004 292.669c29.66-17.7 65.687-30.613 102.387-36.606 32.191-5.253 62.726-4.656 88.207 1.543-7.246 8.153-15.098 15.98-23.59 23.504-35.535 31.489-78.457 54.192-112.613 70.598H30.004zm263.473-96.094l9.687 8.137a35.68 35.68 0 011.082 53.71l-9.957 9.067c-24.016 21.864-28.043 57.75-10.941 84.219H149.016c60.12-34.86 114.191-80.988 144.46-155.133zm188.52 155.133h-159.4l-10.605-11.773c-13.094-14.54-11.976-37.086 2.492-50.258l9.957-9.067a65.664 65.664 0 0021.454-49.89 65.642 65.642 0 00-6.196-26.528c68.512 27.473 119.824 69.532 142.297 118.106zm0 0M96.688 102.938c28.378 0 51.468-23.09 51.468-51.47S125.066 0 96.687 0C68.305 0 45.22 23.09 45.22 51.469c-.004 28.379 23.086 51.468 51.468 51.468zm0-72.934c11.832 0 21.464 9.629 21.464 21.465s-9.629 21.465-21.465 21.465c-11.84 0-21.464-9.63-21.464-21.465-.004-11.836 9.625-21.465 21.465-21.465zm0 0"/>'
  }
})
