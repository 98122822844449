<template>
  <div v-if="show">
    <transition name="modal-fade">
      <div class="modal__backdrop">
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
          <div class="modal__container">
            <section class="modal__body" id="modalDescription">
              <header class="modal__header" id="modalTitle">
                {{ this.title }}
              </header>
              <button class="modal__close" @click.prevent="close()">
                <svgicon name="xclose" class="icon"></svgicon>
              </button>
              <div class="grid grid__row">
                <span v-html="this.message"></span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

   import {eventsbus} from '../mixins/EventsBus.js'

    export default {

    name: 'GenericModalDialog',

    props: {
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        show: false

    },
    methods: {
      close() {
          this.show = false
          eventsbus.$emit('generic-modal-closed')
      },
    },

  }

</script>
