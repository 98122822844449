<template>
  <div class="flex items-center gap-x-16"
       @click.prevent="play()">
    <a class="block relative w-56 h-56 z-2 bg-blue-500 rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-400">
      <svg class="w-20 h-20 text-white m-l-2">
        <use xlink:href="#play"></use>
      </svg>
    </a>
    <slot></slot>
  </div>
</template>

<script>

import { eventsbus } from '../mixins/EventsBus'

export default {
  name: 'PodcastPlayButton',
  props: {
    payload: {
      required: true,
    },
  },
  data () {
    return {}
  },
  methods: {
    play: function () {
      eventsbus.$emit('SendPodcastPlayer', JSON.stringify(this.payload))
    }
  }
}

</script>
