<template>
  <div class="globalnav__search" @click="showSearch()">
    <svgicon name="search" class="icon icon--white"></svgicon>
    <span class="globalnav__search__text">Ricerca</span>
    <span class="globalnav__search__subtext">in Lonely Planet</span>
  </div>
</template>

<script>

  import {eventsbus} from '../mixins/EventsBus.js'

  export default {

    inherit: true,
    name: 'SearchBox',
    methods: {
      showSearch() {
        eventsbus.$emit('search-input-show', true)
        eventsbus.$emit('fs-overlay', true)
      },
    },
  }

</script>
