<template>
  <div class="display-inline-block position-relative z-50 min-width-320">
    <label id="listbox-label" class="display-block text-12">
      <slot name="SelectLabel"></slot>
    </label>
    <div class="position-relative" v-click-outside="close">
      <span class="display-inline-block border-1 border-solid border-grey-300 rounded-4 width-full">
        <button type="button"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                @click="isOpen = !isOpen"
                class="padding-y-16 width-full cursor-pointer">
              <div class="display-flex items-center text-left padding-x-16">
                <transition name="fade">
                  <div v-if="!loading">
                    <span class="font-family-tertiary font-bold text-16 leading-none text-primary">
                      <template v-if="selected">{{ selected }}</template>
                      <template v-else>
                        <slot name="SelectPlaceholder"></slot>
                      </template>
                    </span>
                    <span class="position-absolute inset-y-0 inset-right-12 display-flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg"
                           class="display-inline-block width-16 text-primary" fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M8 9l4-4 4 4m0 6l-4 4-4-4"/>
                      </svg>
                    </span>
                  </div>
                  <div v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" class="animate-spin display-inline-block width-18 text-azure" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                  </div>
                </transition>
              </div>
        </button>
      </span>
      <transition name="fade">
        <div v-show="isOpen"
             class="position-absolute margin-top-2 width-full max-height-256 overflow-auto bg-white shadow-md rounded-4 border-1 border-solid border-grey-300">
          <ul tabindex="-1"
              aria-labelledby="listbox-label"
              role="listbox"
              class="padding-y-8">
            <li v-for="el in data"
                class="cursor-pointer padding-x-16 padding-y-8 hover:bg-azure hover:text-white display-flex items-center justify-between"
                @click="select(el)"
            >
              <span>{{ el }}</span>
              <template v-if="el===selected">
                <svg xmlns="http://www.w3.org/2000/svg" class="display-inline-block width-16" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                </svg>
              </template>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TailwindDropdown',
  props: ['data'],
  data() {
    return {
      isOpen: false,
      selected: '',
      loading: false
    }
  },
  methods: {
    select(el) {
      this.isOpen = false
      this.selected = this.selected === el ? '' : el
      this.$emit('valueSelect', this.selected)
    },
    close() {
      this.isOpen = false
    }
  }
}

</script>
