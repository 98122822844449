<template>
 <div>

     <div class="property__group">
         <div v-for="itm in checklist">
             <div class="property" v-if="itm.editable">
                 <div class="property__checkbox">
                     <label :for="getElementId(itm.flag_key)" class="property__checkbox__label" v-if="itm.editable" data-children-count="1">
                         <input type="checkbox" :id="getElementId(itm.flag_key)"  :value="itm.flag_key"  v-model="itm.flag_value"  @change="emitCheck()" class="property__checkbox__input">
                         <span class="property__checkbox__box"></span>
                         {{itm.label}}
                     </label>
                 </div>
             </div>
         </div>
     </div>

 </div>
</template>

<script>
  import {eventsbus} from '../mixins/EventsBus.js'
  export default {
    name: 'CheckList',
    props: {
      prop_checklist: {
        required: true,
      }
    },
    data() {
      return {
        checklist: this.prop_checklist
      }
    },
    methods: {
      emitCheck() {
          eventsbus.$emit('checklist-checked', this.checklist)
      },
      getElementId(key) {
          return 'check_item_' + key
      }
    }
  }

</script>
