<template>
  <button class="btn" @click.prevent="add()">{{ label }}</button>
</template>

<script>

import { eventsbus } from '../mixins/EventsBus'

export default {
  name: 'AddToCart',
  props: {
    els: {
      required: true,
    },
    label: {
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    items: function () {
      return this.els instanceof Array ? this.els : [JSON.parse(this.els)]
    },
  },
  methods: {
    add: function () {
      let _this = this
      _this.putInBasket(_this.items, 0, _this.items.length - 1)
    },
    putInBasket: function (items, index, _count) {
      let _this = this
      let options = {
        method: 'POST',
        url: process.env.SHOP_URL + '/api/cart/add',
        data: {
          items: items,
        },
      }
      axios(options).then((res) => {
        if (res.data) {
          _this.carter = res.data
          // Cookie setter
          _this.$cookies.set(process.env.CART_REMOTE_COOKIE_ID, _this.carter.cart.id, null, '/', process.env.CART_REMOTE_COOKIE_DOMAIN, true)
          _this.$cookies.set(process.env.CART_REMOTE_COOKIE_SESSION, _this.carter.cart.cookie_value, null, '/', process.env.CART_REMOTE_COOKIE_DOMAIN, true)
          _this.$cookies.set(process.env.CART_REMOTE_COOKIE_ITEMS, _this.carter.counter, null, '/', process.env.CART_REMOTE_COOKIE_DOMAIN, true)
          eventsbus.$emit('AddedToCart', JSON.stringify(_this.carter))
          _this.sendGACart(items)
        } else {
          console.log('Error on add/retrieving cart')
        }
      })
    },
    sendGACart(els) {
      if (this.carter) {
        let options = {
          method: 'GET',
          url: process.env.SHOP_URL + '/api/cart/get',
        }
        axios(options).then((res) => {
          if (res.data) {
            let cart = res.data
            let items = []
            els.forEach(function (itm, index) {
              items.push({
                item_id: itm.id,
                item_name: itm.title,
                item_ean: itm.ean,
                currency: 'EUR',
                discount: itm.discount_price && (itm.price !== itm.discount_price) ? Math.round((itm.price - itm.discount_price) * 100) / 100 : 0.00,
                index: index,
                item_brand: 'Lonely Planet Italia',
                item_variant: itm.type,
                price: (itm.price * 100) / 100,
                quantity: 1,
              })
            })
            gtag('event', 'add_to_cart', {
              currency: 'EUR',
              value: cart.selling_total,
              items: items
            })
          } else {
            console.log('Error on add/retrieving cart')
          }
        })
      }
    }
  },
}

</script>
