/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bear': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M482.425 165.451c-1.903-8.617-8.156-28.868-26.541-42.304a131.176 131.176 0 00-16.757-10.379c.57-2.049.878-4.202.878-6.424 0-13.732-11.606-24.865-25.923-24.865-12.972 0-23.719 9.139-25.623 21.07h-53.301l-66.331-26.242-65.572 16.946-33.684-1.559a51.606 51.606 0 01-10.418-1.538c-20.827-5.344-73.617-14.708-113.827 13.702C19.043 122.43 3.817 153.04.07 194.836l-.07.783.012.786c.03 2.01.956 49.815 19.286 101.137 15.115 42.323 21.28 71.633 22.496 77.809v60.341h118.56v-53.249l-28.15-12.064 5.876-38.26c22.828 2.519 67.172 5.718 101.502-2.177l8.293 31.088v74.662H357.23V381.55l-29.626-14.468c.886-9.507 2.461-24.614 4.773-40.373 6.588-44.892 13.248-55.088 14.396-56.56 13.702-9.03 16.924-8.574 27.314-7.094 13.493 1.922 50.481-8.246 72.885-14.987l42.006.84L512 208.107l-29.575-42.656zm-10.788 53.065l-28.822-.576-2.281.693c-24.567 7.461-55.399 15.115-62.337 14.666-15.491-2.198-26.529-2.448-48.537 12.155-8.434 5.596-18.183 18.75-26.553 73.887-4.286 28.243-6.244 54.51-6.326 55.614l-.745 10.137 31.157 15.216v5.346h-49.279v-48.563l-17.641-66.126-15.67 6.165c-32.25 12.689-95.265 6.746-116.82 3.386l-14.88-2.329-13.922 90.632 31.336 13.43v3.405H71.831v-33.046l-.231-1.305c-.24-1.352-6.093-33.683-24.014-83.864-15.439-43.23-17.326-84.843-17.519-90.772 3.037-32.169 14-55.139 32.595-68.275 30.809-21.77 75.935-12.499 89.029-9.138a81.673 81.673 0 0016.496 2.448l38.205 1.768 60.484-15.63 62.559 24.749h79.429c4.157 1.255 16.567 5.508 29.299 14.812 12.344 9.02 15.037 24.816 15.21 25.911l.379 3.5 22.835 32.934-4.95 8.77z"/>'
  }
})
