<template>
    <div>

        <div v-html="featuretext" v-if="featuretext" class="feature-text"></div>

        <div id="header-search" class="co-header-search">

            <div class="co-header-search__btn-open" @click.prevent="toggleOnMobileMenu">
                <img src="https://www.earthviaggi.it/themes/custom/earthviaggi/assets/imgs/icons/lente-bianca.svg" alt="ricerca viaggi"> CERCA UN VIAGGIO
            </div>


            <div :class="headerSearchClass">

                <div data-filter="destinazione" :class="destinationsDropDownClass">
                    <div class="cm-header-search__input-group-wrapper" @click.prevent="openDestinationsSubmenu()">
                        <div class="cm-header-search__title">DESTINAZIONE</div>
                        <div class="cm-header-search__subtitle">
                            <span v-if="destination == undefined">dove vuoi andare?</span>
                            <span v-if="destination">{{destination.toUpperCase()}}</span>
                        </div>
                    </div>
                    <div class="cm-header-search__dropdown" data-simplebar="init" v-on:mouseleave="closeDestinationsSubmenu">
                        <div class="simplebar-wrapper" style="margin: 0px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: -12px; bottom: 0px;">
                                    <div class="simplebar-content-wrapper" style="height: auto; overflow: hidden scroll;">
                                        <div class="simplebar-content" style="padding: 0px;">
                                            <ul class="cm-header-search__list cm-header-search__list--0">
                                                <template v-for="dest in destinations">
                                                    <li class="cm-header-search__item cm-header-search__item--0" @click.prevent="setDestination(dest.slug)">
                                                        {{dest.label}}
                                                    </li>
                                                    <ul class="cm-header-search__list cm-header-search__list--1" v-if="dest.destinations">
                                                        <li v-for="subdest in dest.destinations" class="cm-header-search__item cm-header-search__item--1"  @click.prevent="setDestination(subdest.slug)">
                                                            {{subdest.label}}
                                                        </li>
                                                    </ul>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div data-filter="tema" :class="themesDropDownClass">
                    <div class="cm-header-search__input-group-wrapper" @click.prevent="openThemesSubmenu()">
                        <div class="cm-header-search__title">TEMA</div>
                        <div class="cm-header-search__subtitle">
                            <span v-if="theme == undefined">cosa vuoi vedere?</span>
                            <span v-if="theme">{{theme.toUpperCase()}}</span>
                        </div>
                    </div>
                    <div class="cm-header-search__dropdown" data-simplebar="init" v-on:mouseleave="closeThemesSubmenu">
                        <div class="simplebar-wrapper" style="margin: 0px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: -12px; bottom: 0px;">
                                    <div class="simplebar-content-wrapper" style="height: auto; overflow: hidden scroll;">
                                        <div class="simplebar-content" style="padding: 0px;">
                                            <ul class="cm-header-search__list cm-header-search__list--0">
                                                <template v-for="theme in themes">
                                                    <li class="cm-header-search__item cm-header-search__item--0"  @click.prevent="setTheme(theme.slug)">
                                                        {{theme.label}}
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div data-filter="durata" :class="durationsDropDownClass">
                    <div class="cm-header-search__input-group-wrapper" @click.prevent="openDurationsSubmenu()">
                        <div class="cm-header-search__title">DURATA</div>
                        <div class="cm-header-search__subtitle">
                            <span v-if="duration == undefined">quanti giorni?</span>
                            <span v-if="duration">{{duration.toUpperCase()}}</span>
                        </div>
                    </div>

                    <div class="cm-header-search__dropdown" data-simplebar="init" v-on:mouseleave="closeDurationsSubmenu">
                        <div class="simplebar-wrapper" style="margin: 0px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: -12px; bottom: 0px;">
                                    <div class="simplebar-content-wrapper" style="height: auto; overflow: hidden scroll;">
                                        <div class="simplebar-content" style="padding: 0px;">
                                            <ul class="cm-header-search__list cm-header-search__list--0">
                                                <template v-for="duration in durations">
                                                    <li class="cm-header-search__item cm-header-search__item--0"  @click.prevent="setDuration(duration)">
                                                        {{duration}} giorni
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="cm-header-search__input-group cm-header-search__input-group--search">
                    <div class="cm-header-search__input-group-wrapper" @click.prevent="doSearch">
                        <span class="cm-header-search__title">INIZIA RICERCA</span>
                        <img src="https://www.earthviaggi.it/themes/custom/earthviaggi/assets/imgs/icons/lente.svg" alt="ricerca viaggi">
                        <span class="cm-header-search__message"></span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

  export default {

    inherit: true,
    name: 'EarthBar',
    props: {
        featuretext: String,
        destinations: Array,
        themes: Array,
        durations: Array
    },
    data () {
      return {
          destination: null,
          showDestinationsSubmenu: false,
          theme:null,
          showThemesSubmenu: false,
          duration: null,
          showDurationsSubmenu: false,
          window_width: 0,
          window_height: 0,
          mobileMenu: false,
      }
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },

    methods: {
        setDestination: function (dest) {
            this.destination = dest
            this.closeDestinationsSubmenu()
        },
        openDestinationsSubmenu() {
            this.showDestinationsSubmenu = true
        },
        closeDestinationsSubmenu() {
            this.showDestinationsSubmenu = false
        },
        setTheme: function (theme) {
            this.theme = theme
            this.closeThemesSubmenu()
        },
        openThemesSubmenu() {
            this.showThemesSubmenu = true
        },
        closeThemesSubmenu() {
            this.showThemesSubmenu = false
        },
        setDuration: function (duration) {
            this.duration = duration
            this.closeDurationsSubmenu()
        },
        openDurationsSubmenu() {
            this.showDurationsSubmenu = true
        },
        closeDurationsSubmenu() {
            this.showDurationsSubmenu = false
        },
        toggleOnMobileMenu() {
            this.mobileMenu = true;
        },
        toggleOffMobileMenu() {
            this.mobileMenu = false;
        },
        handleResize() {
            this.window_width = window.innerWidth;
            this.window_height = window.innerHeight;
        },
        doSearch() {
            let location = 'https://www.earthviaggi.it/'

            if(this.destination || this.theme || this.duration) {
                location += 'search?'

                let params = []
                if(this.destination) {
                    params.push('destinazioni='+this.destination)
                }
                if(this.theme) {
                    params.push('temi='+this.theme)
                }
                if(this.duration) {
                    params.push('durata='+this.duration)
                }

                params.push('ref=LP')

                location += params.join('&')
            }
            else {
                location += '?ref=LP'
            }
            window.open(location, '_blank');


        }

    },
    computed: {

      destinationsDropDownClass() {
          let $class = 'cm-header-search__input-group';
          return  (this.showDestinationsSubmenu) ? $class + ' is-open ' : $class
      },
      themesDropDownClass() {
          let $class =  'cm-header-search__input-group';
          return  (this.showThemesSubmenu) ? $class + ' is-open ' : $class
      },
      durationsDropDownClass() {
          let $class =  'cm-header-search__input-group';
          return  (this.showDurationsSubmenu) ? $class + ' is-open ' : $class
      },
      headerSearchClass() {
        let $class = 'cm-header-search'
        return  (this.mobileMenu) ? $class + ' is-loading is-search-open ' : $class
      },

    },

    watch: {
        window_width: function(val) {
            if(val > 992) {
                this.toggleOffMobileMenu();
            }
        }
    }



  }

</script>

<style scoped>
    .co-header-search {
        position:relative;
        display:-webkit-box;
        display:-webkit-flex;
        display:-moz-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-pack:center;
        -webkit-justify-content:center;
        -moz-box-pack:center;
        -ms-flex-pack:center;
        justify-content:center;
        z-index:500;
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -webkit-flex-direction:column;
        -moz-box-orient:vertical;
        -moz-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column;
        /*margin-bottom:64px*/
    }

    .co-header-search__btn-open{
        background-color:#000;
        width:100%;
        padding:32px;
        text-align:center;
        color:#fff;
        -webkit-transition:.3s;
        -o-transition:.3s;
        transition:.3s;
        font-family:Montserrat,sans-serif;
        font-size:.75rem;
        font-weight:500;
        line-height:1.5;
        letter-spacing:0
    }

    .co-header-search__btn-open img {
        width:1.25rem;
        height:auto;
        margin-right:8px
    }

    .co-header-search__btn-open.is-search-open { background-color:#444 }

    .cm-header-search {
        background-color:#fff;
        color:#fff;
    }

    .cm-header-search.is-loading .cm-header-search__dropdown { display:none; }
    .cm-header-search.is-loading .cm-header-search__loading-hidden { display:none; }
    .cm-header-search.is-loading .cm-header-search__message { display:none; }
    .cm-header-search.is-search-open { display:block; }
    .cm-header-search.is-search-open .cm-header-search__input-group { display:block; }
    .cm-header-search.is-search-open .cm-header-search__dropdown { display:block; }
    .cm-header-search.is-search-open .cm-header-search__loading-hidden { display:block; }
    .cm-header-search__input-group{
        -webkit-transition:background-color .3s;
        -o-transition:background-color .3s;
        transition:background-color .3s;
        position:relative;
        cursor:pointer;
        text-align:center;
        display:none;
        border-bottom:1px solid #2b2b2b;
        background-color: black;
    }

    .cm-header-search__input-group-wrapper{
        padding:32px 0;
    }

    .cm-header-search__input-group--search {
        width: auto;
        background-color: #fff !IMPORTANT;
        position: relative;
        border-bottom: 1px solid #f1f1f1;
    }

    .cm-header-search__input-group--search img { display:none }

    .cm-header-search__input-group--search .cm-header-search__title {
        color:#444;
        margin-right:16px;
    }

    .cm-header-search__title {
        font-family:Montserrat,sans-serif;
        font-size:.75rem;
        font-weight:500;
        line-height:1.5;
        letter-spacing:0;
    }

    .cm-header-search__subtitle {
        font-family:Montserrat,sans-serif;
        font-size:.625rem;
        font-weight:400;
        line-height:1.5;
        letter-spacing:0;color:#ddd;
    }

    .cm-header-search__dropdown {
        background-color:#444;
        width:100%;
        height:0;
        z-index:100;
        -webkit-transition:height .3s;
        -o-transition:height .3s;
        transition:height .3s;
    }

    .cm-header-search__list {
        margin:0;
        padding:0;
        background-color:#000;
    }

    .cm-header-search__list--0 { padding:16px; }

    .cm-header-search__item {
        list-style:none;
        display:-webkit-box;
        display:-webkit-flex;
        display:-moz-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-align:center;
        -webkit-align-items:center;
        -moz-box-align:center;
        -ms-flex-align:center;
        align-items:center;
        -webkit-box-pack:center;
        -webkit-justify-content:center;
        -moz-box-pack:center;
        -ms-flex-pack:center;
        justify-content:center;
        padding-top:8px;
        padding-bottom:8px;
        font-family:Montserrat,sans-serif;
        font-size:.75rem;
        font-weight:500;
        line-height:1.5;
        letter-spacing:0;
        cursor:pointer
    }

    .cm-header-search__item--0 { text-align:center }


    .cm-header-search__item--1 {
        color:#c4c3c3;
        -webkit-transition:background-color .3s;
        -o-transition:background-color .3s;
        transition:background-color .3s
    }

    .cm-header-search__item--1:hover {
        background-color:#444;
        color:#fff
    }

    .cm-header-search__input-group.is-open .cm-header-search__dropdown { height:250px }

    .simplebar-wrapper {
        overflow:hidden;
        width:inherit;
        height:inherit;
        max-width:inherit;
        max-height:inherit
    }

    [data-simplebar]{
        position:relative;
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
        -webkit-flex-direction:column;
        -moz-box-orient:vertical;
        -moz-box-direction:normal;
        -ms-flex-direction:column;
        flex-direction:column;
        -webkit-flex-wrap:wrap;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-box-pack:start;
        -webkit-justify-content:flex-start;
        -moz-box-pack:start;
        -ms-flex-pack:start;
        justify-content:flex-start;
        -webkit-align-content:flex-start;
        -ms-flex-line-pack:start;
        align-content:flex-start;
        -webkit-box-align:start;
        -webkit-align-items:flex-start;
        -moz-box-align:start;
        -ms-flex-align:start;
        align-items:flex-start
    }
    .simplebar-wrapper{
        overflow:hidden;
        width:inherit;
        height:inherit;
        max-width:inherit;
        max-height:inherit
        }

    .simplebar-mask {
        direction:inherit;
        position:absolute;
        overflow:hidden;
        padding:0;
        margin:0;
        left:0;
        top:0;
        bottom:0;
        right:0;
        width:auto!important;
        height:auto!important;
        z-index:0
    }

    .simplebar-offset {
        direction:inherit!important;
        -webkit-box-sizing:inherit!important;
        -moz-box-sizing:inherit!important;
        box-sizing:inherit!important;
        resize:none!important;
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        padding:0;
        margin:0;
        -webkit-overflow-scrolling:touch
    }

    .simplebar-content-wrapper {
        direction:inherit;
        -webkit-box-sizing:border-box!important;
        -moz-box-sizing:border-box!important;
        box-sizing:border-box!important;
        position:relative;
        display:block;
        height:100%;
        width:auto;
        visibility:visible;
        overflow:auto;
        max-width:100%;
        max-height:100%
    }

    .simplebar-content:after,.simplebar-content:before {
        content:' ';
        display:table
    }


    .simplebar-height-auto-observer-wrapper{
        -webkit-box-sizing:inherit!important;
        -moz-box-sizing:inherit!important;
        box-sizing:inherit!important;
        height:100%;
        width:100%;
        max-width:1px;
        position:relative;
        float:left;
        max-height:1px;
        overflow:hidden;
        z-index:-1;
        padding:0;
        margin:0;
        pointer-events:none;
        -webkit-box-flex:inherit;
        -webkit-flex-grow:inherit;
        -moz-box-flex:inherit;
        -ms-flex-positive:inherit;
        flex-grow:inherit;
        -webkit-flex-shrink:0;
        -ms-flex-negative:0;
        flex-shrink:0;
        -webkit-flex-basis:0;
        -ms-flex-preferred-size:0;
        flex-basis:0
    }

    .simplebar-height-auto-observer {
        -webkit-box-sizing:inherit;
        -moz-box-sizing:inherit;
        box-sizing:inherit;
        display:block;
        opacity:0;
        position:absolute;
        top:0;
        left:0;
        height:1000%;
        width:1000%;
        min-height:1px;
        min-width:1px;
        overflow:hidden;
        pointer-events:none;
        z-index:-1
    }


    [data-simplebar].simplebar-dragging .simplebar-content {
        pointer-events:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        -webkit-user-select:none
    }

    [data-simplebar] {
        pointer-events:all
    }


    [data-simplebar-direction=rtl] {
        right:auto;
        left:0
    }

    .feature-text {
        font-size: 1.5em;
        font-family: Montserrat,sans-serif;
        margin-bottom: 0.3rem;
    }

    .feature-text::before {
        content: " ";
        width: 50px;
        height: 1px;
        line-height: 1px;
        background-color: black;
        margin-right: 10px;
        float: left;
        margin-top: 1.1rem;
    
    }


    @media (min-width:992px) {

        .co-header-search{
            -webkit-box-orient:horizontal;
            -webkit-box-direction:normal;
            -webkit-flex-direction:row;
            -moz-box-orient:horizontal;
            -moz-box-direction:normal;
            -ms-flex-direction:row;
            flex-direction:row;
            margin-bottom:0
        }

        .co-header-search__btn-open { display:none }

        .cm-header-search {
            display:-webkit-inline-box;
            display:-webkit-inline-flex;
            display:-moz-inline-box;
            display:-ms-inline-flexbox;
            display:inline-flex;
        }

        .cm-header-search__input-group {
            text-align:left;
            display:block;
            border-bottom:none;
            background-color: black;
        }
        .cm-header-search__input-group:hover { background-color:#444 }
        .cm-header-search__input-group-wrapper { padding:32px 64px }
        .cm-header-search__input-group--search img { display:inline }
        .cm-header-search__input-group--search .cm-header-search__title { display:none }
        .cm-header-search__input-group--search {
            border-bottom: none;
        }


        .cm-header-search__dropdown {
            background-color:#000;
            position:absolute;
            left:0;
            top:50%;
            -webkit-transform:translateY(-50%);
            -ms-transform:translateY(-50%);
            -o-transform:translateY(-50%);
            transform:translateY(-50%);
        }

        .cm-header-search__input-group--search {
             border-bottom: none;
         }


        .cm-header-search__item {
            -webkit-box-pack:start;
            -webkit-justify-content:flex-start;
            -moz-box-pack:start;
            -ms-flex-pack:start;
            justify-content:flex-start
        }

        .cm-header-search__item--0:before {
            content:'';
            display:block;
            width:8px;
            height:1px;
            background-color:#fff;
            margin-right:8px
        }

        .cm-header-search__item--1 { padding-left:16px; }

    }

</style>
