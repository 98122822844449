/* eslint-disable */
require('./accept')
require('./amazon-pay')
require('./ancient')
require('./apple-pay')
require('./backpack')
require('./bear')
require('./brain')
require('./checked')
require('./chevron-down')
require('./chevron-left')
require('./chevron-right')
require('./chevron-up')
require('./colosseum')
require('./cristo-rei')
require('./cutlery')
require('./dunes')
require('./edt-logo')
require('./edt-shop-logo')
require('./faux-checked')
require('./faux-left-arrow')
require('./faux-right-arrow')
require('./gallery')
require('./golden-gate')
require('./google-pay')
require('./hot-air-balloon')
require('./india')
require('./island')
require('./lighthouse')
require('./logo-lonelyplanetitalia')
require('./logo-shop-lonelyplanetitalia')
require('./mask')
require('./maya')
require('./opera')
require('./party')
require('./paypal')
require('./postal')
require('./pottery')
require('./quotation')
require('./sandals')
require('./statue-of-liberty')
require('./team')
require('./temple')
require('./toucan')
require('./toys')
require('./truck')
require('./ui')
require('./urban')
require('./world-delivery')
