/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'accept': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M0 0v512h512V0H0zm462.452 462.452H49.548V49.548h412.903v412.904z"/><path pid="1" d="M345.838 164.159l-122.87 122.87-65.064-65.064-35.034 35.038L222.968 357.1l157.904-157.904z"/>'
  }
})
