<template>
  <form name="nl_frm" accept-charset="UTF-8" method="post" @submit.prevent="frmSubmit">
    <div class="m-b-12 text-secondary text-sm leading-snug">
      Inizia il tuo viaggio, iscriviti <strong>gratuitamente</strong> alla <a href="/newsletter" class="text-secondary underline font-bold" target="_blank" rel="nofollow">Newsletter di Lonely Planet Italia</a> e <strong>ricevi il 30% di sconto per il tuo prossimo acquisto</strong>.
    </div>
    <input type="text"
           v-validate="'required|email'"
           v-model="email"
           placeholder="Email"
           name="email"
           class="inline-block border-1 border-gray-100 rounded-4 min-h-48 w-full p-x-8 font-light text-base leading-none text-primary outline-none"/>
    <div class="property__error" v-show="errors.has('email')">{{ errors.first('email') }}</div>
    <div class="m-t-12 m-b-16 text-secondary text-sm leading-snug">
      Cliccando ci autorizzi al trattamento dei dati inseriti secondo le finalità indicate nella <a
      href="https://www.lonelyplanetitalia.it/legal/privacy" class="text-blue-500 underline font-bold" target="_blank" rel="nofollow">informativa sulla Privacy</a>. Potrai disicriverti in
      ogni momento cliccando sul link presente nella newsletter.
    </div>
    <a class="inline-block text-white font-heading font-bold rounded-4 uppercase tracking-widest transition-all duration-300 bg-black-400 hover:bg-black-500 cursor-pointer text-xs p-y-14 p-x-16"
      :class="{ 'cursor-not-allowed ':!email }" @click="frmSubmit">
      Iscriviti
    </a>
    <loading :active.sync="this.isLoading"
             :can-cancel="this.canCancel"
             :is-full-page="this.isFullPage"
             color="#297cbb"></loading>
    <generic-modal-dialog :message="popup_message"
                          title="Iscrizione alla Newsletter"
                          :show="showPopup"></generic-modal-dialog>
  </form>
</template>

<script>

import {eventsbus} from '../mixins/EventsBus.js'

export default {

  inherit: true,
  name: 'NewsletterForm',
  props: {
    callback: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      canCancel: false,
      email: '',
      showPopup: false,
      popup_message: ''
    }
  },
  mounted() {
    eventsbus.$on('generic-modal-closed', function () {
      this.showPopup = false
    }.bind(this))
  },
  methods: {
    frmSubmit: function (e) {
      if (!this.email) return false;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoading = true
          axios.post(this.callback, {email: this.email}).then((response) => {
            this.isLoading = false
            this.popup_message = response.data.msg
            this.showPopup = true
          })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
  },
}

</script>
