/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'postal': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<circle pid="0" cx="256" cy="256" r="256" _fill="#374266"/><path pid="1" d="M256.003 53.432v451.671c-1.745-.408-62.725-.836-64.46-1.285a254.27 254.27 0 01-65.473-27.199 255.559 255.559 0 01-32.016-22.361 256.89 256.89 0 01-59.246-69.308V91.454c0-20.999 17.022-38.022 38.022-38.022h183.173z" _fill="#b55d14"/><path pid="2" d="M138.254 209.261V91.454c0-20.998 17.023-38.022 38.022-38.022h-62.694c-20.998 0-38.022 17.023-38.022 38.022v117.807h62.694z" _fill="#c5c6e0"/><path pid="3" d="M477.187 88.366V384.97c-25.652 43.896-63.99 79.475-109.996 101.689a253.628 253.628 0 01-65.651 21.295 257.455 257.455 0 01-45.537 4.044c-20.396 0-40.229-2.382-59.235-6.896-1.745-.408-3.49-.836-5.224-1.285V88.366c0-19.294 15.641-34.934 34.934-34.934h215.776c19.292 0 34.933 15.641 34.933 34.934z" _fill="#ed8c40"/><path pid="4" _fill="#ebebf2" d="M300.408 53.436h67.918v147.466h-67.918z"/><path pid="5" _fill="#c5c6e0" d="M300.408 53.436h67.918v13.72h-67.918z"/><path pid="6" d="M329.288 267.184l-64.938 64.938c-4.523 4.523-1.32 12.257 5.077 12.257h16.448a7.18 7.18 0 017.18 7.18v96.073a7.18 7.18 0 007.181 7.18h68.26a7.18 7.18 0 007.181-7.18v-96.073a7.18 7.18 0 017.18-7.18h16.448c6.397 0 9.601-7.734 5.077-12.257l-64.938-64.938a7.182 7.182 0 00-10.156 0z" _fill="#b55d14"/>'
  }
})
