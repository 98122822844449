<template>
  <VueSlickCarousel v-bind="settings">
    <template v-for="el in elements">
      <figure class="position-relative padding-x-8">
        <img class="rounded-4 object-cover margin-auto width-full tablet:height-160 desktop:height-192 wide:height-256 shadow-md lazyload" :data-src="el.picture" />
        <figcaption class="text-12 leading-snug margin-top-12 text-grey-700 normalize">
          <template v-if="el.caption">
            <p class="font-bold">{{ el.caption }} <template v-if="el.location"> - {{ el.location }}</template></p>
          </template>
          <template v-if="el.credits">
            <span>{{ el.credits }}</span>
          </template>
        </figcaption>
      </figure>
    </template>
    <template #prevArrow="arrowOption">
      <div class="position-absolute inset-top-ratio-2/1 inset-left-0 z-50" style="transform: translateY(-50%);">
        <div class="padding-10 uppercase cursor-pointer bg-black" aria-label="prev">
          <div class="width-16 text-white">
            <mix-svgicon icon="ui/faux-left-arrow" class="fill-current width-16"></mix-svgicon>
          </div>
        </div>
      </div>
    </template>
    <template #nextArrow="arrowOption">
      <div class="position-absolute inset-top-ratio-2/1 inset-right-0 z-50" style="transform: translateY(-50%);">
        <div class="padding-10 uppercase cursor-pointer bg-black" aria-label="next">
          <div class="width-16 text-white">
            <mix-svgicon icon="ui/faux-right-arrow" class="display-block fill-current width-16"></mix-svgicon>
          </div>
        </div>
      </div>
    </template>
  </VueSlickCarousel>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'PictureSwiperModule',
  props: {
    els: {
      required: true
    }
  },
  components: { VueSlickCarousel },
  data () {
    return {
      settings: {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        lazyLoad: "progressive",
        responsive: [{
          breakpoint: 768, //tablet breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
      },
    }
  },
  mounted () {
  },
  beforeDestroy () {
  },
  computed: {
    elements: function () {
      return JSON.parse(this.els)
    },
  }
}
</script>
