export default {
  methods: {
    numberize: function (string, digit = 2) {
      return parseFloat(parseFloat(string).toFixed(digit))
    },
    striptags: function (string) {
        return string.replace(/(<([^>]+)>)/gi, "")
    }
  },
}
