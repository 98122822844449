/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'maya': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M187.79 371.457l-74.294 102.734C121.813 477.375 175.023 512 256 512c80.656 0 133.66-34.422 142.504-37.809L324.21 371.457A136.076 136.076 0 00368.195 330H512V225c0-41.355-33.645-75-75-75h-96.254a135.033 135.033 0 00-33.812-20.016C321.723 116.277 331 96.707 331 75c0-41.355-33.645-75-75-75-24.484 0-46.262 11.797-59.96 30H121v30h61.508C181.52 64.848 181 69.863 181 75s.52 10.152 1.508 15H121v30h75.04a75.758 75.758 0 009.026 9.984A135.033 135.033 0 00171.254 150H75c-41.355 0-75 33.645-75 75v105h143.805a136.076 136.076 0 0043.984 41.457zm28.03 12.438a134.287 134.287 0 0012.676 3.285l-24.719 89.27c-15.289-3.192-30.328-7.786-44.898-13.817zm17.86 96.968L256 400.266l22.32 80.597a255.82 255.82 0 01-44.64 0zm119.441-18.23c-14.57 6.031-29.61 10.625-44.894 13.816l-24.72-89.27c4.302-.894 8.528-1.992 12.673-3.284zM422 300h-38.723c4.996-14.082 7.723-29.227 7.723-45 0-27.73-8.41-53.535-22.805-75H422zm60-75v75h-30V182.578c17.46 6.195 30 22.867 30 42.422zM211 75c0-24.813 20.188-45 45-45s45 20.187 45 45-20.188 45-45 45-45-20.188-45-45zm45 75c57.898 0 105 47.102 105 105s-47.102 105-105 105-105-47.102-105-105 47.102-105 105-105zM30 225c0-19.555 12.54-36.227 30-42.422V300H30zm60 75V179.988h53.805C129.41 201.457 121 227.27 121 255c0 15.773 2.727 30.918 7.723 45zm0 0"/><path pid="1" d="M256 330c41.355 0 75-33.645 75-75s-33.645-75-75-75-75 33.645-75 75 33.645 75 75 75zm0-120c24.813 0 45 20.188 45 45s-20.188 45-45 45-45-20.188-45-45 20.188-45 45-45zm0 0M241 60h30v30h-30zm0 0"/>'
  }
})
