/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'golden-gate': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M458.249 215.901c10.824 12.613 24.166 23.253 39.588 31.137L512 219.334c-33.155-16.95-53.751-50.591-53.751-87.794V43.381h-93.345v77.787c0 60.049-48.854 108.902-108.902 108.902-60.049 0-108.902-48.853-108.902-108.902V43.381H53.754v88.159h-.003c0 37.203-20.596 70.845-53.751 87.794l14.164 27.704c15.423-7.885 28.767-18.526 39.591-31.14v107.518H7.082v31.115h46.672v114.088h93.345V354.531h217.805v114.088h93.345V354.531h46.672v-31.115h-46.672V215.901zM115.984 437.504H84.869V74.496h31.115v363.008zm62.23-114.088h-31.115v-114.35a141.178 141.178 0 0031.115 28.466v85.884zm62.23 0h-31.115v-70.239a138.827 138.827 0 0031.115 7.14v63.099zm62.23 0h-31.115v-63.099a138.827 138.827 0 0031.115-7.14v70.239zm62.23 0h-31.115v-85.884a141.203 141.203 0 0031.115-28.466v114.35zm62.23 114.088h-31.115V74.496h31.115v363.008z"/>'
  }
})
