/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'toucan': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M241 60h30v30h-30z"/><path pid="1" d="M465.959 361C475.397 348.456 481 332.871 481 316v-45h-45c-41.355 0-75 33.645-75 75v15h-61.607C318.88 341.927 331 315.357 331 286V180h15c34.922 0 64.344-23.993 72.653-56.359 13.735 4.092 26.322 11.548 36.74 21.966L481 171.212v-38.087C481 59.72 421.28 0 347.875 0H256c-74.439 0-135 60.561-135 135v226H0v30h121v46c0 41.355 33.645 75 75 75h15v-45.341C223.946 476.615 239.583 482 256 482h15v-91h241v-30h-46.041zM412.307 52.67c15.831 12.704 27.854 29.968 34.039 49.79C430.177 94.311 412.206 90 393.573 90h-1.079c2.844-14.077 9.674-26.978 19.813-37.33zM331 30h16.875c13.387 0 26.178 2.578 37.925 7.24-12.745 14.995-20.959 33.176-23.741 52.76H331V30zm0 89.9h57.42C382.228 137.358 365.555 150 346 150h-15v-30.1zM181 479.43c-17.461-6.191-30-22.874-30-42.43v-46h30v88.43zm60-30.165c-17.282-6.475-30-23.675-30-43.265v-15h30v58.265zM226 361h-25.34C243.55 330.637 271 280.37 271 225c0-41.355-33.645-75-75-75v30c24.813 0 45 20.187 45 45 0 58.172-36.9 109.45-90 128.303V135c0-57.897 47.103-105 105-105h45v256c0 41.355-33.645 75-75 75zm225-45c0 24.813-20.187 45-45 45h-15v-15c0-24.813 20.187-45 45-45h15v15z"/>'
  }
})
