/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 448.8,
    height: 448.8,
    viewBox: '0 0 448.8 448.8',
    data: '<path pid="0" d="M142.8 323.85L35.7 216.75 0 252.45l142.8 142.8 306-306-35.7-35.7z"/>'
  }
})
