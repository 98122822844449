/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ancient': {
    width: 16,
    height: 16,
    viewBox: '-29 0 511 512',
    data: '<path pid="0" d="M422.77 75.027v46.582c-29.301 6-52.43 29.133-58.43 58.43h-30.047V59.945h-32.059C295.234 25.777 264.934 0 228.727 0c-36.211 0-66.512 25.777-73.512 59.945H123.16V180.04H89.09c-6-29.297-29.13-52.43-58.43-58.43V75.027H.5V512h452.43V75.027zM228.727 30.16c19.453 0 36.046 12.445 42.253 29.785H186.47c6.21-17.34 22.804-29.785 42.258-29.785zM153.32 90.105H304.13v89.934h-1.895c-7-34.172-37.3-59.953-73.507-59.953-36.211 0-66.512 25.781-73.512 59.953h-1.895zm117.66 89.934H186.47c6.207-17.348 22.804-29.793 42.258-29.793 19.449 0 36.046 12.445 42.253 29.793zM30.66 152.863c12.63 4.52 22.656 14.547 27.176 27.176H30.66zM273.594 481.84h-89.735v-75.406c0-24.743 20.125-44.868 44.868-44.868 24.738 0 44.867 20.125 44.867 44.868zm149.176 0H303.754v-75.406c0-41.372-33.656-75.032-75.027-75.032-41.372 0-75.032 33.66-75.032 75.032v75.406H30.66V210.2h392.11zm-27.176-301.8c4.52-12.63 14.547-22.657 27.176-27.177v27.176zm0 0"/><path pid="1" d="M90.984 255.621h30.164v30.164H90.984zm0 0M151.309 255.621h30.164v30.164h-30.164zm0 0M211.633 255.621h30.164v30.164h-30.164zm0 0M271.957 255.621h30.164v30.164h-30.164zm0 0M332.281 255.621h30.164v30.164h-30.164zm0 0"/>'
  }
})
