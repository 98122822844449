/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'informations': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M453.708 186.943H288.512c.569-3.022.869-6.105.869-9.245V76.216c0-27.432-22.317-49.748-49.748-49.748H49.737C22.311 26.468 0 48.784 0 76.216v101.482c0 27.425 22.311 49.736 49.737 49.736h29.221l-1.577 36.738a8.869 8.869 0 0 0 13.681 7.826l68.856-44.565h8.724a58.065 58.065 0 0 0-2.786 17.786v122.676c0 32.134 26.143 58.278 58.278 58.278h25.178l55.514 56.695a8.864 8.864 0 0 0 9.277 2.161 8.867 8.867 0 0 0 5.884-7.491l5.097-51.365h128.625c32.143 0 58.292-26.144 58.292-58.278V245.22c-.001-32.135-26.151-58.277-58.293-58.277zM177.98 209.696h-20.683c-1.71 0-3.383.494-4.819 1.424l-56.639 36.658 1.238-28.832a8.873 8.873 0 0 0-8.86-9.25H49.738c-17.645 0-32.001-14.354-32.001-31.998V76.216c0-17.651 14.356-32.01 32-32.01h189.898c17.651 0 32.01 14.36 32.01 32.01v101.482c0 3.177-.459 6.27-1.36 9.245h-46.152c-18.771 0-35.486 8.929-46.153 22.753zm316.284 158.199c0 22.354-18.193 40.541-40.555 40.541H317.05a8.869 8.869 0 0 0-8.826 7.994l-4.013 40.459-44.834-45.788a8.865 8.865 0 0 0-6.336-2.664h-28.906c-22.354 0-40.541-18.187-40.541-40.541V245.22c0-22.354 18.187-40.54 40.541-40.54H453.71v-.001c22.362 0 40.555 18.186 40.555 40.54v122.676z"/><path pid="1" d="M183.31 93.756c-1.206-19.413-16.755-34.962-36.168-36.167-10.807-.668-21.086 3.028-28.949 10.418-7.754 7.287-12.199 17.566-12.199 28.201-.001 4.9 3.968 8.87 8.868 8.87s8.869-3.971 8.869-8.869c0-5.846 2.347-11.27 6.609-15.277 4.258-4.002 9.836-6.005 15.702-5.641 10.329.641 18.922 9.234 19.564 19.562.484 7.802-3.346 15.177-9.997 19.249-12.391 7.585-19.789 20.693-19.789 35.065 0 4.898 3.97 8.869 8.869 8.869s8.869-3.971 8.869-8.869c0-8.149 4.229-15.601 11.312-19.937 12.265-7.507 19.332-21.101 18.44-35.474zM338.925 240.4a8.869 8.869 0 0 0-8.869 8.869v75.063a8.87 8.87 0 0 0 17.738 0v-75.063a8.87 8.87 0 0 0-8.869-8.869z"/><circle pid="2" cx="338.916" cy="364.068" r="8.869"/><circle pid="3" cx="144.679" cy="182.046" r="8.869"/>'
  }
})
