<template>
  <div>
    <div :class="['sticky-menu', { 'is-sticked': visible }]">
      <slot></slot>
    </div>
    <div ref="stickabler"></div>
  </div>
</template>

<script>

export default {
  props: {
    thresold: {
      type: Number,
      default: 0,
    },
    hideScrollDown: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      el: null,
      scrollPosition: null,
      scrollDirection: null
    }
  },
  computed: {
    visible () {
      return (this.hideScrollDown ? this.scrollDirection === 'up' : true) && (this.scrollPosition >= (this.el + this.thresold))
    },
  },
  mounted () {
    this.registerPosition()
    this.updateScroll()
    this.registerScrollEvent()
  },
  beforeDestroy () {
    this.removeScrollEvent()
  },
  methods: {
    registerPosition () {
      this.el = this.$refs.stickabler.offsetTop
    },
    updateScroll () {
      this.scrollPosition = window.scrollY
    },
    registerScrollEvent () {
      let _this = this
      window.addEventListener('scroll', _.throttle(function () {
        _this.scrollDirection = window.scrollY > _this.scrollPosition ? 'down' : 'up'
        _this.scrollPosition = window.scrollY
      }, 400))
    },
    removeScrollEvent () {
      window.removeEventListener('scroll', this.updateScroll())
    },
  },
}
</script>
