<template>
  <div class="carousel carousel--tall" :class="{ 'nobtns': nobtns }">
    <template v-if="!nobtns">
      <button class="carousel__button carousel__button--previous" title="Previous" aria-label="Previous" @click="prev()">
        <svgicon name="chevron-left" class="icon"></svgicon>
      </button>
    </template>
    <slick ref="slick" :options="slickOptions">
      <a v-for="(card, index) in els" class="slick-card card card--tall card--animated" :href="card.route">
        <div class="card__image-container" :style="{ backgroundImage: 'url(' + card.picture + ')' }">
          <div class="card__gradient-overlay"></div>
          <div class="card__content">
            <div class="number-marker card__marker" v-if="marker">{{ (+index+1) }}</div>
            <p class="card__eyebrow" v-if="card.parent">{{ card.parent.name }}</p>
            <p class="card__eyebrow" v-else-if="card.place">{{ card.place.name }}</p>
            <h3 class="card__subtitle" v-if="card.collection==='inspiration'">{{ card.title }}</h3>
            <h3 class="card__title" v-else>{{ card.name }}</h3>
            <p class="card__ttd" v-if="card.collection==='poi' && card.type">{{ card.type }}</p>
            <p class="card__ttd" v-else-if="card.collection==='poi' && card.thingstodo">{{ card.thingstodo }}</p>
          </div>
        </div>
      </a>
    </slick>
    <template v-if="!nobtns">
      <button class="carousel__button carousel__button--next" title="Next" aria-label="Next" @click="next()">
        <svgicon name="chevron-right" class="icon"></svgicon>
      </button>
    </template>
  </div>
</template>

<script>

  import Slick from 'vue-slick'

  export default {

    name: 'CardSlider',
    props: {
      els: {
        required: true,
        type: Array
      },
      marker: {
        type: Boolean,
        default: true
      },
      nobtns: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        slickOptions: {
          arrows: false,
          infinite: true,
          slidesToScroll: 4,
          slidesToShow: 4,
          // touchThreshold: 10,
          // variableWidth: true,
          zIndex: 1,
          responsive: [
            {
              breakpoint: 1080,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                swipe: true,
                touchThreshold: 10,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                swipe: true,
                touchThreshold: 10,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                swipe: true,
                touchThreshold: 10,
              },
            },
            {
              breakpoint: 544,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                swipe: true,
                touchThreshold: 10,
              },
            },
          ],
        },
      }
    },

    components: { Slick },

    methods: {
      next() {
        this.$refs.slick.next()
      },
      prev() {
        this.$refs.slick.prev()
      },
    },

    mounted() {
      if (this.nobtns) {
        this.slickOptions.responsive = false;
        this.slickOptions.infinite = false;
      }
    },

  }

</script>
