/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'google-pay': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M243.08 254v55.24h-17.51V172.88H272c11.2-.22 21.97 4.02 30.12 11.85 8.16 7.29 12.72 17.83 12.51 28.71.22 10.98-4.35 21.53-12.51 28.93-8.16 7.72-18.16 11.64-30.01 11.64h-29.03zm0-64.37v47.63h29.36a22.88 22.88 0 0017.29-7.07c9.24-8.92 9.35-23.6.43-32.84l-.43-.43a22.654 22.654 0 00-17.29-7.18c0-.01-29.36-.11-29.36-.11zm111.89 23.27c12.94 0 23.16 3.48 30.67 10.33 7.5 6.96 11.2 16.42 11.2 28.49v57.42h-16.75V296.2h-.76c-7.29 10.66-16.86 15.99-28.93 15.99-10.22 0-18.92-3.04-25.77-9.13a28.836 28.836 0 01-10.33-22.84c0-9.68 3.7-17.29 10.98-23.05 7.29-5.76 17.07-8.59 29.25-8.59 10.44 0 18.92 1.96 25.66 5.65v-4.02c0-5.98-2.61-11.64-7.18-15.44-4.68-4.13-10.66-6.42-16.86-6.42-9.79 0-17.51 4.13-23.27 12.4l-15.44-9.68c8.39-12.08 20.9-18.17 37.53-18.17zm-22.61 67.74c0 4.57 2.17 8.81 5.76 11.42 3.91 3.04 8.7 4.68 13.59 4.57 7.39 0 14.46-2.94 19.68-8.16 5.76-5.44 8.7-11.85 8.7-19.25-5.44-4.35-13.05-6.52-22.84-6.52-7.07 0-13.05 1.74-17.83 5.11-4.67 3.48-7.06 7.72-7.06 12.83zm160.61-64.7l-58.5 134.41h-18.05l21.75-46.98-38.49-87.32h19.03l27.73 66.99h.43l27.08-66.99 19.02-.11z" _fill="#585f65"/><path pid="1" d="M172.29 242.04c0-5.33-.43-10.66-1.3-15.88H97.15v30.12h42.3c-1.74 9.68-7.39 18.38-15.66 23.81v19.57h25.23c14.78-13.58 23.27-33.7 23.27-57.62z" _fill="#4d7cbe"/><path pid="2" d="M97.15 318.59c21.1 0 38.93-6.96 51.87-18.92l-25.23-19.57c-7.07 4.78-16.09 7.5-26.64 7.5-20.44 0-37.73-13.81-43.93-32.3h-26v20.23c13.27 26.32 40.35 43.06 69.93 43.06z" _fill="#31af60"/><path pid="3" d="M53.21 255.31c-3.26-9.68-3.26-20.23 0-30.01v-20.12H27.22a77.638 77.638 0 000 70.25l25.99-20.12z" _fill="#fbb928"/><path pid="4" d="M97.15 193c11.2-.22 21.97 4.02 30.01 11.74l22.29-22.29c-14.14-13.38-32.84-20.66-52.31-20.44-29.58 0-56.65 16.75-69.92 43.17l25.99 20.23C59.41 206.81 76.7 193 97.15 193z" _fill="#ef5040"/>'
  }
})
