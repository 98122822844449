<template>
    <div class="only4u-app">
        <loading-module :loading="loading">
            <template v-slot:PageContent>
                <div class="padding-y-32">
                    <div class="display-flex flex-row items-center">
                        <tw-dropdown :data="regions"
                                     v-model="regionSelected"
                                     v-on:valueSelect="onRegionSelected">
                            <template v-slot:SelectLabel>Seleziona la regione</template>
                            <template v-slot:SelectPlaceholder>--</template>
                        </tw-dropdown>
                    </div>
                </div>
                <loading-module :loading="updating">
                    <template v-slot:PageContent>
                        <div
                            class="display-grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-auto column-gap-12 row-gap-12 padding-bottom-32">
                            <article v-for="el in els"
                                     class="position-relative overflow-hidden shadow-md rounded-4 padding-top-ratio-5/8 cursor-pointer"
                                     @click="showModal(el)">
                                <div class="display-block height-full overflow-hidden">
                                    <div class="position-absolute inset-0 height-full">
                                        <img v-lazy="el.picture.cardsrc"
                                             class="width-full height-full object-cover"/>
                                    </div>
                                    <div class="position-absolute inset-0 height-full shadow-overlay-to-top"></div>
                                    <div class="position-absolute inset-0 height-full padding-24">
                                        <div
                                            class="display-flex flex-col flex-grow-1 height-full width-full justify-end">
                                            <div>
                                                <p class="josefin uppercase font-bold text-10 text-white">
                                                    {{ el.category }}
                                                </p>
                                                <h3 class="oswald text-22 tablet:text-24 desktop:text-30 text-white leading-closely padding-bottom-4">
                                                    {{ el.title }}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="position-absolute inset-top-1/12 padding-x-12 padding-y-6 bg-gradient-to-right from-azure to-blue"
                                    style="min-width:50%">
                                    <div class="display-flex flex-row items-center">
                                        <mix-svgicon icon="places/marker"
                                                     class="display-inline-block width-6 width-12 fill-current text-white"></mix-svgicon>
                                        <span
                                            class="display-inline font-family-tertiary text-10 font-bold uppercase text-white margin-left-4">
                                {{ el.regions.join(' | ') }}
                    </span>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </template>
                </loading-module>
            </template>
        </loading-module>
        <modal :name="uid"
               :id="uid"
               :ref="uid"
               classes="vm--modal-tour"
               height="90%"
               @opened="opened"
               @before-close="beforeClose"
               @closed="closed"
        >
            <div class="display-block overflow-y-scroll height-full">
                <div class="display-flex width-full flex-col">
                    <div class="position-absolute inset-top-8 inset-right-12 z-30">
                        <button class="rounded-full">

                        </button>
                    </div>
                    <template v-if="selected">
                        <div class="display-block position-relative width-full height-448">
                            <div
                                class="position-relative bg-cover bg-center-center bg-no-repeat lazy width-full height-448"
                                :data-bg="selected.picture.itemsrc">
                            </div>
                            <div
                                class="position-absolute -inset-bottom-16 inset-right-16 inset-left-16 tablet:inset-right-32 tablet:inset-right-32 desktop:inset-left-64 desktop:inset-right-64 display-block text-right padding-y-16 padding-x-24 bg-white">
                                <div class="display-flex flex-col md:flex-row items-center justify-between">
                                    <div class="display-flex flex-row items-center tablet-max:padding-bottom-16">
                                        <mix-svgicon icon="ui/clock"
                                                     class="display-inline-block width-32 fill-current text-azure padding-right-8"></mix-svgicon>
                                        <div class="text-left">
                                            <h5 class="font-family-tertiary text-11 leading-none text-primary padding-bottom-2">
                                                Durata</h5>
                                            <p class="font-family-tertiary font-bold text-16 leading-none text-primary">
                                                {{ selected.days }}
                                                Giorni</p>
                                        </div>
                                    </div>
                                    <div class="display-flex flex-row items-center tablet-max:padding-bottom-16">
                                        <mix-svgicon icon="ui/calendar"
                                                     class="display-inline-block width-32 fill-current text-azure padding-right-8"></mix-svgicon>
                                        <div class="text-left">
                                            <h5 class="font-family-tertiary text-11 leading-none text-primary padding-bottom-2">
                                                Periodo</h5>
                                            <p class="font-family-tertiary font-bold text-16 leading-none text-primary">
                                                Gen / Mar</p>
                                        </div>
                                    </div>
                                    <div class="display-flex flex-row items-center tablet-max:padding-bottom-16">
                                        <mix-svgicon icon="ui/pin"
                                                     class="display-inline-block width-32 fill-current text-azure padding-right-8"></mix-svgicon>
                                        <div class="text-left">
                                            <h5 class="font-family-tertiary text-11 leading-none text-primary padding-bottom-2">
                                                Regioni</h5>
                                            <p class="font-family-tertiary font-bold text-16 leading-none text-primary">
                                                {{ selected.regions.join(', ') }}</p>
                                        </div>
                                    </div>
                                    <div class="display-flex flex-row items-center tablet-max:padding-bottom-24">
                                        <mix-svgicon icon="ui/wallet"
                                                     class="display-inline-block width-32 fill-current text-azure padding-right-8"></mix-svgicon>
                                        <div class="text-left">
                                            <h5 class="font-family-tertiary text-11 leading-none text-primary padding-bottom-2">
                                                Prezzo</h5>
                                            <p class="font-family-tertiary font-bold text-16 leading-none text-primary">
                                                € {{ selected.price }}
                                                <span class="text-12">a <mix-svgicon icon="ui/avatar"
                                                                                     class="display-inline-block width-10 fill-current text-primary"></mix-svgicon></span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="display-hidden tablet-max:display-block tablet-max:padding-bottom-16">
                                        <a class="cta-btn display-flex flex-row items-center padding-x-24 padding-top-12 padding-bottom-14 bg-gradient-to-right from-azure to-blue"
                                           :href="selected.path" target="_blank">
                                            <span class="oswald font-bold text-16 text-white uppercase">PRENOTA</span>
                                            <mix-svgicon icon="ui/arrow-top-right"
                                                         class="display-inline-block width-20 fill-current text-white margin-top-2 padding-left-8"></mix-svgicon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="padding-24 desktop:padding-32">
                            <div class="display-flex flex-row flex-wrap justify-between">
                                <div class="display-flex flex-col flex-grow-1">
                                    <p class="font-secondary font-bold text-16 text-secondary">
                                        {{ selected.category }}
                                    </p>
                                    <h3 class="oswald text-32 leading-closely text-primary uppercase">
                                        {{ selected.title }}
                                    </h3>
                                </div>
                                <div
                                    class="display-hidden tablet:display-block min-width-128 margin-top-auto padding-left-16">
                                    <a class="cta-btn display-flex flex-row items-center padding-x-24 padding-top-12 padding-bottom-14 bg-gradient-to-right from-azure to-blue"
                                       :href="selected.path" target="_blank">
                                        <span class="oswald font-bold text-16 text-white uppercase">PRENOTA</span>
                                        <mix-svgicon icon="ui/arrow-top-right"
                                                     class="display-inline-block width-20 fill-current text-white margin-top-2 padding-left-8"></mix-svgicon>
                                    </a>
                                </div>
                            </div>
                            <div class="display-flex flex-col">
                                <div
                                    class="text-16 tablet:text-20 font-primary normalize leading-normal font-light padding-bottom-24 margin-top-32">
                                    {{ selected.intro }}
                                </div>
                                <div class="text-16 tablet:text-18 leading-relaxed normalize font-secondary paragraphs"
                                     v-html="selected.content"></div>
                            </div>
                            <div class="display-block width-full">
                                <ul class="display-flex flex-row flex-wrap">
                                    <li v-for="tag in selected.tags"
                                        class="display-inline-block font-family-tertiary text-primary text-9 uppercase padding-x-6 padding-y-3 margin-right-3 bg-onlygrey-20">
                                        {{ tag }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

import LazyLoad from 'vanilla-lazyload'

export default {
    name: 'Only4U',
    data() {
        return {
            els: '',
            selected: '',
            regions: '',
            lazyLoader: false,
            ModalLazyLoad: false,
            regionSelected: '',
            page: 1,
            loading: false,
            updating: true
        }
    },
    mounted() {
        this.loadData()
        this.getRegions()
    },
    methods: {
        getRegions() {
            axios.get('/api/only4u/regions')
                .then((response) => {
                    this.regions = response.data.data
                })
                .catch((error) => {
                    console.warn('Error')
                })
        },
        getPayload() {
            return [
                'filter[region]=' + this.regionSelected,
                'page=' + this.page
            ].join('&')
        },
        loadData() {
            this.updating = true
            axios.get('/api/only4u?' + this.getPayload())
                .then((response) => {
                    this.els = response.data.data
                    this.updating = false
                })
                .catch((error) => {
                    console.warn('Error')
                })
        },
        onRegionSelected(value) {
            console.log(value)
            this.regionSelected = value
            this.loadData()
        },
        ellipsis(input, length) {
            return input.length > length ? input.substring(0, length) + '...' : input
        },
        showModal(e) {
            this.selected = e
            this.$modal.show(this.uid)
            const scroll = window.scrollY
            document.documentElement.style.overflow = 'hidden'
            document.body.style.top = '-' + scroll + 'px'
        },
        opened(e) {
            this.$nextTick(() => {
                this.ModalLazyLoad = new LazyLoad({
                    container: e.ref,
                    elements_selector: '.lazy',
                    class_loaded: 'lazy__loaded',
                    class_loading: 'lazy__loading',
                    load_delay: 0,
                    use_native: false
                })
            })
        },
        beforeClose() {
            document.documentElement.style.overflow = ''
            document.body.style.top = ''
        },
        close() {
            document.documentElement.style.overflow = ''
            document.body.style.top = ''
            this.$modal.hide(this.uid)
            this.selected = false
        },
        closed() {
            this.ModalLazyLoad.destroy()
        },
    }
}

</script>
