<template>
  <section class="display-block margin-bottom-64">
    <gallery-thumbnails :els="elements" @show="showGallery()"></gallery-thumbnails>
    <modal :name="modalname" width="90%" height="90%" classes="tablet:max-height-800 vm--modal-visible">
      <vueper-slides :ref="galleryname"
                     :slide-content-outside="false"
                     :touchable="true"
                     :infinite="false"
                     :arrows="false"
                     class="no-shadow vueperslides--full-height bg-white">
        <vueper-slide
            v-for="(el, i) in elements"
            :key="i++">
          <template v-slot:content>
            <gallery-slide-template :el="el" :current="i" :total="elements.length" :onlyphoto="onlyphoto"></gallery-slide-template>
          </template>
        </vueper-slide>
      </vueper-slides>
      <div class="position-absolute inset-top-0 inset-right-0 width-64 display-flex justify-center items-center">
        <div class="width-64 height-64 cursor-pointer z-index-2 display-flex flex-col items-center justify-center"
             @click="closeGallery">
          <div class="width-16 margin-right-4 text-white">
            <mix-svgicon icon="ui/close" class="fill-current"></mix-svgicon>
          </div>
        </div>
      </div>
      <div class="position-absolute inset-top-0 inset-left-0 inset-bottom-0 width-48 display-flex justify-center items-center">
        <div class="width-48 height-48 cursor-pointer bg-white shadow-md rounded-full -margin-left-48 z-index-2 display-flex flex-col items-center justify-center"
             @click="$refs[galleryname].previous()">
          <div class="width-16 margin-right-4">
            <mix-svgicon icon="ui/chevron-left"></mix-svgicon>
          </div>
        </div>
      </div>
      <div class="position-absolute inset-top-0 inset-right-0 inset-bottom-0 width-48 display-flex justify-center items-center">
        <div class="width-48 height-48 cursor-pointer bg-white shadow-md rounded-full -margin-right-48 z-index-2 display-flex flex-col items-center justify-center"
             @click="$refs[galleryname].next()">
          <div class="width-16 margin-left-4">
            <mix-svgicon icon="ui/chevron-right"></mix-svgicon>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>

import { VueperSlide, VueperSlides } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import GalleryThumbnails from './GalleryThumbnails'
import GallerySlideTemplate from '../templates/GallerySlideTemplate'

export default {
  name: 'Gallery',
  props: {
    els: {
      required: true,
    },
    name: {
      required: true,
    },
    onlyphoto: {
      default: false
    }
  },
  data () {
    return {}
  },
  mounted () {
  },
  computed: {
    elements: function () {
      return JSON.parse(this.els)
    },
    galleryname: function () {
      return 'gallery' + this.name
    },
    modalname: function () {
      return 'modal' + this.name
    }
  },
  methods: {
    showGallery: function () {
      this.$modal.show(this.modalname)
    },
    closeGallery: function () {
      this.$modal.hide(this.modalname)
    }
  },
  components: { GalleryThumbnails, GallerySlideTemplate, VueperSlides, VueperSlide },
}

</script>
