<template>
  <div class="drawer" :class="{'drawer--active': isVisible}">
    <div class="drawer__actions">
      <button class="drawer__close" @click.prevent="close()">
        <svgicon name="xclose" class="icon"></svgicon>
      </button>
    </div>
    <div class="drawer__body">
      <button class="drawer__header__close" @click.prevent="close()">
        <svgicon name="xclose" class="icon"></svgicon>
      </button>
      <div class="drawer__content">
        <div class="cart-summary">
          <template v-if="el && el.items">
            <div class="cart-summary__addedtocart">
              <div class="cart-summary__addedtocart__media">
                <img :src="el.items[0].picture" class="cart-summary__addedtocart__media--image" />
                <span class="badge cart-summary__addedtocart__badge badge--success">
                  <svgicon name="check" class="icon "></svgicon>
                </span>
              </div>
              <div class="cart-summary__addedtocart__text">
                <span v-if="el.items && el.items.length > 1">{{ el.items.length }} capitoli PDF aggiunti al carrello</span>
                <span v-else>Prodotto aggiunto al carrello</span>
              </div>
              <div class="cart-summary__addedtocart__buttons">
                <a class="button button--offbeat-blue button--extra-small button--prev" @click.prevent="close()">
                  <svgicon name="chevron-left"></svgicon>
                  <span>Continua sullo Shop</span>
                </a>
                <a class="button button--solid button--extra-small button--next" :href="checkout">
                  <span>Vai al Checkout</span>
                  <svgicon name="chevron-right"></svgicon>
                </a>
              </div>
            </div>
          </template>
          <div class="cart-summary__content" :class="{'cart-summary__content--loading': loading}">
            <template v-if="loading">
              <div class="cart-summary__loader">
                <lp-loading></lp-loading>
              </div>
            </template>
            <template v-else>
              <h3 class="cart-summary__title">
                Il tuo carrello ({{ cart.number_of_items }}
                <span v-if="cart.number_of_items && cart.number_of_items === 0">Vuoto</span>
                <span v-else-if="cart.number_of_items && cart.number_of_items > 1">prodotti</span>
                <span v-else>prodotto</span>)
              </h3>
              <ul class="cart-summary__list">
                <li v-for="(item, index) in cart.items" class="cart-summary__list__item">
                  <div class="cart-summary__list__item__summary">
                    <div class="cart-summary__list__item__media">
                      <a class="cart-summary__list__item__media--link" :href="item.route">
                        <img :src="item.picture" class="cart-summary__list__item__media--image" :alt="item.title" />
                      </a>
                    </div>
                    <div class="cart-summary__list__item__info">
                      <div class="cart-summary__list__item__title">
                        <a :href="item.route" class="cart-summary__list__item__title--link">{{ item.title }}</a>
                      </div>
                      <template v-if="item.subtitle">
                        <div class="cart-summary__list__item__subtitle">
                          <span v-if="item.type==='chapters'">Capitolo in PDF - </span>{{ item.subtitle }}
                        </div>
                      </template>
                      <div class="cart-summary__list__item__type">{{ types[item.type] }}</div>
                      <template v-if="item.gadget && item.gadget.campaign && item.reference_id && item.reference_type">
                        <div class="cart-summary__list__item__extra">+ {{ item.gadget.campaign.name }} -- EUR {{ item.gadget.price }}</div>
                      </template>
                    </div>
                  </div>
                  <div class="cart-summary__list__item__totals">
                    <div class="cart-summary__list__item__price">EUR {{ item.selling_price.total.toFixed(2) }}</div>
                    <template v-if="item.selling_price.total !== item.list_price.total">
                      <div class="cart-summary__list__item__promo">EUR {{ item.list_price.total.toFixed(2) }}</div>
                    </template>
                    <div class="cart-summary__list__item__quantity">Qtà: {{ item.quantity }}</div>
                  </div>
                </li>
              </ul>
            </template>
          </div>
          <div class="cart-summary__footer">
            <div class="cart-summary__subtotal">
              <div class="cart-summary__subtotal__wrapper">
                <div class="cart-summary__subtotal__label">
                  <span>Totale parziale:</span>
                </div>
                <div class="cart-summary__subtotal__value">
                  <span v-if="cart.selling_total">EUR {{ cart.selling_total.toFixed(2) }}</span>
                  <span v-else>--</span>
                </div>
              </div>
            </div>
            <div class="cart-summary__buttons">
              <a class="button button--full button--next" :href="checkout">
                <span>Vai al checkout</span>
                <svgicon name="chevron-right"></svgicon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { eventsbus } from '../mixins/EventsBus.js'

  export default {

    name: 'DrawerCart',

    data () {
      return {
        isVisible: false,
        el: {},
        loading: false,
        cart: {},
        types: {
          books: 'Libro cartaceo',
          chapters: 'Capitolo PDF',
          epubmobis: 'ePUB-Mobi',
          pdfs: 'PDF eBook',
        },
      }
    },
    computed: {
      checkout: function () {
        return process.env.CHECKOUT_URL
      }
    },
    mounted () {
      eventsbus.$on('AddedToCart', function (e) {
        this.el = JSON.parse(e)
        this.isVisible = true
        this.getCart()
      }.bind(this))
      eventsbus.$on('ShowCart', function (e) {
        this.getCart()
        this.isVisible = true
      }.bind(this))
    },
    methods: {
      close () {
        this.isVisible = false
        this.el = {}
      },
      getCart () {
        this.loading = true
        let options = {
          method: 'GET',
          url: process.env.SHOP_URL + '/api/cart/get',
        }
        axios(options).then((res) => {
          if (res.data) {
            this.cart = res.data
          } else {
            console.log('Error on add/retrieving cart')
          }
        }).then(() => {
          this.loading = false
        })
      },
    },

  }

</script>
