/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hot-air-balloon': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M512 166C512 74.906 437.07.004 346.008 0H346c-44.738 0-103.898 23.863-137.863 73.941C195.847 65.582 181.238 61 166 61c-24.453 0-47.184 12.098-61.086 31.715A57.363 57.363 0 0091 91c-33.086 0-60 26.914-60 60 0 12.027 3.086 19.063 6.953 30H181.59c3.648 46.414 24.2 72.422 44.137 97.645 22.136 28.011 43.093 54.574 45.101 112.355H241v30h30v91h150v-91h30v-30h-29.832c1.988-52.332 22.965-80.371 45.125-109.953C488.766 251.055 512 220.039 512 166zm-121 0c0 47.242-6.844 69.828-14.77 95.977-5.515 18.191-11.671 38.515-16.183 69.023h-27.485c-4.253-31.18-10.683-51.25-16.437-69.21-8.11-25.313-15.11-47.177-15.11-95.712 0-76.633 24.18-136.074 44.989-136.078C364.27 30.004 391 84.508 391 166zM91 121c8.406 0 12.82 3.07 27.621 10.719l6.543-14.32C132.488 101.362 148.52 91 166 91a44.856 44.856 0 0127.703 9.54c-6.332 15.058-10.582 31.862-12.078 50.46H61c0-16.543 13.457-30 30-30zm120.02 45.078c0-66.098 40.84-107.055 84.011-125.16-17.312 35.355-24.015 85.387-24.015 125.16 0 53.227 8.035 78.309 16.543 104.863 5.343 16.688 10.84 33.852 14.707 60.059h-11.282c-10.187-31.05-26.586-51.8-41.722-70.953-20.52-25.965-38.242-48.39-38.242-93.969zM391 482h-90v-61h90zm-90.148-91c-.34-10.844-1.29-21.004-2.73-30.2h96.12c-1.648 9.255-2.726 19.415-3.09 30.2zm101.386-60h-11.843c4.085-25.797 9.386-43.305 14.546-60.32C413.2 243.43 421 217.69 421 166c0-40.578-6.605-90.617-25.238-126.574C446.21 59.328 482 108.562 482 166c0 44.047-18.406 68.613-39.715 97.063-14.375 19.183-29.89 39.91-40.047 67.937zm0 0"/><path pid="1" d="M135 241c-24.453 0-47.184 12.098-61.086 31.715A57.363 57.363 0 0060 271c-33.086 0-60 26.914-60 60 0 12.027 3.086 19.062 6.953 30h188.672c5.059-8.742 14.375-22.602 14.375-45 0-41.355-33.645-75-75-75zm42.438 90H30c0-16.543 13.457-30 30-30 8.406 0 12.82 3.07 27.621 10.719l6.543-14.32C101.488 281.362 117.52 271 135 271c24.813 0 45 20.188 45 45 0 5.133-.875 10.21-2.563 15zm0 0"/>'
  }
})
