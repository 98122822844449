<template>
  <l-map ref="map"
         :options="mapOptions"
         :zoom.sync="zooming">
    <l-tile-layer :url="url" :attribution="attribution" ref="layer"/>
    <l-feature-group ref="features">
      <l-marker v-for="(marker, index) in JSON.parse(markers)"
                :lat-lng="mapMarker(marker)"
                :key="index"
                @add="selfOpen($event, marker)"
                @click="clickOnMarker($event, marker)"
      >
        <l-icon :icon-url="markerUrl(marker, index)"
                :icon-size="[28, 28]"
                :icon-anchor="[14, 14]"
                class="shadow-md"/>
        <l-popup :options="{ autoClose: autoClose, closeOnClick: false }">
          <dynamic-map-card :el="marker"></dynamic-map-card>
        </l-popup>
      </l-marker>
    </l-feature-group>
  </l-map>
</template>

<script>
import {latLng} from 'leaflet'
import {LFeatureGroup, LIcon, LMap, LMarker, LPopup, LTileLayer} from 'vue2-leaflet'
import DynamicMapCard from './DynamicMapCard'

export default {
  name: 'DynamicMap',
  props: {
    markers: {
      required: true,
      type: String
    },
    zoom: {
      default: null
    },
    autoshow: {
      default: false
    },
    dragging: {
      default: true
    },
    zoomControl: {
      default: true
    },
    letClickOnMarkers: {
      default: false
    },
    layout: {
      default: 'white'
    },
    nearby: false
  },
  components: {
    DynamicMapCard,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LFeatureGroup,
    LPopup
  },
  data() {
    return {
      url: '',
      attribution: '',
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false,
        worldCopyJump: true,
        dragging: this.dragging,
        zoomControl: this.zoomControl
      },
      zooming: null,
      ModalLazyLoad: false
    }
  },
  watch: {},
  mounted() {
    this.mapLayout()
    this.$nextTick(() => {
      this.initialZoom()
    })
  },
  computed: {
    autoClose: function () {
      return !!(this.letClickOnMarkers)
    },
  },
  methods: {
    async initialZoom() {
      let map = this.$refs.map,
          bounds = this.$refs.features.mapObject.getBounds()
      map.fitBounds(bounds, {animate: false})
      map.mapObject.setZoom(Number((this.zoom ? this.zoom : (map.mapObject.getZoom() - .5))))
    },
    mapMarker(marker) {
      // from MongoDB - longitude/latitude
      return marker.coordinates.length === 2 && marker.coordinates[0] && marker.coordinates[1] ? latLng(this.latCalc(marker.coordinates[1]), this.lngCalc(marker.coordinates[0])) : null
    },
    latCalc(el) {
      let lat = this.numberize(el, 8)
      return lat + (Math.sign(lat) < 0 ? -.01 : .01)
    },
    lngCalc(el) {
      let lng = this.numberize(el, 8)
      return lng + (Math.sign(lng) < 0 ? -.02 : .02)
    },
    markerUrl(marker, index) {
      let mark = marker.icon === 'number' ? 'numbers/marker-number-' + (index + 1) : 'extras/marker-pin-circle'
      return '/vendor/markers/' + mark + '.png'
    },
    selfOpen(event, marker) {
      if (this.autoshow && marker.autoshow) {
        this.$nextTick(function () {
          event.target.openPopup()
        })
      }
    },
    clickOnMarker(event, marker) {
      if (this.letClickOnMarkers) {
        this.$nextTick(function () {
          event.target.openPopup()
        })
      }
    },
    mapLayout() {
      const layouts = ['black', 'white', 'voyager', 'outdoors']
      this.url = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png'
      if (layouts.indexOf(this.layout) >= 0) {
        switch (this.layout) {

          case 'black':
            this.url = 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
            this.attribution = '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            break

          case 'white':
            this.url = 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png'
            this.attribution = '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            break

          case 'outdoors':
            this.url = 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png'
            this.attribution = '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            break

          case 'voyager':
            this.url = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png'
            this.attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            break
        }
      }
    },
    getNearby() {

    }

  }
}
</script>
