<template>
  <div class="width-256">
    <template v-if="el.card">
      <figure>
        <a :href="el.card.link" class="position-relative display-block cursor-pointer">
          <div :data-bg="el.card.picture" class="width-full overflow-hidden padding-bottom-ratio-16/9 bg-cover bg-center lazy"></div>
        </a>
        <figcaption class="padding-16 padding-bottom-24">
                    <span class="font-family-tertiary text-9 uppercase text-grey-600 hover:text-azure cursor-pointer" @click.prevent="link(el.card.section_link)">
                        {{ el.card.section }}
                    </span>
          <h4 class="text-16 font-bold leading-snug text-grey-900 hover:text-azure cursor-pointer" @click.prevent="link(el.card.link)">
            {{ el.card.title }}
          </h4>
        </figcaption>
      </figure>
    </template>
    <template v-else>
      <div class="padding-16">
        {{ el.text }}
      </div>
    </template>
  </div>
</template>

<script>

import LazyLoad from 'vanilla-lazyload'

export default {
  name: 'LeafletDynamicMapCardTemplate',
  props: {
    el: { required: true, },
  },
  data () {
    return {
      ModalLazyLoad: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.ModalLazyLoad = new LazyLoad({
        elements_selector: '.lazy',
        class_loaded: 'lazy__loaded',
        class_loading: 'lazy__loading',
        load_delay: 0,
        use_native: false,
      })
    })
  },
  beforeDestroy () {
    this.ModalLazyLoad.destroy()
  },
  methods: {
    link: function (url) {
      window.location.href = url
    }
  },

}

</script>
