/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pinterest': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 2.138c-7.656 0-13.863 6.206-13.863 13.863 0 5.875 3.656 10.887 8.813 12.906-.119-1.094-.231-2.781.05-3.975.25-1.081 1.625-6.887 1.625-6.887s-.412-.831-.412-2.056c0-1.925 1.119-3.369 2.506-3.369 1.181 0 1.756.887 1.756 1.95 0 1.188-.756 2.969-1.15 4.613-.331 1.381.688 2.506 2.05 2.506 2.462 0 4.356-2.6 4.356-6.35 0-3.319-2.387-5.638-5.787-5.638-3.944 0-6.256 2.956-6.256 6.019 0 1.194.456 2.469 1.031 3.163a.408.408 0 0 1 .094.4c-.106.438-.338 1.381-.387 1.575-.063.256-.2.306-.463.188-1.731-.806-2.813-3.337-2.813-5.369 0-4.375 3.175-8.387 9.156-8.387 4.806 0 8.544 3.425 8.544 8.006 0 4.775-3.012 8.625-7.194 8.625-1.406 0-2.725-.731-3.175-1.594 0 0-.694 2.644-.863 3.294-.313 1.206-1.156 2.712-1.725 3.631 1.3.4 2.675.619 4.106.619 7.656 0 13.863-6.206 13.863-13.863 0-7.662-6.206-13.869-13.863-13.869z"/>'
  }
})
