<template>
  <div>
    <button class="button button--solid button--small" @click.prevent="confirm()" :disabled="(response.err == false) && (response.msg != '')">Elimina account</button>
    <div v-if="showconfirm">
      <transition name="modal-fade">
        <div class="modal__backdrop">
          <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <div class="modal__container">
              <section class="modal__body" id="modalDescription">
                <header class="modal__header" id="modalTitle">
                  Conferma operazione
                </header>
                <button class="modal__close" @click.prevent="close()" v-if=" !((response.err == false) && (response.msg != '')) ">
                  <svgicon name="xclose" class="icon"></svgicon>
                </button>
                <div class="grid grid__row">
                  <div class="width-full">
                    <form name="delete_account_frm" accept-charset="UTF-8" method="post" @submit.prevent="deleteaccount" v-if=" (response.err == false) && (response.msg == '') ">
                      Sei sicuro di voler procedere all'eliminazione dell'account? <br/> Per procedere conferma il tuo indirizzo email <br/>
                      <input type="text" v-validate="'required|email'" v-model="email" placeholder="Email" name="email" class="property__text-box" style="width: 99%; max-width: 99%;" />
                      <button class="button button--solid button--small mt-2" :disabled="email == '' || errors.first('email')">Conferma</button>  
                    </form>
                    <div v-else-if="response.err == true">
                      {{ response.msg }} <br/>
                      <button class="button button--solid button--small mt-2" @click.prevent="retry()">Riprova</button>
                    </div>
                    <div v-else>
                      {{ response.msg }} <br/>
                      <button class="button button--solid button--small mt-2" @click.prevent="refresh()">Fatto</button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </transition>

      <loading :active.sync="this.isWorking"
             :can-cancel="false"
             :is-full-page="true"
             color="#297cbb"></loading>

    </div>

    
    
  </div>
</template>

<script>

  export default {
    name: 'DeleteAccount',
    props: {
      callback: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showconfirm: false,
        email: "",
        isWorking: false,
        response: { 'err': false, 'msg': ''}
      }
    },
    methods: {
      confirm: function() {
        this.showconfirm = true
      },
      deleteaccount: function() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.isWorking = true
            axios.post(this.callback, { email: this.email }).then((response) => {
              this.isWorking = false
              
              this.response = response.data
            })
            .catch((error) => {
              console.log(error)
            })
          }
        })
      },
      close() {
        this.showconfirm = false
      },
      retry() {
        this.response = { 'err': false, 'msg': ''}
      },
      refresh() {
        location.href = location.href
      }
     
    },
  }

</script>
