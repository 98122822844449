<template>
  <div>
    <template>
      <button class="carousel__button carousel__button--previous" title="Previous" aria-label="Previous" @click="prev()">
        <svgicon name="chevron-left" class="icon"></svgicon>
      </button>
    </template>
    <template>
      <slick ref="slick" :options="slickOptions">
        <div class="teaser__container" v-for="(teaser, index) in teasers">
          <div class="teaser__image-container">
            <a :href="teaser.route" target="_blank">
              <img :src="teaser.picture" />
            </a>
          </div>
          <div class="teaser__inner">

            <div class="teaser__eyebrow" v-if="teaser.eyebrow">{{ teaser.eyebrow }}</div>
            <div class="teaser__title" v-if="teaser.title">
              <a :href="teaser.route" target="_blank">{{ teaser.title }}</a>
            </div>
            <div class="teaser__subtitle" v-if="teaser.subtitle">
              {{ teaser.subtitle }}
            </div>
            <div class="teaser__content" v-if="teaser.content">{{teaser.content}}</div>
          </div>
          <div class="teaser__footer">
              <div class="teaser__departure__container">
                <div class="teaser__departure">
                  <label>PARTENZE</label>
                  <div v-for="_date in teaser.departures" v-if="teaser.departures">
                    <a :class="getDateStatusClass(_date.status)" :href="_date.url" :title="getDateStatusLabel(_date.status)" target="_blank">{{_date.departureDate}}</a>
                  </div>
                  <div v-if="!teaser.departures">Su richiesta</div>
                </div>
                <div class="teaser__more__departure" v-if="teaser.departures && (teaser.more_departures > 0)">
                  +{{teaser.more_departures}} DATE
                </div>
              </div>
            <div class="clear"></div>
              <p class="teaser__quote">
                <template v-if="teaser.quote != '0.00'">
                  quote a partire da <b>{{ teaser.quote}} {{teaser.currency}}</b>
                </template>
                <template v-else>
                  &nbsp;
                </template>
              </p>
              <a class="button button--extra-small button--blue" :href="teaser.route" target="_blank">
                {{ target_btn_label }}
              </a>
          </div>
        </div>
      </slick>
    </template>
    <template>
      <button class="carousel__button carousel__button--next" title="Next" aria-label="Next" @click="next()">
        <svgicon name="chevron-right" class="icon"></svgicon>
      </button>
    </template>
  </div>
</template>

<script>

  import Slick from 'vue-slick'

  export default {

    name: 'TeaserSlider',
    props: {
        teasers: {
          required: true,
          type: Array
        },
        target_btn_label: {
            required: false,
            type: String,
            default: 'Scopri il viaggio'
        },
    },

    data() {
      return {
        slickOptions: {
          arrows: false,
          infinite: true,
          slidesToScroll: 4,
          slidesToShow: 4,
          zIndex: 1,
          responsive: [
              {
                  breakpoint: 1200,
                  settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      swipe: true,
                      touchThreshold: 10,
                  },
              },

              {
                  breakpoint: 992,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      swipe: true,
                      touchThreshold: 10,
                  },
              },
            {
              breakpoint: 544,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                swipe: true,
                touchThreshold: 10,
              },
            },
          ],
        },
      }
    },

    components: { Slick },
    methods: {
      next() {
        this.$refs.slick.next()
      },
      prev() {
        this.$refs.slick.prev()
      },
      getDateStatusClass(status) {
          let _class = 'ca-travel-date'
          if(status === 'available') {
              _class += ' ca-travel-date--available'
          }
          else if (status == 'last') {
              _class += ' ca-travel-date--last'
          }
          else if (status == 'request') {
              _class += ' ca-travel-date--request'
          }
          return _class
      },
      getDateStatusLabel(status) {

          if(status === 'available') {
              return 'Disponibile'
          }
          else if (status == 'last') {
              return 'Ultimi posti'
          }
          else if (status == 'request') {
              return 'Su richiesta'
          }
      },

    },



  }

</script>

<style scoped>

  p {
    text-rendering: optimizeLegibility;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  .teaser__container {
    position: relative;
    height: 100%;
  }

  .teaser__eyebrow {
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 100;
    margin-top: 1rem;
  }

  .teaser__title {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-top: 1rem;
    min-height: 6rem;
    font-family: "Zilla Slab", sans-serif;
  }

  .teaser__title a, .teaser__title a:link, .teaser__title a:visited {
    color:#2c3643 !important;
  }


  .teaser__subtitle {
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.1rem;
    color: #444;
    font-size: 0.75rem;
    min-height: 5.3rem;
    font-family: Montserrat, sans-serif;
  }

  .teaser__inner {
    position: relative;
    margin: 0 1rem;
    overflow: hidden;
    text-align: left;
  }

  .teaser__image-container {
    margin: 0 1rem;
  }

  .teaser__content {
    font-family: Montserrat, sans-serif;
    margin-top: 1rem;
    position: relative;
    font-size: 1rem;
    line-height: 1.2rem;
    min-height: 5.6rem;
  }

  .teaser__quote {
    font-family: Montserrat, sans-serif;
    margin-top: 1rem;
    font-size: 0.7rem;
    line-height: 1;
    clear: left;
  }

  .teaser__departure__container {
    display: flex;
    width: 90%;
    margin-top: 1rem;
    height: 3rem;
  }

  .teaser__departure {
    font-family: Montserrat, sans-serif;
    font-size: 0.75rem;
    flex: 1;
    flex-grow: 3;
  }

  .teaser__more__departure {
    font-weight: 700;
    font-size: .8rem;
    flex:1;
  }

  .teaser__departure > label {
    float:left;
    width: 5.2rem;
    color: #999;
    font-weight: 600;
  }

  .teaser__departure .ca-travel-date {
    float:left;
    width: 5.2rem;
  }

  .carousel__button {
     height: auto;
     top: 152px;
  }

  .carousel__button > svg {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .teaser__footer {
    width: 100%;
    margin: 0 1rem;
    background-color: white;
    text-align: left;
  }

  .carousel__button--previous {
    left: 0;
    cursor: pointer;
  }
  .carousel__button--next  {
    right: 0;
    cursor: pointer;
  }
  .carousel__button--previous:after {
    display: none !important;
  }

  .carousel__button--previous:before {
    display: none !important;
  }

  .carousel__button--next:after {
    display: none !important;
  }

  .carousel__button--next:before {
    display: none !important;
  }

  .carousel__button > svg {
    color: black;
  }

  .button--blue {
    padding: 0.7rem 1.25rem;
    border: 1px solid #00a699;
    background-color: white;
    color: #00a699;
    font-family: Montserrat,sans-serif;
    font-size: .6rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0;
    border-radius: 2rem;
    margin-top: 2rem;
  }

  .button--blue:hover {
    background-color: #00a699;
    color: #fff;
  }

  .ca-travel-date {
    color: black;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  .ca-travel-date:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #444;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 4px;
  }

  .ca-travel-date--available::before {
    background-color: green;
  }
  .ca-travel-date--available:hover {
    color: green;
  }
  .ca-travel-date--last::before {
    background-color: orange;
  }
  .ca-travel-date--last:hover {
    color: orange;
  }
  .ca-travel-date--request::before {
    background-color: #87cefa;
  }
  .ca-travel-date--request:hover {
    color: #87cefa;
  }

  .clear {
    clear: left;
  }
</style>
