/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'instagram': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 2.9c4.3 0 4.8 0 6.5.1 1.6.1 2.4.3 3 .6.7.3 1.3.6 1.8 1.2.6.6.9 1.1 1.2 1.8.2.5.5 1.4.5 2.9.1 1.7.1 2.2.1 6.5s0 4.8-.1 6.5c-.1 1.6-.3 2.4-.6 3-.3.7-.6 1.3-1.2 1.8-.6.6-1.1.9-1.8 1.2-.6.2-1.4.5-3 .6-1.7.1-2.2.1-6.5.1s-4.8 0-6.5-.1c-1.6-.1-2.4-.3-3-.6-.7-.3-1.3-.6-1.8-1.2-.6-.6-.9-1.1-1.2-1.8-.1-.6-.4-1.5-.4-3-.1-1.7-.1-2.2-.1-6.5s0-4.8.1-6.5c0-1.5.3-2.4.5-2.9.3-.7.6-1.3 1.2-1.8.6-.6 1.1-.9 1.8-1.2.6-.3 1.5-.6 3-.6 1.7-.1 2.2-.1 6.5-.1zM16 0c-4.3 0-4.9 0-6.6.1S6.5.4 5.5.8c-1.1.4-1.9 1-2.8 1.8-.9 1-1.5 1.9-1.9 2.9S.1 7.7.1 9.4C0 11.1 0 11.7 0 16s0 4.9.1 6.6c.1 1.7.3 2.9.7 3.9.4 1.1 1 1.9 1.8 2.8.9.9 1.8 1.4 2.8 1.8s2.2.7 3.9.7c1.8.2 2.4.2 6.7.2s4.9 0 6.6-.1c1.7-.1 2.9-.3 3.9-.7 1.1-.4 1.9-1 2.8-1.8.9-.9 1.4-1.8 1.8-2.8s.7-2.2.7-3.9c.2-1.8.2-2.4.2-6.7s0-4.9-.1-6.6c-.1-1.7-.3-2.9-.7-3.9-.4-1.1-1-1.9-1.8-2.8-.9-.9-1.8-1.4-2.8-1.8S24.4.2 22.7.2C20.9 0 20.3 0 16 0z"/><path pid="1" d="M16 7.8c-4.5 0-8.2 3.7-8.2 8.2s3.7 8.2 8.2 8.2 8.2-3.7 8.2-8.2-3.7-8.2-8.2-8.2zm0 13.5c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3zM26.4 7.5a1.9 1.9 0 1 1-3.8 0 1.9 1.9 0 0 1 3.8 0z"/>'
  }
})
