/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'opera': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M451.492 300c2.031-31.883 10.477-63.395 24.758-91.957L490.27 180H466c-16.863 0-33.008 3.117-47.902 8.79-7.875-48.692-31.528-93.411-67.914-127.657C308.3 21.71 253.543 0 196 0h-28.027l15.547 23.32c19.511 29.27 34.242 61.035 43.906 94.293C200.664 99.766 169.02 90 136 90h-24.27l10.852 21.707a268.23 268.23 0 0114.758 35.809C110.62 129.719 79.04 120 46 120H21.73l10.852 21.707C51.176 178.887 61 220.512 61 262.082V270H0v150h512V300zm-30.066 0H362.07c6.114-42.559 37.852-76.96 79.024-87.016-11.297 27.645-17.985 57.203-19.668 87.016zm-31.586-96.402c-31.606 21.672-53.563 56.43-58 96.402h-12.3L271 275.73v-62.566c0-63.797-16.188-125.852-47.043-181.156 86.898 12.582 156.195 82.89 165.883 171.59zm-152.05-37.27a344.06 344.06 0 013.21 46.84V270h-60v-37.918c0-37.496-7.195-75.039-20.95-109.941 30.005 5.394 57.38 20.82 77.74 44.187zM70.046 152.133c30.504 5.469 58.219 21.281 78.687 45.289a267.908 267.908 0 012.266 34.66V270H91v-7.918c0-37.5-7.195-75.043-20.953-109.95zM482 390H331v-30h-30v30h-30v-30h-30v30h-30v-30h-30v30H30v-90h222.46l60 30H482zm0 0"/>'
  }
})
