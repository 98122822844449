<template>
  <form>
    <div class="property property--full">
      <div class="property__value property__value--select">
        <select class="property__select" name="category" id="category" v-model="selected" @change="changeSelect()">
          <option value="">- Seleziona -</option>
          <option v-for="option in els" :value="option.value">{{ option.text }}</option>
        </select>
      </div>
    </div>
  </form>
</template>

<script>

  export default {

    inherit: true,
    name: 'Select',
    props: {
      els: {
        required: true,
      },
      def: {
        type: String
      }
    },
    data() {
      return {
        selected: this.def && this.els[this.def] ? this.els[this.def].value : ''
      }
    },
    methods: {
      changeSelect() {
        window.location = this.els[this.selected].route
      },
    },
  }

</script>
