/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'yacht': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M56 48H37v-1h13a1 1 0 0 0 .907-1.42L35.944 13.279c.026-.09.056-.18.056-.279V1a1 1 0 1 0-2 0v8.083l-.093-.2c-.167-.36-.506-.603-.93-.58-.397.01-.752.253-.903.622L32 9.106V5a1 1 0 0 0-1.86-.511l-22 37A.998.998 0 0 0 9 43h9.146l-1.072 2.622a.993.993 0 0 0-.067.382.985.985 0 0 0 .058.345.956.956 0 0 0 .106.209c.022.032.051.058.076.087.009.011.012.025.022.036.007.007.016.009.023.016a.966.966 0 0 0 .293.206c.022.01.043.017.065.025A.96.96 0 0 0 18 47h17v1H10a1 1 0 0 0-.857.485l-6 10A1.001 1.001 0 0 0 4 60h38c5.358 0 9.447-5.09 9.948-10H56a1 1 0 1 0 0-2zM33.057 11.806l5.013 10.822c-3.436 7.541-4.027 8.043-9.571 12.742a303.336 303.336 0 0 0-7.885 6.879l12.443-30.443zM10.758 41L30 8.639v5.36L18.963 41h-8.205zm9.801 4a294.594 294.594 0 0 1 9.234-8.104c5.329-4.518 6.349-5.388 9.381-11.885L48.435 45H20.559zM42 58H5.766l1.8-3h40.221c-1.432 1.767-3.458 3-5.787 3zm7.045-4.991C49.029 53.008 49.016 53 49 53H8.766l1.8-3H49.939a9.746 9.746 0 0 1-.894 3.009z"/>'
  }
})
