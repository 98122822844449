<template>
  <div class="fullsize marketing-article__map">
    <l-map
      :zoom="setZoom"
      :center="center"
      :options="mapOptions">
      <l-tile-layer :url="url" :attribution="attribution" />
      <template v-if="markers">
        <l-marker v-for="(marker, index) in JSON.parse(markers)" :lat-lng="mapMarker(marker)" :key="index">
          <l-icon
            :icon-url="markerUrl(marker)"
            :icon-size="[30, 41]"
            :icon-anchor="[15, 41]"
          />
        </l-marker>
      </template>
    </l-map>
  </div>
</template>

<script>
  import { latLng } from 'leaflet'
  import { LIcon, LMap, LMarker, LTileLayer } from 'vue2-leaflet'

  export default {
    name: 'LeafletMap',
    props: {
      lng: {
        required: true,
        type: String
      },
      lat: {
        required: true,
        type: String
      },
      markers: {
        required: false,
        type: String
      },
      zoom: {
        type: String,
        default: '13'
      },
    },
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LIcon
    },
    data () {
      return {
        url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        // withPopup: latLng(47.41322, -1.219482),
        // withTooltip: latLng(47.41422, -1.250482),
        mapOptions: {
          zoomSnap: 0.5,
          scrollWheelZoom: false
        },
      }
    },
    computed: {
      center () {
        return latLng(this.numberize(this.lat, 8), this.numberize(this.lng, 8))
      },
      setZoom () {
        return this.numberize(this.zoom, 1)
      },
    },
    methods: {
      mapMarker (marker) {
        return marker.lat && marker.lng ? latLng(this.numberize(marker.lat, 8), this.numberize(marker.lng, 8)) : null
      },
      markerUrl (marker) {
        return '/vendor/leaflet/markers/marker-icon-' + marker.icon + '.png'
      }
    }
  }
</script>
