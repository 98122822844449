/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-share-twitter': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" _fill="#4DA7DE" class="st7" d="M438.7 166.5v11.6c0 30.9-5.8 62.2-17.4 94-11.6 31.5-28.7 60-51.2 85.5-22.6 25.5-50.5 46.5-83.7 62.8-33 16-71 24.1-114 24.1-13.1 0-26-.9-38.7-2.7-12.8-1.8-25.2-4.5-37.2-8s-23.7-8-35-13.4c-11.3-5-22.1-11-32.5-17.8 3.6.3 7.3.5 11.1.7 3.9.2 7.6.2 11.1.2 21.7 0 42.5-3.4 62.6-10.2 20-6.8 38.1-16.8 54.1-29.8-20.5 0-38.7-6.2-54.8-18.5-16-12.3-27.2-27.8-33.4-46.5 3 .9 5.9 1.4 8.7 1.6 2.8.2 5.9.2 9.1.2 4.5 0 8.7-.2 12.7-.7 4-.4 8.1-1.1 12.2-2C101.1 293 83.3 282.2 69 265c-14.3-17.2-21.4-37-21.4-59.2v-.7-1.1c6.2 3.6 12.8 6.4 19.8 8.5 7 2.1 14.3 3.1 22 3.1-12.5-8-22.6-18.9-30.3-32.7-7.6-13.9-11.5-28.8-11.5-44.9 0-8.3 1.2-16.5 3.6-24.5 2.4-8 5.6-15.6 9.8-22.7 11.3 14.3 24 27.2 38.3 38.8 14.3 11.6 29.5 21.6 45.9 30.1 16.3 8.5 33.6 15.2 51.7 20.3 18.4 5.1 37.3 8 56.6 8.9-.9-3.6-1.6-7.1-2-10.7-.4-3.6-.7-7.1-.7-10.7 0-12.8 2.4-24.9 7.1-36.5 5-11.3 11.8-21.2 20.3-29.6 8.5-8.5 18.3-15.1 29.6-19.8 11.3-5 23.5-7.6 36.5-7.6 13.7 0 26.3 2.7 38.1 8 11.7 5.3 21.9 12.5 30.5 21.4 10.7-2.1 21.1-5 31.2-8.7 10.1-3.7 19.6-8.2 28.5-13.6-3.6 10.7-8.9 20.5-16 29.4-7.1 8.9-15.4 16.3-24.9 22.3 9.2-1.2 18.3-3 27.2-5.3 8.9-2.4 17.7-5.6 26.3-9.8-6.2 9.2-13.3 18-21.2 26.3-8 8.1-16.4 15.7-25.3 22.5z"/>'
  }
})
