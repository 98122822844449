/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dropdown-arrow': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M15 17l-4-4h8" fill-rule="evenodd"/>'
  }
})
