<template>
  <div class="accordion">
    <button @click="toggle()" class="accordion__button">
      <slot name="header"></slot>
      <svgicon name="plus" class="icon" :class="{ 'icon--rotate-45': show }"></svgicon>
    </button>
    <div class="accordion__wrapper">
      <div class="accordion__item" :class="{ 'accordion__item--collapsed': !show }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

  export default {

    name: 'collapsible',
    props: {
      showed: {
        default: true,
      },
    },
    data() {
      return {
        show: this.showed,
      }
    },
    methods: {
      toggle() {
        this.show = !this.show
      },
    },
  }

</script>
