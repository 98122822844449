/* eslint-disable */
require('./add')
require('./arrow-next')
require('./arrow-prev')
require('./bed')
require('./best-in-europe')
require('./best-in-travel')
require('./boat')
require('./brackets')
require('./calendar')
require('./cash-delivery')
require('./castle')
require('./check')
require('./chevron-down')
require('./chevron-left')
require('./chevron-right')
require('./chevron-up')
require('./controls')
require('./credit-card')
require('./delivery-truck')
require('./dolomiti-venete')
require('./dropdown-arrow')
require('./earth-logo')
require('./email')
require('./evaneos-claim')
require('./evaneos-collaboration')
require('./evaneos-create-travel')
require('./evaneos-logo')
require('./evaneos-yoga')
require('./eye')
require('./facebook')
require('./faux-left-arrow')
require('./faux-right-arrow')
require('./flipboard')
require('./gift')
require('./hamburger')
require('./informations')
require('./instagram')
require('./insurance')
require('./logout')
require('./lp-globe-logo')
require('./lp-logo')
require('./lp-shop-logo')
require('./lp-stack-logo')
require('./magazine')
require('./mailbox')
require('./map-location')
require('./map')
require('./mouse')
require('./package')
require('./pdf')
require('./pen')
require('./pinterest')
require('./plus')
require('./promotion')
require('./quetzal-logo')
require('./quotation')
require('./rucksack')
require('./search')
require('./shopping-bag')
require('./social-share-facebook')
require('./social-share-line')
require('./social-share-pinterest')
require('./social-share-skype')
require('./social-share-telegram')
require('./social-share-twitter')
require('./social-share-whatsapp')
require('./suitcase')
require('./telephone')
require('./transports')
require('./travel')
require('./twitter')
require('./user')
require('./xclose')
require('./yacht')
require('./youtube')
