/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M508.667 125.707a10.623 10.623 0 00-15.04 0L255.76 363.573 18 125.707c-4.267-4.053-10.987-3.947-15.04.213a10.763 10.763 0 000 14.827L248.293 386.08a10.623 10.623 0 0015.04 0l245.333-245.333c4.161-4.054 4.161-10.88.001-15.04z"/>'
  }
})
