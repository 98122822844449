/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cristo-rei': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M466 90H301V45c0-24.813-20.187-45-45-45s-45 20.187-45 45v45H46v30h30v60h105v180h-30v152h210V360h-30V180h105v-60h30zM211 194.959C223.544 204.397 239.129 210 256 210s32.456-5.603 45-15.041V270h-90zM256 180c-24.813 0-45-20.187-45-45v-15h90v15c0 24.813-20.187 45-45 45zM241 45c0-8.271 6.729-15 15-15s15 6.729 15 15v45h-30zM106 150v-30h75v30zm225 332H181v-92h150zM211 360v-60h90v60zm195-210h-75v-30h75z"/>'
  }
})
