export default {

  methods: {
    /**
     * Update Google Analytics tracking
     *
     * @param title
     * @param location
     * @param page
     */
    updatePageTracking: function (title, location, page) {
      'use strict'
      this.$ga.page({
        page: page ? page : '/',
        title: title ? title : document.title,
        location: location ? location : window.location.href,
      })
      return true
    },
    //Update current url using html5 states
    updateCurrentUrl: function (url, title) {
      if (typeof (history.replaceState !== 'undefined')) {
        var obj = { page: title, url: url }
        history.replaceState(obj, obj.page, obj.url)
      } else {
        location.href = url
      }
      return null
    },
    //Remove a parameter from the current url.
    //For example, i can remove 'auth' part from www.mysite.com/blog?auth=1&page=2
    // simply calling removeParamFromCurrentUrl('auth'), that give me the following String
    // www.mysite.com/blog?page=2
    removeParamFromCurrentUrl: function (paramName) {

      var base_url = location.href

      var search = location.search

      base_url = base_url.replace(search, '')

      search = search.replace('\?', '')
      search = search.split('&')

      var index = null
      for (var i = 0; i < search.length; i++) {
        if (search[i].indexOf(paramName + '=') !== -1) {
          index = i
          break
        }
      }

      if (index !== null) {
        search.splice(index, 1)
      }

      search = search.join('&').trim()

      if (search != '') {
        search = '?' + search
      }

      return base_url + search
    },
    //Remove a segment from the current url.
    //For example, i can remove 'auth' part from www.mysite.com/blog/auth/page
    // simply calling removeSegmentFromUrl('auth'), that give me the following
    // www.mysite.com/blog/page
    removeSegmentFromUrl: function (url, segmentName) {
      url = url.split('/')

      var index = null
      for (var i = 0; i < url.length; i++) {
        if (url[i].indexOf(segmentName) !== -1) {
          index = i
          break
        }
      }

      if (index !== null) {
        url.splice(index, 1)
      }

      url = url.join('/').trim()

      return url
    },
    //Pure Javascript scroll to element animation.
    //A Typical usage is the scrollTo(document.body, 0, 600)
    //that will scroll to the top of the page
    scrollToTop: function (element, to, duration) {
      if (duration <= 0) return
      var difference = to - element.scrollTop
      var perTick = difference / duration * 10

      setTimeout(function () {
        element.scrollTop = element.scrollTop + perTick
        if (element.scrollTop == to) return
        this.scrollToTop(element, to, duration - 10)
      }.bind(this), 10)
    },
    slugify: function (string) {
      let a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
      let b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
      let p = new RegExp(a.split('').join('|'), 'g')
      return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with ‘and’
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple — with single -
        .replace(/^-+/, '') // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
    },
    numberize: function (string) {
      return parseFloat(parseFloat(string).toFixed(2))
    },
  },

}
