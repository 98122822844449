<template>
  <section class="mastheadslider relative">
    <slick ref="slick" :options="slickOptions" @afterChange="current">
      <div v-for="(slide, index) in slides" class="slick-slide">
        <div class="homepage-slide" style="transform:translateZ(0);">
          <div class="homepage-slide__image-container" :style="{ backgroundImage: 'url(' + slide.picture + ')' }"></div>
          <div class="gradient-overlay"></div>
          <div class="homepage-slide__container">
            <div class="homepage-slide__claim">
              <a :href="slide.route">
                <div class="homepage-slide__eyebrow">
                  {{ slide.eyebrow }}
                </div>
              </a>
              <h1 class="homepage-slide__heading">
                <a :href="slide.route">
                  {{ slide.title }}
                </a>
              </h1>
              <div class="callout-link">
                <div class="separator"></div>
                <a class="button button--hero button--next" :href="slide.route">
                  {{ slide.label }}
                  <svgicon name="chevron-right" class="icon--white"></svgicon>
                </a>
              </div>
            </div>
            <div class="sponsor" v-if="slide.sponsored_by_name">
              <a>
                <span class="label" v-if="slide.sponsored_by_title">{{ slide.sponsored_by_title }}</span>
                <img :src="slide.sponsored_by_logo" v-if="slide.sponsored_by_logo"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </slick>
    <ul class="slick-dots container">
      <li v-for="(slide, index) in slides" :class="{ 'slick-active': (currentSlide == index+1) }">
        <button @click="moveTo(index)">
          {{ index }}
        </button>
      </li>
    </ul>
    <div class="slick-tab-navigation">
      <div class="container" style="padding: 0 !important">
        <div class="sticky-tab-container">
          <nav>
            <div v-for="(slide, index) in slides" class="nav-item" ref="navitem">
              <div class="nav-zone" @click="moveTo(index)"></div>
              <div class="nav-text">
                <div class="number-marker">{{ index + 1 }}
                  <div class="number-marker-diamond"></div>
                </div>
                <span>{{ slide.title }}</span>
              </div>
            </div>
          </nav>
          <span class="slick-tab-activebar" :style="{ left: (slideWidth * (currentSlide-1)) + 'px', width: slideWidth + 'px' }"></span>
        </div>
        <div class="sticky-icon-container">
          <div class="item" @click="scroll">
            <div class="scroll-indicator-mouse">
              <svgicon name="mouse" class="icon icon--white"></svgicon>
            </div>
            <div class="scroll-indicator-arrows">
              <svgicon name="chevron-down" class="icon icon--white"></svgicon>
              <svgicon name="chevron-down" class="icon icon--white"></svgicon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import Slick from 'vue-slick'
import _ from 'lodash'

export default {

  name: 'MastheadsSlider',
  props: ['slides'],

  data() {
    return {
      slickOptions: {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        fade: true,
        infinite: true,
        speed: 500,
        zIndex: 1,
      },
      currentSlide: 1,
      slideWidth: 300,
      navItem: 100,
    }
  },

  components: {
    Slick,
  },

  watch: {},

  methods: {
    moveTo(slide) {
      this.$refs.slick.goTo(slide)
    },
    current(evn, slick, current) {
      this.currentSlide = current + 1
    },
    getSlideWidth() {
      this.slideWidth = _.head(this.$refs.navitem).clientWidth
    },
    scroll() {
      // console.log(this.$el.clientHeight)
    },
  },

  mounted() {
    window.addEventListener('load', this.getSlideWidth)
    window.addEventListener('resize', this.getSlideWidth)
  },

  beforeDestroy() {
    window.removeEventListener('load', this.getSlideWidth)
    window.removeEventListener('resize', this.getSlideWidth)
  },

}

</script>
