/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-share-facebook': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" _fill="#3E5B98" d="M208.1 489.4V279.6h-70.8V198h70.8v-60c0-17.6 2.5-33 7.6-46.2 5.1-13.4 12.2-24.7 21.3-33.8 9.1-9.1 20.1-15.9 32.9-20.4 13.1-4.8 27.5-7.2 43-7.2 14.9 0 28.2.4 39.9 1.1 11.7.7 19.3 1.6 22.9 2.5v72.6h-43c-17 0-28 3.7-32.9 11-4.9 7.3-7.4 16.8-7.4 28.5v52H373l-10.8 81.6h-69.9v209.7h-84.2z"/>'
  }
})
