;(function ($) {

  let $doc = $(document),
    $body = $('body'),
    $window = $(window),
    g = void 0,
    f = void 0;

  $.fn.updateTracking = function (e, a) {
    var page = e || document.location.pathname;
    if (e) {
      $(this).on(e, function () {
        ga('send', 'pageview', page);
      });
    } else {
      ga('send', 'pageview', page);
    }
  };

  $.fn.overlay = function (method) {
    "use strict";
    var methods = {
      init() {
        let me = $('<div class="overlay" />');
        return me;
      },
      show() {
        let i = this;
        i.appendTo($('body'));
        setTimeout(function () {
          i.addClass('is-visible');
        }, 200);
        return i;
      },
      burrow() {
        let i = this;
        i.removeClass('is-visible'),
          setTimeout(function () {
            i.detach();
          }, 200);
        return i;
      }
    };
    if (methods[method]) return methods[method].apply(this, Array.prototype.slice.call(arguments, 1))
    else if (typeof method === 'object' || !method) return methods.init.apply(this, arguments);
  };

  $.fn.headerNav = function () {
    "use strict";
    $doc.find('[data-nav="item"]').each(function (a, b) {
      var c = $(this), i = c.find('[data-nav="subnav"]'), f = void 0, g = void 0;
      0 !== i.length &&
      c.on('mouseenter', function () {
        clearTimeout(f), g = setTimeout(function () {
          i.addClass('is-visible');
        }, 150);
      }), c.on('mouseleave', function () {
        clearTimeout(g), f = setTimeout(function () {
          i.removeClass('is-visible');
        }, 300);
      });
    });
    return this;
  };

  $.fn.mobileNav = function () {
    "use strict";
    let $nav = $('[data-nav="mobile"]');
    $nav.css('z-index', -1);
    this.on('click touchstart', '[data-nav="mobile-toggle"]', function (e) {
      e.preventDefault();
      let $curtain = $doc.overlay();
      $curtain.overlay('show'), $nav.removeAttr("style").addClass('is-visible');
      $curtain.on('click touchstart', function () {
        $nav.removeClass('is-visible').css('z-index', -1), $curtain.overlay('burrow');
      });
    });
    var $items = $doc.find('[data-nav="mobile-item"]');
    var $subitems = $doc.find('[data-nav="mobile-subnav"]');
    $items.each(function (a, b) {
      var c = $(this), i = c.find('[data-nav="mobile-subnav"]'), f = g = void 0;
      0 !== i.length && c.on('click touchstart', function () {
        if (!c.hasClass('is-clicked')) {
          $subitems.removeClass('is-expanded'), $items.removeClass('is-clicked');
          clearTimeout(f), g = setTimeout(function () {
            i.addClass('is-expanded'), c.addClass('is-clicked');
          }, 150);
        } else {
          i.removeClass('is-expanded'), c.removeClass('is-clicked');
        }
      });
    });
    return this;
  };

  $.fn.showMore = function () {
    "use strict";
    $doc.find('[data-showmore="more-toggle"]').each(function (a, b) {
      var t = $(this), c = t.next('[data-showmore="content"]'), l = c.find('[data-showmore="less-toggle"]'), s = 0;
      t.on('click touchstart', function () {
        s = $window.scrollTop();
        c.addClass('is-visible');
        t.addClass('is-hidden');
      });
      l.on('click touchstart', function () {
        c.removeClass('is-visible');
        t.removeClass('is-hidden');
        $window.scrollTop(s);
      });
    });
    return this;
  };

  $.fn.topChoices = function () {
    "use strict";
    let $topChoices = $('.topchoices');
    $topChoices.css('z-index', -1);
    this.on('click touchstart', '[data-action=topchoices]', _.debounce(function (e) {
      e.preventDefault();
      let $curtain = $doc.overlay();
      $curtain.overlay('show'), $topChoices.removeAttr("style").addClass('is-visible');
      $doc.keyup(function (e) {
        e.stopPropagation(), e.keyCode == 27 ? ($topChoices.removeClass('is-visible').css('z-index', -1), $curtain.overlay('burrow')) : null;
      });
      $curtain.on('click touchstart', function () {
        $topChoices.removeClass('is-visible').css('z-index', -1), $curtain.overlay('burrow');
      });
      $topChoices.on('click touchstart', '[data-action=topchoicesclose]', function (e) {
        e.stopPropagation(), $topChoices.removeClass('is-visible').css('z-index', -1), $curtain.overlay('burrow');
      });
    }, 200, true));
    return this;
  };

  $.fn.dropdownToggle = function () {
    "use strict";
    this.on('click touchstart', '[data-action=dropdown-toggle]', function (ev) {
      var $button = $(this),
        $container = $('#' + $button.data('ref'));
      $container.toggleClass('is-visible');
      $doc.one('click touchstart', function (e) {
        e.stopPropagation(), (!$container.is(e.target) && !$button.is(e.target) || $button.has(e.target).length > 0) ? $container.removeClass('is-visible') : false;
      });
    });
    return this;
  };

  $.fn.popout = function () {
    "use strict";
    this.on('click touchstart', '[data-action=pop-out]', function (ev) {
      let $this = $(this),
        ref = $('[class*="' + $this.data('ref') + '"]'),
        elem = $('<div class="popout"><header class="popout__header"><h4 class="popout__heading">' + $this.data('title') + '</h4><button class="popout__close"><svg aria-label="close" class="icon--dark-grey"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#cross"></use></svg></button></header><div class="popout__content"></div></div>'),
        $curtain = $doc.overlay(),
        $parent = ref.parent();
      ref.detach();
      elem.on('click touchstart', '.popout__close', function () {
        elem.toggleClass('is-visible'),
          setTimeout(function () {
            $curtain.overlay('burrow'), ref.detach(), $parent.append(ref), elem.remove();
          }, 200);
      });
      $curtain.overlay('show');
      elem.appendTo($body);
      elem.find('.popout__content').append(ref);
      setTimeout(function () {
        elem.addClass('is-visible');
      }, 200);
    });
    return this;
  };

  $.fn.smoothScroll = function () {
    "use strict";
    this.on('click touchstart', '[data-action=smooth-scroll]', function (ev) {
      let $this = $(this),
        ref = $this.data('ref'),
        segment = $(ref).closest('div[class*="segment"]');
      $("html, body").stop().animate({
        scrollTop: $(segment).offset().top + (parseInt($(segment).css('padding-top')) / 2) - 100
      }, 500);
      return false;
    });
    return this;
  };

  $.fn.fixedNavBar = function () {
    "use strict";
    let $elem = $('[data-action=fixed-nav]');
    if ($elem.length) {
      let $placeholder = $elem.prev(),
        offtop = $placeholder.offset().top;
      $window.on('scroll', _.throttle(function () {
        if ($window.scrollTop() > offtop) {
          $elem.addClass('is-fixed'), $placeholder.addClass('is-fixed');
        } else {
          $elem.removeClass('is-fixed'), $placeholder.removeClass('is-fixed');
        }
      }, 100));
      $window.on('resize', _.throttle(function () {
        offtop = $placeholder.offset().top;
      }));
    }
  };

})
(jQuery);
