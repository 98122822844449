<template>
  <div class="flex flex-col flex-wrap relative rounded-16 overflow-hidden">
    <video ref="videoplayer"
           class="video-js"
           preload
           :playsinline="true">
    </video>
    <template v-if="toggleplay || slotPassed">
      <div class="absolute top-0 bottom-48 w-full flex flex-col items-center justify-center">
        <template v-if="toggleplay && player">
          <template v-if="!onair">
            <div class="cursor-pointer bg-black rounded-full w-52 h-52 md:w-96 md:h-96 m-t-64"
                 @click="togglePause">
              <div class="relative flex flex-row w-full h-full items-center justify-center">
                <svg class="w-16 h-16 md:w-24 md:h-24 text-white">
                  <use xlink:href="#play"></use>
                </svg>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="w-full h-full" @click="togglePause"></div>
          </template>
        </template>
        <slot name="content"></slot>
      </div>
    </template>
  </div>
</template>

<script>

import videojs from 'video.js'
import youtube from 'videojs-youtube'

export default {
  name: 'VideoPlayer',
  props: {
    autoplay: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    fluid: {
      type: Boolean,
      default: true
    },
    toggleplay: {
      type: Boolean,
      default: false
    },
    sources: {
      type: Object,
      required: true
    },
    poster: {
      default: false
    },
    counter: {
      default: false
    }
  },
  data () {
    return {
      player: null,
      onair: false,
      options: {
        autoplay: this.autoplay,
        controls: this.controls,
        loop: this.loop,
        fluid: this.fluid,
        sources: this.sources,
      },
    }
  },
  mounted () {
    this.addPlugin()
    if (this.poster) {
      this.options.poster = this.poster
    }
    this.player = videojs(this.$refs.videoplayer, this.options)
    this.onair = this.options.autoplay
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  },
  computed: {
    slotPassed () {
      return !!this.$slots.content
    }
  },
  methods: {
    togglePause () {
      if (this.player.paused()) {
        this.player.play()
        this.onair = true
      } else {
        this.player.pause()
        this.onair = false
      }
    },
    addPlugin () {
      if (this.sources.type.indexOf('youtube') > 0) {
        this.options.techOrder = ['youtube']
        this.options.youtube = {
          'iv_load_policy': 3,
          'modestbranding': 1,
          'rel': 0,
        }
        return true
      }
      this.options.poster = this.sources.poster
    }
  }
}
</script>
