<template>
  <div class="inline-block relative z-50 w-full">
    <label id="listbox-label" class="block text-sm p-b-8">
      {{ label }}
    </label>
    <div class="relative" v-click-outside="close">
        <span class="inline-block border-1 border-black-200 rounded-4 w-full">
            <button type="button"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                    @click="isOpen = !isOpen"
                    class="p-y-16 w-full cursor-pointer">
                  <div class="flex items-center text-left p-x-16">
                        <span class="font-sans font-light text-base leading-none text-primary">
                            <template v-if="optioned">{{ optioned.label }}</template>
                            <template v-else>
                                {{ placeholder }}
                            </template>
                        </span>
                        <span class="absolute inset-y-0 right-12 flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="inline-block w-16 text-primary" fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M8 9l4-4 4 4m0 6l-4 4-4-4"/>
                            </svg>
                        </span>
                  </div>
            </button>
        </span>
      <transition name="fade">
        <div v-show="isOpen"
             class="absolute max-h-256 w-full m-t-2 overflow-auto bg-white shadow-md rounded-4 border-1 border-black-200">
          <ul tabindex="-1"
              aria-labelledby="listbox-label"
              role="listbox"
              class="p-y-8">
            <li v-for="el in data"
                class="cursor-pointer text-primary hover:bg-azure hover:text-white text-sm leading-none p-x-16 p-y-8"
                @click="select(el)">
              <span>{{ el.label }}</span>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'uiDropdown',
  props: {
    data: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: ' --- ',
      required: false
    },
    label: {
      type: String,
      required: false
    },
    selected: {
      type: String,
      required: false
    },
    action: {
      type: String,
      default: 'jumper',
      required: false
    }
  },
  data() {
    return {
      isOpen: false,
      optioned: '',
      loading: false
    }
  },
  mounted() {
    this.optioned = this.selected && this.data.hasOwnProperty(this.selected) ? this.data[this.selected] : ''
  },
  methods: {
    select(el) {
      this.isOpen = false
      if (el !== this.optioned) {
        this.optioned = el
        this.$emit('uiDropdownSelected', this.optioned)
        return typeof this[this.action] !== 'undefined' ? this[this.action]() : false
      }
    },
    close() {
      this.isOpen = false
    },
    jumper() {
      window.location.href = this.optioned.link;
    }
  }
}

</script>
