<template>
  <div @click="collapse()">
    <slot></slot>
  </div>
</template>

<script>

  import {eventsbus} from '../mixins/EventsBus.js'

  export default {

    name: 'collapsibleEmit',
    props: {
      refs: {
        default: null,
      },
    },
    data() {
      return {
        show: this.showed,
      }
    },
    methods: {
      collapse() {
        eventsbus.$emit('collapsible', this.refs)
      },
    },
  }

</script>
