<template>
  <div class="search" :class="{'search--visible': show}" role="search">
    <div class="search__inner">
      <label class="search__label" for="search__input">
        <svgicon name="search" class="icon icon--grey"></svgicon>
      </label>
      <input ref="searchInput" class="search__input" id="search__input" type="text" placeholder="Ricerca destinazioni, prodotti e articoli" autocomplete="off" autocapitalize="off" autocorrect="off" @keyup.enter="submit()" v-model="inputSearch"/>
      <button class="search__close" @click="close()">
        <svgicon name="xclose" class="icon"></svgicon>
      </button>
    </div>
  </div>
</template>

<script>

import {eventsbus} from '../mixins/EventsBus.js'
import slugify from 'slugify'

export default {

  inherit: true,
  name: 'SearchInput',
  props: {
    route: {
      required: true,
    },
  },
  data() {
    return {
      inputSearch: '',
      show: false,
    }
  },
  mounted() {
    eventsbus.$on('search-input-show', function() {
      this.show = true
      this.$refs.searchInput.focus()
    }.bind(this))
    eventsbus.$on('fs-overlay-clicked', function() {
      this.show = false
    }.bind(this))
  },
  methods: {
    close() {
      this.show = false
      eventsbus.$emit('fs-overlay', false)
    },
    submit() {
      return (this.inputSearch && this.inputSearch.length > 3)
        ? window.location.href = '/ricerca?q=' + slugify(this.inputSearch, '+')
        : null
    },
  },
}

</script>
