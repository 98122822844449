<template>
  <form>
    <ul class="chapter-list list-none">
      <li v-for="chapter in els" class="chapter-list__item">
        <div class="chapter-list__item__cell m-t-0">
          <input type="checkbox" :id="chapter.ean" :value="chapter" :name="chapter.ean" v-model="checked">
        </div>
        <div class="chapter-list__item__cell">
          <label :for="chapter.ean" class="chapter-list__item__name">{{ chapter.title }}</label>
          <a :href="chapter.route" class="button button--link button--link--flat"
            :title="chapter.title + ' - Capitolo PDF'" target="_blank" rel="noopener">Vedi capitolo</a>
        </div>
      </li>
    </ul>
    <div class="chapter-list__footer">
      <div class="product-details__variants__item__column">
      </div>
      <div class="product-details__variants__item__column">
        <div class="product-details__variants__item__price product-details__variants__item__price--regular">
          EUR {{ totalSum }}
        </div>
      </div>
      <div class="product-details__variants__item__column">
        <add-to-cart :els="checked" :label="button" :disabled="!(totalSum > 0)"></add-to-cart>
      </div>
    </div>
  </form>
</template>

<script>

import _ from 'lodash'

export default {

  name: 'chapterList',
  props: {
    els: {
      required: true,
    },
    button: {
      required: true,
    },
  },
  data() {
    return {
      checked: [],
      list: _.keyBy(this.els, 'ean'),
    }
  },
  computed: {
    totalSum: function () {
      return _.reduce(this.checked, function (sum, n) {
        return sum + parseFloat(n.price)
      }, 0).toFixed(2)
    },
  },
}

</script>
