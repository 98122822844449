import Vue from 'vue'
import vueScrollto from 'vue-scrollto'
import VueFilter from 'vue-filter'
import VueGtag from 'vue-gtag'
import VueRouter from 'vue-router'
import * as svgicon from 'vue-svgicon'
import './svg'
import './../../vendor/svg'

import 'leaflet/dist/leaflet.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import * as VeeValidate from 'vee-validate'
import ImageLazy from 'cube-vue-image-lazy'
import LazyLoad from 'vanilla-lazyload'
import Loading from 'vue-loading-overlay'
import Mixin from './mixin'
import './mixins/Directives'
import MixSVGIcon from 'laravel-mix-vue-svgicon/IconComponent.vue'
import UniqueId from 'vue-unique-id'
import VeeValidateIt from 'vee-validate/dist/locale/it'
import VModal from 'vue-js-modal'
import VueCookies from 'vue-cookies'
import VueForceNextTick from 'vue-force-next-tick'
import VueLazyBackgroundImage from 'vue-lazy-background-images/VueLazyBackgroundImage'
import VueSocialSharing from 'vue-social-sharing'
import VueWindowSize from 'vue-window-size'
import { Icon } from 'leaflet'
import Maska from 'maska'
import VueLazyload from 'vue-lazyload'
import VueCountdownTimer from 'vuejs-countdown-timer'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

// Components
import AddToCart from './components/AddToCart'
import ArticleList from './components/ArticleList'
import CardSlider from './components/CardSlider'
import TallSlider from './components/CardSlider'
import Cart from './components/Cart'
import ChaptersList from './components/ChaptersList'
import Collapsible from './components/Collapsible'
import CollapseWrapper from './components/CollapseWrapper'
import CollapsibleEmit from './components/CollapsibleEmit'
import CollapsibleTarget from './components/CollapsibleTarget'
import FSOverlay from './components/FullScreenOverlay'
import LeafletMap from './components/LeafletMap'
import LoginForm from './components/LoginForm'
import MastHead from './components/MastHead'
import MastheadsSlider from './components/MastHeadsSlider'
import DrawerCart from './components/DrawerCart'
import Placehead from './components/PlaceHead'
import ProductSlider from './components/ProductSlider'
import ReadMore from './components/ReadMore'
import RegistrationForm from './components/RegistrationForm'
import SearchBox from './components/SearchBox'
import SearchInput from './components/SearchInput'
import StickyNav from './components/StickyNav'
import TopChoices from './components/TopChoices'
import VueSelect from './components/Select'
import WordsList from './components/WordsList'
import VueChecklist from './components/CheckList'
import UserFlagsForm from './components/UserFlagsForm'
import NewsletterForm from './components/NewsletterForm'
import NewsletterSubscribe from './components/NewsletterSubscribe'
import GenericModalDialog from './components/GenericModalDialog'
import LpLoading from './components/LpLoading'
import EarthBar from './components/EarthBar'
import EarthTeaserSlider from './components/EarthTeaserSlider'
import HooperCarousel from './components/HooperCarousel'
import DeleteAccount from './components/DeleteAccount'
import UpdateEmailAccount from './components/UpdateEmailAccount'
import Gallery from './components/Gallery'
import LeafletDynamicMap from './components/LeafletDynamicMap'
import Only4U from './app/Only4U'
import LandingConsentDownload from './components/LandingConsentDownload'
import BestInTravel from './app/BestInTravel'

//Tailwind Components
import TailwindDropdown from './components/Tailwind/TailwindDropdown'

// Modules
import AddModalFunctionModule from './modules/AddModalFunctionModule'
import SwiperModule from './modules/PictureSwiperModule'
import LoadingModule from './modules/LoadingModule'
import PosterComponent from './components/PosterComponent'
import CampaniaCode from './app/CampaniaCode'
import NotifyWhenAvailable from './components/NotifyWhenAvailable'
// Video
import VueYoutube from 'vue-youtube'
import VideoPlayer from './components/VideoPlayer'
import CinemaPlayer from './components/CinemaPlayer'

// Utilies
import ScrollReveal from 'scrollreveal'
import ColorThief from 'colorthief'
window.ColorThief = ColorThief
// AdnKronos
import ADNKronos from './app/ADNKronos.vue'
import AllAroundSliderModule from './modules/CarouselModule.vue'
import CarouselModule from './modules/CarouselModule.vue'
import PlaceGalleryModule from './modules/PlaceGalleryModule.vue'
require('./bootstrap')

// VeeValidate.localize('it', VeeValidateIt)

Vue.config.debug = true
Vue.config.devtools = true
Vue.config.silent = false
if (process.env.NODE_ENV === 'production') {
  Vue.config.debug = false
  Vue.config.devtools = false
}

Vue.use(vueScrollto, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -100,
  cancelable: true,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(svgicon, { tagName: 'svgicon' })
Vue.use(VueFilter)
Vue.use(VeeValidate, {
  locale: 'it',
  dictionary: {
    it: { messages: VeeValidateIt, attributes: VeeValidateIt }
  }
})
Vue.use(VueSocialSharing)
Vue.use(VueCookies)
Vue.use(VueWindowSize)
Vue.use(VModal)
Vue.use(UniqueId)
Vue.use(Maska)
Vue.use(VueLazyload)
Vue.use(VueCountdownTimer)

Vue.use(VueGtag, {
  config: { id: process.env.GTAG_CONFIG },
  bootstrap: false
})

Vue.use(VueRouter)

Vue.mixin(Mixin)

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.use(VueForceNextTick)
Vue.use(ImageLazy, {
  delay: 500,
  loadingClass: 'display-block',
  loadedClass: 'filter-lazy animation-duration-500'
})

// import VueVideoPlayer from 'vue-video-player'
// Vue.use(videojs)
// Vue.use(youtubevideojs)
Vue.use(VueYoutube)

// VueComponents
Vue.component('add-to-cart', AddToCart)
Vue.component('article-list', ArticleList)
Vue.component('card-slider', CardSlider)
Vue.component('cart', Cart)
Vue.component('chapters-list', ChaptersList)
Vue.component('collapsible', Collapsible)
Vue.component('collapsible-emit', CollapsibleEmit)
Vue.component('collapsible-target', CollapsibleTarget)
Vue.component('fs-overlay', FSOverlay)
Vue.component('login-form', LoginForm)
Vue.component('leaflet-map', LeafletMap)
Vue.component('leaflet-dynamic-map', LeafletDynamicMap)
Vue.component('masthead', MastHead)
Vue.component('user-flags-form', UserFlagsForm)
Vue.component('mastheads-slider', MastheadsSlider)
Vue.component('drawer-cart', DrawerCart)
Vue.component('placehead', Placehead)
Vue.component('product-slider', ProductSlider)
Vue.component('read-more', ReadMore)
Vue.component('registration-form', RegistrationForm)
Vue.component('search-box', SearchBox)
Vue.component('search-input', SearchInput)
Vue.component('sticky-nav', StickyNav)
Vue.component('tall-slider', TallSlider)
Vue.component('top-choices', TopChoices)
Vue.component('vue-select', VueSelect)
Vue.component('words-list', WordsList)
Vue.component('vue-checklist', VueChecklist)
Vue.component('nl-form', NewsletterForm)
Vue.component('nwsl-subscribe', NewsletterSubscribe)
Vue.component('loading', Loading)
Vue.component('lp-loading', LpLoading)
Vue.component('earth-bar', EarthBar)
Vue.component('earth-teaser-slider', EarthTeaserSlider)
Vue.component('generic-modal-dialog', GenericModalDialog)
Vue.component('hooper-carousel', HooperCarousel)
Vue.component('delete-account', DeleteAccount)
Vue.component('update-email-account', UpdateEmailAccount)
Vue.component('gallery', Gallery)
Vue.component('mix-svgicon', MixSVGIcon)
Vue.component('lazy-background', VueLazyBackgroundImage)
Vue.component('vue-video-player', VideoPlayer)
Vue.component('cinema-player', CinemaPlayer)
Vue.component('collapse-wrapper', CollapseWrapper)
Vue.component('only4u', Only4U)
Vue.component('poster-component', PosterComponent)
Vue.component('campania-code', CampaniaCode)
Vue.component('notify-when-available', NotifyWhenAvailable)
Vue.component('landing-consent-download', LandingConsentDownload)
Vue.component('adn-kronos', ADNKronos)
Vue.component('best-in-travel', BestInTravel)

// Elements
const files = require.context('./elements', true, /\.vue$/i)
files.keys().map(key =>
  Vue.component(
    key
      .split('/')
      .pop()
      .split('.')[0],
    files(key).default
  )
)

// Modules
Vue.component('add-modal-function-module', AddModalFunctionModule)
Vue.component('swiper-module', SwiperModule)
Vue.component('loading-module', LoadingModule)
Vue.component('carousel-module', CarouselModule)
Vue.component('place-gallery-module', PlaceGalleryModule)
//TailwindComponents
Vue.component('tw-dropdown', TailwindDropdown)

// Ignoring custom tags
Vue.config.ignoredElements = ['page', 'category', 'place']

const app = new Vue({
  el: '#app',
  data: {
    accordion: '',
    lazyLoadInstance: false
  },
  created () {
    let el = document.getElementById('app')
    _.each(el.getElementsByTagName('script'), function (tag) {
      tag.setAttribute('type', 'application/javascript')
    })
  },
  mounted () {
    this.lazyLoadInstance = new LazyLoad({
      elements_selector: '.lazy',
      class_loaded: 'lazy__loaded',
      class_loading: 'lazy__loading',
      load_delay: 0
    })
  },
  methods: {
  }
})

$(document).ready(function ($) {
  'use strict'

  $(this).dropdownToggle()
  $(this).headerNav()
  $(this).mobileNav()
  $(this).popout()
  $(this).smoothScroll()
  $(this).topChoices()
  $(this).fixedNavBar()
  $(this).showMore()
})

ScrollReveal({
  reset: false,
  delay: 300,
  distance: '50px',
  viewFactor: 0.3,
  duration: 1000
})
window.ScrollReveal = ScrollReveal
