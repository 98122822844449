/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faux-left-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 476.213 476.213',
    data: '<path pid="0" d="M476.213 223.107H57.427l94.393-94.394-21.213-21.213L0 238.106l130.607 130.608L151.82 347.5l-94.393-94.393h418.786z"/>'
  }
})
