<template>
    <form class="" ref="settingsForm" accept-charset="UTF-8" method="post" @submit.prevent="frmSubmit">

        <div style="max-width: 60%; text-align: left; margin: auto;">
            <vue-checklist :prop_checklist= "checklist"></vue-checklist>
        </div>


        <div class="property property--full property--footer">
            <button class="button button--next">
                Salva
            </button>
            <loading :active.sync="this.isLoading"
                     :can-cancel="this.canCancel"
                     :is-full-page="this.isFullPage"
                     color="#297cbb"></loading>
        </div>
    </form>
</template>

<script>

  export default {

        inherit: true,
        name: 'UserFlagsForm',
        props: {
            prop_checklist: {
              required: true
            },
            prop_callback: {
                type: String,
                required: true
            }
        }
    ,
    data () {
        return {
            checklist: this.prop_checklist,
            callback: this.prop_callback,
            isLoading: false,
            isFullPage: false,
            canCancel: false
            // frmData: []
        }
    },
    methods: {
      frmSubmit: function (e) {

          this.isLoading = true


              axios.post(this.callback,  { flags: this.checklist }).then((response) => {
                      // console.log(response.data)
                  })
                  .catch((error) => {
                      console.log(error)
                  })
                  .finally( ()=>{
                      this.isLoading = false
                  })

          return false;

      },
    },
  }

</script>
