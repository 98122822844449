/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paypal': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M512 256c0 135.158-104.741 245.844-237.474 255.342-6.123.439-12.298.658-18.526.658-30.093 0-58.964-5.193-85.786-14.733-37.397-13.291-70.802-35.046-97.896-62.955C27.564 388.221 0 325.329 0 256 0 114.615 114.615 0 256 0s256 114.615 256 256z" _fill="#f0efeb"/><path pid="1" d="M430.028 166.285c-11.964 79.705-30.553 150.11-138.156 166.045-21.703 3.218-48.567 3.354-77.625 1.515-11.703-.731-21.985 7.732-23.625 19.341l-20.407 144.081c-37.397-13.291-70.802-35.046-97.896-62.955l51.869-366.289c1.515-10.689 10.668-18.631 21.462-18.631h175.449c50.477 0 120.882 37.199 108.929 116.893z" _fill="#002c8d"/><path pid="2" d="M446.558 352.925c-33.876 64.428-106.935 67.615-124.197 67.615h-18.16c-9.686 0-17.889 7.137-19.237 16.718l-10.439 74.083c-6.122.44-12.297.659-18.525.659-30.093 0-58.964-5.193-85.786-14.733l44.387-313.448c1.317-9.247 9.226-16.123 18.568-16.123h151.834c27.449 0 62.882 15.872 74.177 41.106 11.285 25.234 21.254 79.705-12.622 144.123z" _fill="#008ee7"/><path pid="3" d="M428.07 178.735c-12.194 74.48-34.232 138.501-136.19 153.604-21.718 3.208-48.578 3.348-77.63 1.514-11.715-.738-21.997 7.711-23.631 19.327l23.97-169.365c1.316-9.245 9.245-16.12 18.57-16.12H385.01c13.69.001 29.37 3.946 43.06 11.04z" _fill="#142171"/>'
  }
})
