<template>
  <half-circle-spinner
    :animation-duration="duration"
    :size="size"
    :color="color"
  />
</template>
<script>

  import { HalfCircleSpinner } from 'epic-spinners'

  export default {
    name: 'LpLoading',
    components: {
      HalfCircleSpinner
    },
    props: {
      'duration': {
        type: Number,
        default: 1000
      },
      'size': {
        type: Number,
        default: 40
      },
      'color': {
        type: String,
        default: '#297cbb'
      },
    },
    data () {
      return {}
    },

  }

</script>
