/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flipboard': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M10.6 0H0v32h10.6z"/><path pid="1" opacity=".5" d="M10.6 10.6h10.6v10.6H10.6V10.6z"/><path pid="2" opacity=".8" d="M21.4 0H10.6v10.6H32V0z"/>'
  }
})
