/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brackets': {
    width: 16,
    height: 16,
    viewBox: '0 0 254.1 256.7',
    data: '<path pid="0" class="st0" _fill="#231F20" d="M72.9 47L20 111.4l52.9 64.3 12.5-20.5-35.6-43.8 35.6-43.8zM106 174.2h23.2l36.9-126h-23.2zM181.2 47l-12.5 20.6 35.6 43.8-35.6 43.8 12.5 20.5 52.9-64.3z"/>'
  }
})
