/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'brain': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M353.43 139.813c-12.583-13.986-30.004-21.688-49.056-21.688-25.539 0-42.231 10.548-52.5 20.997-10.269-10.449-26.961-20.997-52.5-20.997-19.052 0-36.474 7.702-49.056 21.688-11.894 13.22-18.444 30.862-18.444 49.676 0 50.855 49.308 82.8 110.238 135.025l9.762 8.368 9.762-8.368c60.929-52.223 110.238-84.185 110.238-135.025 0-18.814-6.55-36.456-18.444-49.676zM251.874 293.432c-54.878-46.358-90-71.423-90-103.943 0-23.581 16.122-41.364 37.5-41.364 27.874 0 37.391 19.723 38.574 22.454l13.42 36.502 14.455-36.567c.088-.224 9.19-22.389 38.55-22.389 21.378 0 37.5 17.783 37.5 41.364.001 32.539-35.11 57.576-89.999 103.943z"/><path pid="1" d="M434.166 243.229l3.018-6.036C495.359 120.845 384.377.896 253.749.165V0c-115.794 0-210 94.206-210 210 0 57.053 22.771 109.002 60 146.883V512h210v-62.461c14.625 2.704 30.445 4.315 47.542 4.315a47.546 47.546 0 0045.162-32.55l24.053-72.16 37.745-37.745-34.085-68.17zm-29.881 89.71l-26.292 78.878a17.582 17.582 0 01-16.701 12.037c-73.378 0-119.843-32.581-147.605-52.047-7.032-4.931-12.587-8.826-17.524-11.429l-13.992 26.537c3.259 1.719 8.373 5.304 14.293 9.456 18.438 12.929 47.117 33.012 87.287 45.695V482h-150V344.209c-1.929-2.723-60-47.836-60-134.209 0-90.533 67.187-165.656 154.315-178.16l.002.016a177.471 177.471 0 0123.683-1.721c108.577-.644 206.943 96.96 158.602 193.642l-9.726 19.452 31.123 62.247-27.465 27.463z"/>'
  }
})
