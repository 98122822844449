/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-prev': {
    width: 512,
    height: 512,
    viewBox: '0 0 451.111 451.111',
    data: '<path pid="0" d="M451.111 193.333H128.889L241.667 80.556l-48.333-48.333L0 225.556l193.333 193.333 48.333-48.333-112.777-112.778h322.222z"/>'
  }
})
