/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'urban': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M484.332 482V145h-96V.898L207 111.898v-.777l-53.332-53.336V0h-30v57.785l-53.336 53.336v22.285l-42.664 53.332V482H0v30h512v-30zm-138.664 0v-53.168h108.664V482zm-288 0V197.262l42.664-53.332v-20.383l38.336-38.332L177 123.547v20.383l42.668 53.332V482zM214.34 142.578l143.992-88.14V145h-42.664v337h-66V186.738zm239.992 256.254H345.668V343.5h108.664zm0-85.332H345.668v-55.332h108.664zm0-85.332H345.668V175h108.664zm0 0"/><path pid="1" d="M91.668 213.332h30V256h-30zm0 0M155.668 213.332h30V256h-30zm0 0M91.668 298.668h30v42.668h-30zm0 0M155.668 298.668h30v42.668h-30zm0 0M91.668 384h30v42.668h-30zm0 0M155.668 384h30v42.668h-30zm0 0"/>'
  }
})
