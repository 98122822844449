<template>
  <article class="placehead" :class="{'placehead--no-picture': !el.picture}">
    <div class="placehead__item">
      <div class="placehead__image-container" v-if="el.picture">
        <div class="gradient-overlay"></div>
        <picture class="placehead__image" :style="{ backgroundImage: 'url(' + el.picture + ')' }"></picture>
      </div>
      <div class="placehead__container">
        <div class="placehead__claim">
          <div class="placehead__claim__eyebrow" v-for="item in correctParent" v-if="correctParent.length > 0">
            <a :href="item.route">{{ item.name }}</a>
          </div>
          <h1 class="placehead__claim__title">
            {{ el.name }}
          </h1>
          <h3 class="placehead__claim__strapline">

          </h3>
          <div class="placehead__claim__callout-link" v-if="tlist && (el.type != 6)">
            <a class="button button--solid button--more" @click="showList()">
              <span>Luoghi</span>
              <svgicon name="chevron-down" class="icon icon--white"></svgicon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>

  import {eventsbus} from '../mixins/EventsBus.js'

  export default {

    inherit: true,
    name: 'PlaceHead',
    props: ['el'],

    data() {
      return {
        tlist: false,
      }
    },

    mounted() {
      eventsbus.$on('tlist-available', function() {
        this.tlist = true
      }.bind(this))
    },

    computed: {
      correctParent: function() {
        return Object.values(this.el.parents).reverse().filter(function(p) {
          return p.type != 4 && p.type < 6
        }).slice(0, 1)
      },
    },

    methods: {
      showList() {
        eventsbus.$emit('tlist-show', true)
      },
    },

  }

</script>
