<template>
  <div>
    <template v-if="!message">
      <div class="property property--full display-flex flex-row">
        <div class="property__value margin-right-8">
          <input type="text"
                 class="property__text-box"
                 :class="{'border-red': emailError}"
                 placeholder="Email"
                 name="email"
                 id="email"
                 aria-required="true"
                 aria-invalid="false"
                 v-model="email"
                 @keyup.enter="confirm">
        </div>
        <button class="button button--solid button--extra-small" @click="confirm">Conferma</button>
      </div>
      <div class="property">
        <div class="property__checkbox">
          <label for="AGREE_PRIVACY" class="property__checkbox__label" style="background-color:transparent; line-height:1.2">
            <input v-model="agreement" type="checkbox" id="AGREE_PRIVACY" class="property__checkbox__input">
            <span class="property__checkbox__box" :class="{ 'border-red' : agreementError}"></span>
            <span class="text-xs">Autorizzo l’utilizzo dei miei dati per questo servizio</span>
          </label>
        </div>
      </div>
    </template>
    <template v-else>
      <span class="text-green font-bold">Indirizzo email inserito con successo</span>
    </template>
  </div>
</template>

<script>

export default {
  name: 'NotifyWhenAvailable',
  props: {
    productId: {
      required: true,
    },
    link: {
      required: true,
    },
    cover: {
      required: true
    }
  },
  data () {
    return {
      agreement: false,
      agreementError: false,
      email: null,
      emailError: false,
      message: null,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    }
  },
  methods: {
    confirm: function () {
      this.agreementError = !this.agreement
      if (this.email === '' || this.email == null || (!this.reg.test(this.email))) {
        this.emailError = true
      }
      if (this.agreementError || this.emailError) {
        return false
      }
      this.emailError = this.agreementError = false
      return this.store()
    },

    store: function () {
      let _this = this,
          payload = {
            product: _this.productId,
            email: _this.email,
            link: _this.link,
            cover: _this.cover
          },
          options = {
            method: 'POST',
            url: process.env.SHOP_URL + '/api/cart/notify',
            data: { payload: payload },
          }
      axios(options).then((res) => {
        if (res.data && res.data.status === 'success') {
          this.message = '<span class="text-green font-bold">Indirizzo email inserito con successo</span>'
        } else {
          this.message = '<span class="text-red font-bold">Errore nella procedura, riprova più tardi</span>'
        }
      })
    },
  }
}
</script>
