/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-down': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28.8 6.2L16 19 3.2 6.2 0 9.4l16 16.4L32 9.4z"/>'
  }
})
