/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hamburger': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M30 16.2c0 .8-.6 1.4-1.4 1.4H3.3c-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4h25.3c.8 0 1.4.6 1.4 1.4zM3.3 9.1h25.3c.8 0 1.4-.6 1.4-1.4s-.6-1.4-1.4-1.4H3.3c-.8 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4zm25.3 14.1H3.3c-.8 0-1.4.6-1.4 1.4S2.5 26 3.3 26h25.3c.8 0 1.4-.6 1.4-1.4s-.6-1.4-1.4-1.4z"/>'
  }
})
