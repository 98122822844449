<template>
    <form class="" ref="login" accept-charset="UTF-8" method="post" @submit.prevent="onSubmit" :action="route">
        <input type="hidden" name="_token" :value="token"/>
        <div class="property property--full">
            <label class="property__name" for="email">Email</label>
            <div class="property__value">
                <input type="text" class="property__text-box" data-vv-validate-on="change" v-validate="'required|email'" name="email"
                       v-model="email"/>
            </div>
            <div class="property__error" v-show="errors.has('email')">{{ errors.first('email') }}</div>
        </div>
        <div class="property property--full">
            <label class="property__name">Password</label>
            <div class="property__value">
                <input type="password" class="property__text-box" data-vv-validate-on="change" v-validate="'required'" name="password"
                       v-model="password"/>
            </div>
            <div class="property__error" v-show="errors.has('password')">{{ errors.first('password') }}</div>
            <div class="property__info text-right">
                <a :href="url" target="_blank">Hai dimenticato la password?</a>
            </div>
        </div>
        <div class="property property--full text-center">
            <div class="property__error" v-if="logs">{{ logs }}</div>
        </div>
        <div class="property property--full property--footer text-center">
            <button class="button button--next">
                Accedi
            </button>
        </div>
    </form>
</template>

<script>

  export default {

    inherit: true,
    name: 'LoginForm',
    props: {
      route: '',
      token: '',
      logs: '',
    },
    data () {
      return {
        password: '',
        email: '',
        url: process.env.CHECKOUT_URL + '/password/reset'
      }
    },

    methods: {
      onSubmit: function (e) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$refs.login.submit()
          }
        })
      },
    },
  }

</script>
