<template>
  <section class="top-choices" :class="{'top-choices--visible': show}">
    <button class="top-choices__close" @click="close()">
      <svgicon name="xclose" class="icon"></svgicon>
    </button>
    <div class="top-choices__container">
      <div class="top-choices__wrap">
        <div class="top-choices__column" v-for="(block, group) in availableOffspring.slice(0,2)">
          <div class="top-choices__list">
            <h2 class="top-choices__list__heading">{{ titles[group] }}</h2>
            <div class="top-choices__list__content">
              <ul class="top-choices__list__primary" v-if="Object.keys(block).length !== 0">
                <li v-for="(item, index) in block" v-if="index < 3" class="top-choices__list__primary__item">
                  <a class="top-choices__list__primary__item__link" :href="item.route">
                    <figure class="top-choices__list__image" :style="{ 'background-image': 'url(' + item.picture + ')' }"></figure>
                    <div class="top-choices__list__marker">{{ index+1 }}</div>
                    <div class="top-choices__list__text">
                      <div class="top-choices__list__title">{{ item.name }}</div>
                      <div class="top-choices__list__subtitle" v-if="item.type > 3 && item.parent.id">
                        {{ item.parent.name }}
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
              <ul v-if="Object.keys(block).length !== 0" class="top-choices__list__secondary">
                <li v-for="(item, index) in block" v-if="index < 10" class="top-choices__list__secondary__item">
                  <a class="top-choices__list__secondary__item__link" :href="item.route">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
            <a class="top-choices__list__more button button--link button--link--flat button--next" :href="route + '?filter=' + slugify(titles[group])">
              Vedi tutt{{ (keys[group]==4 || keys[group]==5) ? 'e le ' : 'i i ' }} {{ titles[group] }}
              <svgicon name="chevron-right" class="icon"></svgicon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  import {eventsbus} from '../mixins/EventsBus.js'
  import functions from '../mixins/Functions.js'

  export default {

    inherit: true,
    name: 'TopChoices',
    props: ['els', 'regional', 'route'],
    mixins: [functions],
    data() {
      return {
        labels: {
          1: 'Continenti',
          2: 'Subcontinenti',
          3: 'Paesi',
          4: 'Regioni',
          5: 'Città',
          6: 'Quartieri',
          7: 'Territori'
        },
        show: false,
        titles: [],
        keys: [],
      }
    },

    mounted() {
      this.availableOffspring.length ? eventsbus.$emit('tlist-available', true) : null
      eventsbus.$on('tlist-show', function() {
        this.show = true
        eventsbus.$emit('fs-overlay', true)
      }.bind(this))
      eventsbus.$on('fs-overlay-clicked', function() {
        this.show = false
      }.bind(this))
    },

    computed: {
      availableOffspring: function() {
        let _this = this
        let _mykeys = Object.keys(_this.els)
        let _titles = _this.titles
        let _labels = _this.labels
        let _regional = _this.regional
        return Object.values(_this.els).filter(function(e, i) {
          if (!_regional) {
            if (_mykeys[i] == 3 || _mykeys[i] == 5 || _mykeys[i] == 7) {
              _titles.push(_labels[_mykeys[i]])
              _this.keys.push(_mykeys[i])
              return e
            }
          } else {
            if (_mykeys[i] > 2 && _mykeys[i] < 6) {
              _titles.push(_labels[_mykeys[i]])
              _this.keys.push(_mykeys[i])
              return e
            }
          }
        })
      },
    },

    methods: {
      close() {
        this.show = false
        eventsbus.$emit('fs-overlay', false)
      },
    },

  }

</script>
