/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faux-right-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 476.213 476.213',
    data: '<path pid="0" d="M345.606 107.5l-21.212 21.213 94.393 94.394H0v30h418.787L324.394 347.5l21.212 21.213 130.607-130.607z"/>'
  }
})
