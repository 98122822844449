/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faux-checked': {
    width: 16,
    height: 16,
    viewBox: '0 0 434.107 434.107',
    data: '<path pid="0" d="M412.895 67.303l-257.08 257.075L21.213 189.777 0 210.99l155.815 155.814L434.107 88.516z"/>'
  }
})
