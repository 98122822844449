/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'magazine': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M106 0C72.916 0 46 26.916 46 60v392c0 33.084 26.916 60 60 60h330v-60h30V30H157.928C147.537 12.084 128.159 0 106 0zm0 30c16.542 0 30 13.458 30 30v340.06a59.895 59.895 0 0 0-30-8.06 59.608 59.608 0 0 0-30 8.072V60c0-16.542 13.458-30 30-30zm300 452H106c-16.542 0-30-13.458-30-30s13.458-30 30-30c12.697 0 24.068 8.037 28.297 19.999L137.832 452H406zm15-60H166V60h270v362z"/><path pid="1" d="M196 90h210v30H196zM196 270h210v30H196zM196 330h210v30H196zM286 150h-90v90h90zm-30 60h-30v-30h30zM316 150h90v30h-90zM316 210h90v30h-90z"/>'
  }
})
