<template>
  <div v-if="show" class="fs-overlay" :class="{'fs-overlay--visible': show}" @click="hide"></div>
</template>

<script>

  import {eventsbus} from '../mixins/EventsBus.js'

  export default {

    inherit: true,
    name: 'FSOverlay',
    data() {
      return {
        show: false,
      }
    },
    mounted() {
      eventsbus.$on('fs-overlay', function(e) {
        this.show = e
      }.bind(this))

    },
    methods: {
      hide () {
        eventsbus.$emit('fs-overlay', false)
        eventsbus.$emit('fs-overlay-clicked', true)
      }
    }

  }

</script>
