/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-next': {
    width: 512,
    height: 512,
    viewBox: '0 0 451.111 451.111',
    data: '<path pid="0" d="M257.778 32.222l-48.333 48.333 112.778 112.778H0v64.444h322.222L209.445 370.556l48.333 48.333 193.333-193.333z"/>'
  }
})
