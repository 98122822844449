/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'india': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M493.893 267.327v-28.4c3.946-7.793 10.137-24.344 4.338-43.652-5.467-18.208-19.905-33.228-42.956-44.743v-33.028h-29.749v33.028c-23.05 11.515-37.487 26.535-42.956 44.743-5.857 19.505.521 36.197 4.459 43.888v28.164h-14.18v-38.095h-27.022v-27.986c6.135-11.09 20.654-42.928 9.567-79.851-10.339-34.43-39.48-62.833-86.666-84.524V2.144h-29.749v34.728c-47.185 21.691-76.326 50.094-86.665 84.524-11.068 36.86 3.383 68.655 9.536 79.796v28.04h-26.992v38.095h-14.06v-28.402c3.946-7.794 10.136-24.344 4.338-43.651-5.468-18.208-19.905-33.228-42.956-44.743v-33.028H52.436v33.028c-23.05 11.516-37.488 26.535-42.956 44.743-5.857 19.504.52 36.196 4.459 43.888v28.165H0v242.529h512V267.327h-18.107zM37.972 203.83c3.081-10.26 13.178-19.686 29.338-27.465 16.01 7.706 26.075 17.034 29.251 27.179 1.989 6.355 1.073 12.426-.279 16.815H38.318c-1.322-4.3-2.23-10.257-.346-16.529zm53.081 46.278v17.219H43.688v-17.219h47.365zm43.809 229.999H29.749V297.076h105.113v183.031zm45.946-350.156c7.945-26.456 32.507-49.034 73.048-67.167 40.363 18.054 64.89 40.517 72.945 66.827 6.809 22.24-.056 42.587-4.697 52.652H185.612c-4.616-10.021-11.434-30.23-4.804-52.312zm135.272 82.062v17.219H191.601v-17.219H316.08zm-21.958 268.095H213.59V380.09c0-7.529 3.062-14.649 8.4-19.533l31.866-29.155 31.865 29.155c5.339 4.884 8.401 12.004 8.401 19.533v100.018zm48.981-.001H323.87V380.09c0-15.856-6.585-30.976-18.068-41.482l-51.946-47.528-51.948 47.528c-11.482 10.506-18.067 25.625-18.067 41.481v100.018H164.61V258.981h178.493v221.126zm67.961-276.277c3.08-10.26 13.177-19.686 29.337-27.465 16.01 7.706 26.076 17.034 29.251 27.18 1.989 6.354 1.073 12.426-.28 16.814H411.41c-1.322-4.3-2.23-10.257-.346-16.529zm53.081 46.278v17.219H416.78v-17.219h47.365zm18.106 229.999H377.139V297.076h105.112v183.031z"/><path pid="1" d="M67.43 327.4h29.749v44.33H67.43zM67.43 404.79h29.749v44.33H67.43zM414.82 327.4h29.749v44.33H414.82zM414.82 404.79h29.749v44.33H414.82z"/>'
  }
})
