<template>
  <div class="display-block width-full height-full position-relative">
    <template v-if="el.type==='video'">
      <div class="display-flex flex-col justify-center items width-full height-full bg-black">
        <youtube :video-id="el.source" ref="youtube" :fitParent="true"></youtube>
      </div>
    </template>
    <template v-else>
      <template v-if="onlyphoto">
        <lazy-background
            :image-source="el.picture"
            image-class="grid-row-1 desktop:grid-col-2 position-relative height-full"
            background-size="cover"
            loading-image=""
            error-image=""
            style="background-position: center center"
            :class="lazyclass"
            :image-success-callback="successCallback">
        </lazy-background>
        <div class="position-absolute inset-bottom-16 inset-left-20">
          <span class="text-white text-12 text-shadow-sm">{{ [el.caption,el.credits].join(' ') }}</span>
        </div>
      </template>
      <template v-else>
        <div class="display-grid desktop-max:grid-rows-2 desktop:grid-template-cols-4/12-left height-full width-full overflow-hidden">
          <div class="overflow-y-scroll">
            <div class="display-flex flex-col height-full padding-20 desktop:padding-40">
              <div class="margin-bottom-8 desktop:margin-bottom-20 margin-top-0">
                <div class="display-flex flex-row items-baseline text-12">
                  <span class="font-bold text-16 tablet:text-32">{{ current }}</span>
                  <span class="margin-x-6">/</span>
                  <span class="text-12 tablet:text-16">{{ total }}</span>
                </div>
                <template v-if="el.eyebrow">
                  <span class="tablet-max:display-hidden text-tertiary leading-loose font-bold text-12 uppercase margin-bottom-20">{{ el.eyebrow }}</span>
                </template>
              </div>
              <div class="display-flex flex-1 flex-col tablet:justify-center phone-max:items-start">
                <template v-if="el.title">
                  <h2 class="font-bold text-20 tablet:text-24 tracking-wide phone-max:margin-top-20 leading-tight">{{ el.title }}</h2>
                </template>
                <div class="phone-max:leading-snug tablet:leading-relaxed text-14 tablet:text-16 tracking-wide">{{ el.content }}</div>
                <template v-if="el.credits">
                  <span class="padding-top-16 text-tertiary text-12">{{ el.credits }}</span>
                </template>
              </div>
            </div>
          </div>
          <lazy-background
              :image-source="el.picture"
              image-class="grid-row-1 desktop:grid-col-2 position-relative"
              background-size="cover"
              loading-image=""
              error-image=""
              style="background-position: center center"
              :class="lazyclass"
              :image-success-callback="successCallback">
          </lazy-background>
        </div>
      </template>
    </template>
  </div>
</template>

<script>

export default {
  name: 'GallerySlideTemplate',
  props: {
    el: { required: true, },
    current: { required: true, },
    total: { required: true, },
    onlyphoto: {
      default: false
    }
  },
  data () {
    return {
      lazyclass: '',
    }
  },
  methods: {
    successCallback: function () {
      this.lazyclass = 'filter-lazy animation-duration-500'
    }
  }
}

</script>
