/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32 30.2l-8.4-8.4c2-2.4 3-5.3 3-8.4C26.6 6 20.6 0 13.3 0S0 6 0 13.3s6 13.3 13.3 13.3c3.1 0 6.1-1.1 8.4-3l8.4 8.4 1.9-1.8zM2.6 13.3c0-5.9 4.8-10.7 10.7-10.7S24 7.4 24 13.3c0 2.8-1.1 5.5-3 7.5-2 2.1-4.8 3.2-7.7 3.2-5.9 0-10.7-4.8-10.7-10.7z"/>'
  }
})
