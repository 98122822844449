<template>
  <transition :duration="{ enter: 1000, leave: 2000 }">
    <div v-if="loading">
      <div class="display-flex flew-row items-center justify-center width-full min-height-320">
        <mix-svgicon icon="ui/aereo-spinner" class="animate-spin display-inline-block width-96 fill-current text-azure"></mix-svgicon>
      </div>
    </div>
    <div v-else>
      <slot name="PageContent"></slot>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'LoadingModule',
  props: ['loading'],
  data () {
    return {}
  },
  methods: {
  }
}

</script>
