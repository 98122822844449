<template>
  <nav class="subnav">
    <div class="subnav__shadow">
      <div class="subnav__container">
        <ul class="subnav__list">
          <li v-for="(el, index) in els" class="subnav__list__item" :class="{'is-active': isActive===el.attributes.anchor}">
            <a v-scroll-to="el.attributes.anchor" @click="active(el.attributes.anchor)">{{ el.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

  export default {

    name: 'StickyNav',
    props: ['els'],

    data () {
      return {
        isActive: ''
      }
    },

    directives: {
    },

    mounted () {
    },

    methods: {
      active(anchor) {
        this.isActive = anchor
      }
    },

  }

</script>
