/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 16,
    height: 16,
    viewBox: '0 0 44.7 44.8',
    data: '<path pid="0" d="M23.7 1.1H21V21H1v2.7h20v19.9h2.7V23.8h19.9v-2.7H23.7v-20z"/>'
  }
})
