<template>
  <div class="list list--words">

    <div class="list__item" v-for="word in words" v-on:click="playSound(word.en)">
      <div class="word-image-container">
        <img class="word-image" :src="imageSrc(word.en)" alt=""/>
      </div>
      <div class="word-it">{{ word.it }}</div>
      <div class="word-en">{{ word.en }}</div>
      <div class="word-speak">{{ word.speak }}</div>
    </div>

    <audio ref="audio"></audio>
  </div>
</template>

<script>
    import words from '../data/prime_parole/words.json'

    export default {

        inherit: true,
        name: 'WordsList',
        data() {
            return {
                words,
            }
        },
        methods: {
            playSound(el) {
                let path = '/audio/prime_parole/'
                let filename = el.replace(" ", "_").toLowerCase()
                this.$refs.audio.src = path + 'English_' + filename + '.mp4'
                this.$refs.audio.play()
            },
            imageSrc(el) {
                let path = '/images/prime_parole/'
                let filename = el.replace(" ", "_").toLowerCase();
                return path + filename + '.png'
            },
        },
    }

</script>
