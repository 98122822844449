/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-share-telegram': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" _fill="#08C" d="M454.1 60.7c22.6-5.5 33.9-2.1 33.9 10.1L413.7 445c-12.2 15-25.4 19.6-39.4 13.8L269.8 379l-66 65.1c-4.9 2.8-9.2 4-12.8 3.7h-1.8c-1.5-.6-3.2-2.3-5-5s-4.1-8.7-6.9-17.9c-1.2-4.3-3.5-12.1-6.9-23.4-3.1-11.3-6.6-23.6-10.5-36.9-4-13.3-7.8-26.4-11.5-39.2-3.7-12.5-6.6-22.2-8.7-28.9L36 261.5c-10.7-5.8-16.5-11.9-17.4-18.3-.9-6.4 5.2-12.8 18.3-19.3L454.1 60.7zm-46.7 76.1c0-4.6-4.6-4.6-13.8 0L147.8 300c2.4 7 5.2 15.9 8.3 26.6 3.1 10.7 6.2 21.6 9.4 32.8 3.2 11.2 6.3 21.8 9.4 31.9 2.8 10.4 5 18.5 6.9 24.3 1.8 3.1 4.2 4.5 7.1 4.4 2.9-.2 4.5-2.2 4.8-6.2l10.1-86.2c4.3-4.3 16.7-15.7 37.1-34.4 20.5-19 42.3-39 65.6-60.3 23.2-21.2 44.5-40.6 63.7-58 19.3-17.7 29.8-27.4 31.6-28.9 2.8-2.8 4.7-5.8 5.6-9.2z"/>'
  }
})
