<template>
  <div class="inline-block w-384 relative"
       v-click-outside="close">
    <form class="w-full flex flex-row justify-start items-center text-left rounded-full bg-white">
      <div class="p-x-32 p-y-4">
        <input type="text"
               v-model="searchinput"
               placeholder="Ricerca la tua guida"
               class="text-lg font-normal p-y-12 leading-relaxed border-0 outline-none shadow-none"
               @input="filter"
               @click="show=true" />
      </div>
      <button class="flex-none relative text-black m-r-24" @click.prevent="get()">
        <svg class="w-24 h-24 fill-current cursor-pointer">
          <use xlink:href="#lens" class="w-24 h-24"></use>
        </svg>
      </button>
    </form>
    <template v-if="show">
      <ul class="list-none text-left absolute m-t-6 max-h-320 w-full bg-white rounded-8 shadow-md overflow-y-auto" ref="scrollable">
        <li v-for="(item, $index) in filtered"
            :id="item.ean"
            :key="$index"
            class="p-y-8 p-x-24 cursor-pointer first-child:p-t-12 last-child:p-b-12"
            :class="{ 'bg-black-100': currentActive($index) }"
            @click="link"
            @mouseover="currentIndex=$index">
          <span class="font-semibold">{{ item.title }}</span>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>

export default {
  name: "HealthSearch",
  data() {
    return {
      searchinput: '',
      items: [],
      filtered: [],
      show: false,
      currentIndex: 0
    };
  },
  beforeMount () {
    window.addEventListener('keydown', this.handleKeyDown, null);
  },
  mounted() {
    this.get()
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e) {
      if (!this.filtered.length || !this.show) return;
      switch (e.keyCode) {
        case 38:
          this.arrowUp();
          break;
        case 40:
          this.arrowDown();
          break;
        case 13:
          this.link();
          break;
      }
    },
    close: function() {
      this.show = false
    },
    get: function() {
      axios.get(process.env.WWW_URL + '/api/health/guides')
        .then(response => {
          if (response.status === 200) {
            this.items = this.filtered = response.data.items
          }
        }).catch((error) => {
        console.log(error)
      })
    },
    link: function() {
      window.location.href = process.env.WWW_URL + '/salute/' + this.filtered[this.currentIndex].slug
    },
    filter: _.debounce(function() {
      let _this = this
      this.filtered = this.items.filter(function (item) {
        return item.title.toLowerCase().includes(_this.searchinput.toLowerCase())
      })
      this.currentIndex = 0
    }, 300),
    arrowUp: function () {
      this.currentIndex = this.currentIndex === 0 ? this.filtered.length-1 : this.currentIndex-1
      this.scroll(this.filtered[this.currentIndex].isocode)
    },
    arrowDown: function () {
      this.currentIndex = this.currentIndex === this.filtered.length-1 ? 0 : this.currentIndex+1
      this.scroll(this.filtered[this.currentIndex].isocode)
    },
    currentActive: function (index) {
      return index === this.currentIndex
    },
    scroll: function(id) {
      const el = document.getElementById(id)
      this.$refs.scrollable.scrollTop = el.offsetTop
    }
  }
};
</script>
