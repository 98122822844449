/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-up': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M3.2 25.8L16 13l12.8 12.8 3.2-3.2L16 6.2 0 22.6l3.2 3.2z"/>'
  }
})
