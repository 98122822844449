/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lighthouse': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M399 482v-70.333h-26.719l-18.342-194H368v-30h-17V126h16v-15C367 49.795 317.206 0 256 0S145 49.795 145 111v15h16v61.667h-17v30h14.061l-18.342 194H113V482H0v30h512v-30H399zM256 30c38 0 69.958 26.312 78.653 61.667H177.347C186.041 56.312 218 30 256 30zm65 91.667v66h-50v-66h50zm-80 0v66h-50v-66h50zm-52.805 96h135.611l3.719 39.338-150.587 79.723 11.257-119.061zM173.56 372.461l157.021-83.129 11.566 122.335H169.853l3.707-39.206zM369 475.667H143v-34h226v34zM32 155.67h42.667v30H32zM54.998 118.669l18-24 24 18-18 24z"/><path pid="1" d="M55.002 222.668l24-18 18 24-24 18zM437.33 155.67h42.667v30H437.33zM414.994 112.672l24-18 18 24-24 18zM414.998 228.666l18-24 24 18-18 24z"/>'
  }
})
