<template>
  <div class="block relative bg-black md:min-h-512 p-t-32 md:p-t-96 p-b-72">
    <template v-if="isAvailable">
      <div class="w-80vw max-w-1280 m-x-auto">
        <vue-video-player :sources='{"src": this.source.src, "type": "video/mp4", "remote": "false"}'
                          :poster="this.source.poster"
                          :toggleplay="true"
                          :autoplay="false"
                          :controls="true"
                          :loop="false"
                          :key="videoKey"
                          class="margin-bottom-32 shadow-md">
        </vue-video-player>
      </div>

      <!--      <VueSlickCarousel v-bind="settings">-->
      <!--        <article v-for="(el, index) in sources">-->
      <!--          <figure class="position-relative padding-x-8">-->
      <!--            <a class="cursor-pointer position-relative display-block" @click="changeSource(index)">-->
      <!--              <img class="rounded-4 object-cover margin-auto width-full height-160 shadow-md" :src="el.poster" />-->
      <!--              <div class="position-absolute width-full height-full inset-0">-->
      <!--                <div class="display-flex items-center justify-center width-full height-full">-->
      <!--                  <template v-if="index===current">-->
      <!--                    <div class="display-flex justify-center items-center height-full width-full position-relative bg-black-alpha-80">-->
      <!--                      <div class="width-48 height-24 margin-left-3">-->
      <!--                        <mix-svgicon icon="ui/on-air" class="fill-current text-white"></mix-svgicon>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </template>-->
      <!--                  <template v-else>-->
      <!--                    <div class="display-flex justify-center items-center height-48 width-48 position-relative bg-black-alpha-60 rounded-full">-->
      <!--                      <div class="width-16 height-16 margin-left-3">-->
      <!--                        <mix-svgicon icon="ui/play-arrow" class="fill-current text-white"></mix-svgicon>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </template>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
      <!--            <figcaption class="text-12 leading-snug margin-top-12 text-grey-700 normalize">-->
      <!--              <a class="cursor-pointer position-relative display-block">-->
      <!--                <template v-if="el.category">-->
      <!--                  <span class="font-family-tertiary text-9 display-block margin-bottom-2 text-grey-600 uppercase font-bold">{{ el.category }}</span>-->
      <!--                </template>-->
      <!--                <template v-if="el.title">-->
      <!--                  <h3 class="text-20 font-bold leading-tight text-white">{{ el.title }}</h3>-->
      <!--                </template>-->
      <!--              </a>-->
      <!--            </figcaption>-->
      <!--          </figure>-->
      <!--        </article>-->
      <!--        <template #prevArrow="arrowOption">-->
      <!--          <div class="position-absolute inset-top-ratio-2/1 inset-left-0 z-50" style="transform: translateY(-50%);">-->
      <!--            <div class="padding-10 uppercase cursor-pointer bg-black" aria-label="prev">-->
      <!--              <div class="width-16 text-white">-->
      <!--                <mix-svgicon icon="ui/faux-left-arrow" class="fill-current width-16"></mix-svgicon>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <template #nextArrow="arrowOption">-->
      <!--          <div class="position-absolute inset-top-ratio-2/1 inset-right-0 z-50" style="transform: translateY(-50%);">-->
      <!--            <div class="padding-10 uppercase cursor-pointer bg-black" aria-label="next">-->
      <!--              <div class="width-16 text-white">-->
      <!--                <mix-svgicon icon="ui/faux-right-arrow" class="display-block fill-current width-16"></mix-svgicon>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <div></div>-->
      <!--      </VueSlickCarousel>-->
    </template>
  </div>
</template>

<script>

import VideoPlayer from './../components/VideoPlayer'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'VideoGallery',
  props: {
    sources: {
      required: true
    }
  },
  data () {
    return {
      current: 0,
      source: {},
      videoKey: 0,
      settings: {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        lazyLoad: 'progressive',
        responsive: [{
          breakpoint: 768, //tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }]
      }
    }
  },
  mounted () {
    this.changeSource(this.current)
  },
  computed: {
    isAvailable: function () {
      return !_.isEmpty(this.source)
    }
  },
  methods: {
    changeSource (index) {
      this.current = index
      this.source = this.sources[index]
      console.log(this.source)
      this.videoKey += 1 // force refresh
    },
  },
  components: {
    VideoPlayer,
    VueSlickCarousel
  }
}
</script>
