/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'castle': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M232.364 180.82h47.271c5.522 0 10-4.478 10-10v-23.635c0-18.547-15.089-33.636-33.636-33.636s-33.636 15.089-33.636 33.636v23.635c.001 5.523 4.479 10 10.001 10zm10-33.634c0-7.519 6.117-13.636 13.636-13.636s13.636 6.117 13.636 13.636v13.635h-27.271v-13.635z"/><path pid="1" d="M449.169 492h-37.617v-70.761c0-5.522-4.478-10-10-10s-10 4.478-10 10V492h-92.078v-40.211c0-23.972-19.502-43.474-43.474-43.474s-43.474 19.502-43.474 43.474V492h-92.078V345.192h281.104c2.814 0 5.585-1.241 7.461-3.342l42.926-48.104a10.102 10.102 0 0 0 2.539-6.658V211.49c0-5.522-4.478-10-10-10h-65.973V91.649h20.572c5.522 0 10-4.478 10-10V10c0-5.522-4.478-10-10-10h-54.145c-5.522 0-10 4.478-10 10v17.81h-41.86V10c0-5.522-4.478-10-10-10h-54.145c-5.522 0-10 4.478-10 10v17.81h-41.86V10c0-5.522-4.478-10-10-10h-54.145c-5.522 0-10 4.478-10 10v71.649c0 5.522 4.478 10 10 10h20.563v109.842H67.522c-5.522 0-10 4.478-10 10v75.598c0 2.43.922 4.846 2.539 6.658l40.387 45.258V492H62.831c-5.522 0-10 4.478-10 10s4.478 10 10 10h386.338c5.522 0 10-4.478 10-10s-4.478-10-10-10zM122.923 20h34.145v17.81c0 5.522 4.478 10 10 10h61.86c5.522 0 10-4.478 10-10V20h34.145v17.81c0 5.522 4.478 10 10 10h61.86c5.522 0 10-4.478 10-10V20h34.145v51.649H122.923zm30.563 71.649h205.019v109.842h-10.037c-5.522 0-10 4.478-10 10v19.32h-24.461l.003-19.318c0-5.405-4.567-10.046-10.005-10.002h-96.019c-5.522 0-10 4.478-10 10v19.32h-24.452v-19.32c0-5.522-4.478-10-10-10h-10.048zM77.522 221.491h76.01v19.32c0 5.453 4.554 10.004 10.002 10.004h44.452c5.457 0 10.009-4.554 10.009-10.004v-19.32h76.01v19.324c0 5.445 4.555 10 10 10h44.463c5.522 0 10-4.478 10-10v-19.324h76.01v55.598H77.522zm344.629 75.598l-25.079 28.104H114.928l-25.079-28.104zM232.526 492v-40.211c0-12.943 10.53-23.474 23.474-23.474s23.474 10.53 23.474 23.474V492z"/><path pid="2" d="M409.87 372.62c-3.043-4.528-9.326-5.775-13.87-2.761-4.545 3.014-5.768 9.344-2.76 13.87 3.016 4.537 9.336 5.775 13.859 2.761 4.535-3.022 5.786-9.339 2.771-13.87z"/>'
  }
})
