/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-left': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.8 28.8L13 16 25.8 3.2 22.6 0 6.2 16l16.4 16 3.2-3.2z"/>'
  }
})
