/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'boat': {
    width: 16,
    height: 16,
    viewBox: '0 0 490.108 490.108',
    data: '<path pid="0" d="M476.023 321.877c-57.889 22.819-136.768 36.648-209.874 44.792v-41.452l181.028-17.586c5.614-.544 9.715-5.533 9.172-11.141-.169-1.784-18.15-181.322-194.986-263.987-4.914-2.297-12.173-1.015-14.465 7.233-.659 2.373-48.856 241.046-153.2 282.091-12.071 4.748-4.728 20.287 3.629 19.744.328-.021 148.405-14.37 148.405-14.37v41.62c-96.068 9.554-177.15 9.826-178.617 9.826-7.763 0-12.428 8.693-8.913 15.172l18.247 32.794c-7.379 4.508-12.03 6.809-22.732 6.789-23.537-.043-31.498-8.479-43.79-8.479-5.634 0-10.079 4.845-9.924 10.483.154 5.632 4.969 10.397 10.482 9.924 2.327.095 17.809 8.489 42.972 8.489 17.065 0 26.034-5.139 35.33-10.817 2.797-1.71 5.822-3.564 9.441-5.453 16.838-8.828 33.367-.678 54.875 11.828 20.98 12.197 39.025 3.325 53.509-3.818 6.022-2.96 11.709-5.763 17.287-7.118 10.029-2.452 19.544 2.597 30.565 8.439 9.735 5.16 20.771 11.011 33.457 11.644 1.236.065 2.452.095 3.648.095 18.109 0 31.692-6.934 44.85-13.647 3.021-1.545 6.096-3.116 9.297-4.636 10.537-5 20.346-1.496 35.475 4.655 2.472 1.008 4.959 2.02 7.461 2.972l1.321.509c12.591 4.814 26.775 9.851 42.993 7.042 13.556-2.348 33.16-16.036 44.148-15.836 5.513-.169 10.243-4.49 10.288-10.128.045-5.637-4.492-10.288-10.129-10.288-17.151 0-38.252 14.53-48.315 16.235-6.609 1.12-13.136.194-19.85-1.863l78.572-95.79c6.742-7.281-1.672-19.903-11.657-15.967zM264.135 56.798c131.657 69.844 163.663 195.22 170.327 231.558l-294.537 28.611c75.432-65.068 113.922-214.525 124.21-260.169zm124.712 369.264c-15.332-6.231-32.714-13.278-51.898-4.165-3.37 1.601-6.619 3.261-9.814 4.89-13.229 6.749-23.667 12.108-38.192 11.351-8.155-.409-16.294-4.726-24.919-9.297-12.835-6.804-27.395-14.524-44.961-10.238-7.75 1.888-14.724 5.318-21.468 8.638-14.485 7.143-23.223 10.881-34.234 4.48-18.303-10.637-42.928-24.931-68.953-14.83l-10.027-18.018c56.719-.992 238.287-7.198 364.471-45.928l-60.005 73.117z"/>'
  }
})
