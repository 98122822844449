/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube': {
    width: 16,
    height: 16,
    viewBox: '0 0 204 142',
    data: '<path pid="0" d="M102 .2S38.7.2 22.9 4.3a26.1 26.1 0 0 0-18 18c-4 15.9-4 48.8-4 48.8s0 33 4 48.6a25.7 25.7 0 0 0 18 17.8c16 4.3 79 4.3 79 4.3s63.4 0 79.2-4.1a25.1 25.1 0 0 0 17.8-18c4.2-15.7 4.2-48.5 4.2-48.5s.2-33-4.2-48.9A25 25 0 0 0 181 4.6C165.2.2 101.8.2 101.8.2h.2zM81.8 40.7l52.6 30.4-52.6 30.2V40.7z"/>'
  }
})
