<template>
  <div class="w-256">
    <template v-if="el.media">
      <figure>
        <a :href="el.route" class="relative block cursor-pointer">
          <div :data-bg="el.media.src" class="w-full overflow-hidden aspect-ratio-w-16 aspect-ratio-h-9 bg-cover bg-center lazy"></div>
        </a>
        <figcaption class="p-16 p-b-24">
          <span class="block font-bold text-micro text-green">{{ el.type }}</span>
          <h4 class="font-heading uppercase responsive-text-lg leading-snug text-primary hover:text-azure cursor-pointer tracking-tight"
              @click.prevent="link(el.route)">
            {{ el.name }}
          </h4>
        </figcaption>
      </figure>
    </template>
  </div>
</template>

<script>

import LazyLoad from 'vanilla-lazyload'

export default {
  name: 'MapCard',
  props: {
    el: {required: true,},
  },
  data() {
    return {
      ModalLazyLoad: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ModalLazyLoad = new LazyLoad({
        elements_selector: '.lazy',
        class_loaded: 'lazy__loaded',
        class_loading: 'lazy__loading',
        load_delay: 0,
        use_native: false,
      })
    })
  },
  beforeDestroy() {
    this.ModalLazyLoad.destroy()
  },
  methods: {
    link: function (url) {
      window.location.href = url
    }
  },

}

</script>
