/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checked': {
    width: 16,
    height: 16,
    viewBox: '0 0 515.556 515.556',
    data: '<path pid="0" d="M0 274.226l176.549 176.886L515.556 112.44l-48.67-47.997-290.337 290L47.996 225.891z"/>'
  }
})
