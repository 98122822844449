<template>
  <div class="display-grid grid-cols-5 grid-rows-auto column-gap-16 row-gap-16 grid-rows-auto-content">
    <button
      class="width-full display-flex items-center justify-center padding-y-16 border-1 border-dotted border-grey-400 col-span-2"
      @click.prevent="showGallery()">
      <div class="display-flex items-center justify-center">
        <div class="position-relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256" class="w-32 h-32 m-r-8">
            <path fill="currentColor"
              d="M208 56h-27.72l-13.63-20.44A8 8 0 0 0 160 32H96a8 8 0 0 0-6.65 3.56L75.71 56H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h160a24 24 0 0 0 24-24V80a24 24 0 0 0-24-24Zm8 136a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8V80a8 8 0 0 1 8-8h32a8 8 0 0 0 6.66-3.56L100.28 48h55.43l13.63 20.44A8 8 0 0 0 176 72h32a8 8 0 0 1 8 8ZM128 88a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44Zm0 72a28 28 0 1 1 28-28a28 28 0 0 1-28 28Z" />
          </svg>
          <!-- <mix-svgicon icon="ui/gallery" class="display-block fill-current margin-right-8 width-32" /> -->
          <div
            class="width-20 height-20 rounded-full bg-green display-inline-block text-white font-family-tertiary font-semibold text-12 text-center position-absolute leading-relaxed -inset-top-8">
            {{ els.length }}</div>
        </div>
        <span class="font-family-tertiary text-12 text-left leading-none">
          SFOGLIA<br />LA GALLERY
        </span>
      </div>
    </button>
    <div class="position-relative overflow-hidden display-block width-auto bg-grey-100" v-for="el in (els.slice(0, 3))">
      <div class="position-relative overflow-hidden width-auto cursor-pointer" @click.prevent="showGallery()">
        <div class="height-full position-relative display-block bg-cover bg-center lazy padding-bottom-ratio-1/1"
          :data-bg="el.picture">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GalleryThumbnails',
  props: {
    els: {
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    showGallery: function () {
      this.$emit('show', true)
    }
  },
}

</script>
