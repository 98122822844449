<template>
  <div class="position-absolute inset-0 width-full height-full display-flex items-center justify-center">
    <div class="bg-white width-auto min-width-320 desktop:width-ratio-2/1 padding-y-36 padding-x-48 shadow-xl">
      <h3 class="text-24 font-bold tracking-tight">Scarica la tua guida Campania Lonely Planet</h3>
      <template v-if="!link">
        <div class="text-14 margin-top-8 text-grey-700">Inserisci il tuo codice e scarica gratuitamente la tua copia in PDF. Il codice è valido per un solo download. Se hai bisogno di aiuto scrivi a
          <span class="underline">assistenza@edt.it</span>.
        </div>
      </template>
      <div class="margin-top-32">
        <transition name="fade"
                    mode="out-in"
        >
          <template v-if="link">
            <a class="button button--solid button--small width-full" :href="link">Scarica il file PDF</a>
          </template>
          <template v-else>
            <div class="property property--full display-flex flex-row">
              <div class="margin-right-8 width-full">
                <div class="property__value margin-right-8">
                  <input type="text"
                         class="property__text-box"
                         :class="{'border-red': message}"
                         placeholder="code"
                         name="code"
                         id="code"
                         aria-required="true"
                         aria-invalid="false"
                         v-model="code"
                         v-maska="'XXXX-XXXX-XXXX'"
                         @keyup.enter="process" />
                </div>
                <template v-if="message">
                  <div class="property__error padding-x-2 padding-top-8">{{ message }}</div>
                </template>
                <div class="property__error padding-x-2 padding-top-8"></div>
              </div>
              <button class="button button--solid button--extra-small" style="height:40px; margin-top:2px" @click="process">Inserisci</button>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  name: 'CampaniaCode',
  data () {
    return {
      code: null,
      message: null,
      link: null
    }
  },
  methods: {
    process: function () {
      let _this = this
      axios({
        method: 'GET',
        url: process.env.WWW_URL + '/api/b2b-campania-codes?code=' + _this.code,
      }).then((res) => {
        if (res && res.data && res.data.status === 'success') {
          this.link = res.data.link
        } else {
          this.message = 'Il codice inserito non è corretto o valido, riprova.'
        }
      })
    }
  }

}
</script>
