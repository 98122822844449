/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M508.788 371.087L263.455 125.753a10.623 10.623 0 00-15.04 0L2.975 371.087c-4.053 4.267-3.947 10.987.213 15.04a10.763 10.763 0 0014.827 0l237.867-237.76 237.76 237.76c4.267 4.053 10.987 3.947 15.04-.213a10.663 10.663 0 00.106-14.827z"/>'
  }
})
