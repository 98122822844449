<template>
  <div class="absolute inset-0">
    <button class="absolute bottom-0 right-0 m-16 rounded-16 shadow-lg bg-white p-x-4 p-y-4" @click.prevent="show()">
      <span class="block p-t-4 p-b-8 text-xs text-center font-semibold leading-none">Gallery</span>
      <div class="relative w-80 h-80">
        <img class="max-w-full object-cover rounded-16 lazyload" :data-src="preview.thumbnail" decoding="async" width="80"
          height="80" />
        <div
          class="absolute right-0 font-semibold bottom-0 m-x-4 m-y-4 rounded-8 w-32 h-24 text-micro text-white bg-black leading-none shadow-sm flex flex-row items-center justify-center">
          <span>+{{ this.els.length - 1 }}</span>

        </div>
      </div>
    </button>
    <template v-if="visible">
      <div class="z-top flex flex-col fixed top-0 left-0 w-screen h-screen bg-black p-32">
        <header class="lg:fixed top-0 left-0 w-full flex justify-between items-center p-b-16 lg:p-32">
          <h4 class="text-white font-semibold">
            <span class="hidden lg:inline text-3xl">{{ this.currentIndex + 1 }} /</span>
            <span class="hidden lg:inline text-xl">{{ this.els.length }}</span>
            <span class="lg:hidden text-xl">{{ this.currentIndex + 1 }} di {{ this.els.length }}</span>
          </h4>
          <button class="block m-l-auto text-white w-32 h-32 lg:w-64 lg:h-64" @click.prevent="hide()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                d="m7 7l10 10M7 17L17 7" />
            </svg>
          </button>
        </header>
        <div class="lg:m-x-128 flex-grow relative h-full">
          <div class="flex overflow-x-hidden w-full h-full">
            <div v-for="(el, idx) in els" class="w-full lg:max-h-screen flex-shrink-0 flex-col" :key="idx"
              :class="[idx === currentIndex ? 'flex' : 'hidden']">
              <div class="relative w-full h-auto flex-1 overflow-hidden">
                <img class="max-w-full h-full object-cover rounded-16 overflow-hidden lazyload" :data-src="el.fullsize"
                  decoding="async" width="1500" height="800" />
              </div>
              <div class="w-10/12 lg:w-6/12 m-x-auto flex flex-col gap-y-8 m-t-24">
                <template v-if="el.caption">
                  <span class="text-white">{{ el.caption }}</span>
                </template>
                <template v-if="el.credits">
                  <span class="font-mono text-sm text-black-200">© {{ el.credits }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <template v-if="currentIndex > 0">
          <button class="absolute w-64 h-64 lg:w-128 lg:h-128 left-0 top-6/12 translate-y-neg6/12 m-16"
            @click.prevent="prev()">
            <img v-for="(el, idx) in els" class="max-w-full w-full h-full rounded-16 object-cover lazyload" :key="idx"
              :class="[idx === (currentIndex - 1) ? 'block' : 'hidden']" decoding="async" :data-src="el.thumbnail"
              width="200" height="200" alt="Previous" />
            <div
              class="absolute bottom-0 left-0 w-32 h-32 bg-black text-white m-x-4 m-y-4 rounded-8 flex flex-col items-center justify-center shadow-sm">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-24 h-24" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  d="m12 5l-7 7m0 0l7 7m-7-7h14" />
              </svg>
            </div>
          </button>
        </template>
        <template v-if="currentIndex < (els.length - 1)">
          <button class="absolute w-64 h-64 lg:w-128 lg:h-128 right-0 top-6/12 translate-y-neg6/12 m-16"
            @click.prevent="next()">
            <img v-for="(el, idx) in els" class="max-w-full w-full h-full rounded-16 object-cover lazyload" :key="idx"
              :class="[idx === (currentIndex + 1) ? 'block' : 'hidden']" decoding="async" :data-src="el.thumbnail"
              width="200" height="200" alt="Next" />
            <div
              class="absolute bottom-0 right-0 w-32 h-32 bg-black text-white m-x-4 m-y-4 rounded-8 flex flex-col items-center justify-center shadow-sm">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-24 h-24" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  d="m12 19l7-7l-7-7m7 7H5" />
              </svg>
            </div>
          </button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PlaceGalleryModule',
  props: {
    els: {
      required: true
    }
  },
  data() {
    return {
      visible: false,
      currentIndex: 0,
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    preview: function () {
      return _.first(this.els)
    },
  },
  methods: {
    show: function () {
      this.visible = true
      const scroll = window.scrollY
      document.body.style.overflow = 'hidden'
      window.scrollTo(0, scroll)
    },
    hide: function () {
      document.body.style.overflow = ''
      document.body.style.top = ''
      this.visible = false
    },
    next: function () {
      this.currentIndex++
    },
    prev: function () {
      this.currentIndex--
    }
  }
}
</script>