<template>
  <button @click="showModal" class="display-block self-start text-left" type="button">
    <slot name="component"></slot>
    <modal :name="uid" :id="uid" :ref="uid" :classes="classes" height="90%" @before-close="beforeClose" @opened="opened"
      @closed="closed">
      <slot name="modal"></slot>
    </modal>
  </button>
</template>

<script>

import LazyLoad from 'vanilla-lazyload'

export default {
  name: 'AddModalFunctionModule',
  props: {
    classes: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ModalLazyLoad: false
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    showModal() {
      this.$modal.show(this.uid)
      const scroll = window.scrollY
      document.documentElement.style.overflow = 'hidden'
      document.body.style.top = '-' + scroll + 'px'
    },
    opened(e) {
      this.$nextTick(() => {
        this.ModalLazyLoad = new LazyLoad({
          container: e.ref,
          elements_selector: '.lazy',
          class_loaded: 'lazy__loaded',
          class_loading: 'lazy__loading',
          load_delay: 0,
          use_native: false,
        })
      })
    },
    beforeClose() {
      document.documentElement.style.overflow = ''
      document.body.style.top = ''
    },
    close() {
      document.documentElement.style.overflow = ''
      document.body.style.top = ''
      this.$modal.hide(this.uid)
    },
    closed() {
      this.ModalLazyLoad.destroy()
    }
  },
}
</script>
