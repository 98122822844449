/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cash-delivery': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M49.554 0H10.446L0 16.713V60h60V16.713L49.554 0zm7.642 16H31V2h17.446l8.75 14zM11.554 2H29v14H2.804l8.75-14zM2 58V18h56v40H2z"/><path pid="1" d="M36 23H24c-1.654 0-3 1.346-3 3s1.346 3 3 3h12c1.654 0 3-1.346 3-3s-1.346-3-3-3zm0 4H24a1.001 1.001 0 0 1 0-2h12a1.001 1.001 0 0 1 0 2zM38 52H22a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2zM25 50a1 1 0 0 0 1-1v-6.586l1.293 1.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-2.999-2.999a1.001 1.001 0 0 0-1.416 0l-2.999 2.999a.999.999 0 1 0 1.414 1.414L24 42.414V49a1 1 0 0 0 1 1zM35.708 39.294a1.001 1.001 0 0 0-1.416 0l-2.999 2.999a.999.999 0 1 0 1.414 1.414L34 42.414V49a1 1 0 1 0 2 0v-6.586l1.293 1.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-2.999-2.999z"/>'
  }
})
