/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'backpack': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M457.122 307.865h-27.036V159.717c0-17.49-5.914-33.617-15.828-46.517C445.073 112.732 470 87.535 470 56.611 470 25.396 444.604 0 413.389 0H98.611C67.396 0 42 25.396 42 56.611c0 30.923 24.927 56.12 55.742 56.588-9.915 12.9-15.828 29.027-15.828 46.517v148.148H54.878c-26.1 0-47.333 21.234-47.333 47.334v120.254h77.859C93.326 496.77 113.873 512 137.914 512h236.172c24.041 0 44.588-15.23 52.51-36.547h77.859V355.199c0-26.1-21.233-47.334-47.333-47.334zM81.914 445.453H37.545v-38.794h44.369v38.794zm0-68.794H37.545v-21.46c0-9.557 7.775-17.334 17.333-17.334h27.036v38.794zM170 30h172v53.222H170V30zm101 356v-9h54.38v55.09H186.62V377H241v9h30zm0-39v-7h-30v7h-54.38v-28.403h138.76V347H271zm-72.341-175.668v-58.111h114.682v58.111h-12.697v30h12.697v12.939H198.659v-12.939h12.697v-30h-12.697zm114.682 72.94v44.325H198.659v-44.325h114.682zm-144.682-131.05v58.111h-11.97v30h11.97v12.939h-56.745v-54.555c0-25.638 20.857-46.495 46.495-46.495h10.25zM72 56.611C72 41.938 83.938 30 98.611 30H140v53.222H98.611C83.938 83.222 72 71.284 72 56.611zm328.086 251.255V456c0 14.336-11.663 26-26 26H137.914c-14.337 0-26-11.664-26-26V244.271h56.745v44.325H156.62V462.09h198.76V288.597h-12.039v-44.325h56.745v63.594zm0-93.594h-56.745v-12.939h11.97v-30h-11.97v-58.111h10.25c25.638 0 46.495 20.857 46.495 46.495v54.555zM372 83.221V30h41.389C428.063 30 440 41.938 440 56.611s-11.938 26.61-26.611 26.61H372zm102.455 362.232h-44.369v-38.794h44.369v38.794zm0-68.794h-44.369v-38.794h27.036c9.558 0 17.333 7.776 17.333 17.334v21.46z"/>'
  }
})
