<template>
  <div class="place-description">
    <div class="text-16 tablet:text-20 leading-relaxed normalize font-secondary paragraphs padding-top-40" v-html="show">
      {{ show }}
    </div>
    <div class="readmore__action callout-link padding-top-24" @click="toggle()">
      <slot name="separator"></slot>
      <slot name="more" v-if="!showing"></slot>
      <slot name="less" v-else></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapseWrapper',
  props: {
    text: {
      type: String,
      required: true,
    },
    moreText: {
      type: String,
      default: 'Show more',
    },
    lessText: {
      type: String,
      default: 'Show less',
    },
  },
  data () {
    return {
      showing: false,
    }
  },
  computed: {
    show () {
      return this.showing ? this.text : ''
    },
  },
  methods: {
    toggle () {
      this.showing = !this.showing
    },
  },
}
</script>
