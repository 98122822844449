/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email': {
    width: 16,
    height: 16,
    viewBox: '0 0 511.991 511.991',
    data: '<path pid="0" d="M511.991 196.243c0-.179-.094-.333-.102-.503a8.146 8.146 0 0 0-.35-1.741 8.824 8.824 0 0 0-.486-1.476 8.236 8.236 0 0 0-.853-1.28 8.38 8.38 0 0 0-1.161-1.348c-.137-.12-.196-.282-.341-.393l-82.039-63.735V59.71c0-14.138-11.462-25.6-25.6-25.6h-92.476L271.556 5.361a25.319 25.319 0 0 0-31.121 0L203.401 34.11h-92.476c-14.138 0-25.6 11.461-25.6 25.6v66.057L3.294 189.502c-.145.111-.205.273-.341.393a8.38 8.38 0 0 0-1.161 1.348c-.324.399-.61.828-.853 1.28-.207.476-.37.97-.486 1.476a8.087 8.087 0 0 0-.35 1.707c0 .171-.102.324-.102.503v290.167a25.294 25.294 0 0 0 1.425 8.064 8.456 8.456 0 0 0 9.387 12.8 25.415 25.415 0 0 0 14.78 4.736h460.8a25.427 25.427 0 0 0 14.822-4.804 8.378 8.378 0 0 0 2.244.367 8.456 8.456 0 0 0 7.117-13.098 25.318 25.318 0 0 0 1.417-8.064V196.243zM250.871 18.827a8.182 8.182 0 0 1 10.18 0l19.686 15.283h-49.493l19.627-15.283zM27.708 494.91l223.164-173.346a8.209 8.209 0 0 1 10.172 0L484.275 494.91H27.708zm467.217-13.329L271.539 308.09a25.309 25.309 0 0 0-31.095 0L17.058 481.581V209.239l139.836 108.587a8.536 8.536 0 0 0 11.977-1.515 8.536 8.536 0 0 0-1.515-11.977L25.293 194.016l60.032-46.643v65.937a8.533 8.533 0 0 0 17.066 0V59.71a8.533 8.533 0 0 1 8.533-8.533h290.133a8.533 8.533 0 0 1 8.533 8.533v153.6a8.533 8.533 0 0 0 17.066 0v-65.937l60.032 46.643-142.317 110.515a8.536 8.536 0 0 0 10.471 13.483L494.925 209.24v272.341z"/><path pid="1" d="M170.658 110.91h170.667c4.713 0 8.533-3.82 8.533-8.533s-3.82-8.533-8.533-8.533H170.658c-4.713 0-8.533 3.82-8.533 8.533s3.82 8.533 8.533 8.533zM349.858 153.577a8.533 8.533 0 0 0-8.533-8.533H170.658a8.533 8.533 0 0 0 0 17.066h170.667a8.533 8.533 0 0 0 8.533-8.533zM349.858 204.777a8.533 8.533 0 0 0-8.533-8.533h-76.8c-4.713 0-8.533 3.82-8.533 8.533s3.82 8.533 8.533 8.533h76.8a8.533 8.533 0 0 0 8.533-8.533z"/>'
  }
})
