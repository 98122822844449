<template>
  <hooper ref="hooper" :settings="hooperSettings"
          :touchDrag="navigable"
          style="outline: none; height: auto">
    <template v-if="navigable">
      <hooper-navigation slot="hooper-addons">
        <div class="PaginationItem display-inline-block margin-x-3" aria-label="Previous" slot="hooper-prev">
          <a class="border-2 border-trans rounded-full width-full height-full display-flex items-center justify-content-center text-grey-900 text-sm font-bold hover:border-azure hover:text-azure">
            <svgicon name="arrow-prev"></svgicon>
          </a>
        </div>
        <div class="PaginationItem display-inline-block margin-x-3" aria-label="Next" slot="hooper-next">
          <a class="border-2 border-trans rounded-full width-full height-full display-flex items-center justify-content-center text-grey-900 text-sm font-bold hover:border-azure hover:text-azure">
            <svgicon name="arrow-next"></svgicon>
          </a>
        </div>
      </hooper-navigation>
    </template>
    <slide v-for="(el, indx) in els" :key="indx" :index="indx">
      <div class="ProductCard bg-white width-full padding-12">
        <div class="ProductCardMedia bg-grey-200 margin-bottom-12">
          <a :href="el.link" class="display-block" target="_self">
            <img :data-src="el.picture" alt="" class="display-block width-full lazy" />
          </a>
        </div>
        <div class="ProductCardDetails">
          <a :href="el.link" class="display-block" target="_self">
            <h5 class="ProductCardTitle TextPrimaryLink text-sm leading-snug break-words truncate">{{ el.title }}</h5>
            <p class="ProductCardText font-family-tertiary text-grey-500 uppercase text-xs font-bold">{{ el.content }}</p>
          </a>
        </div>
      </div>
    </slide>
  </hooper>
</template>

<script>
  import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
  import 'hooper/dist/hooper.css'

  export default {
    name: 'HooperCarousel',
    components: {
      Hooper,
      Slide,
      HooperNavigation
    },
    props: {
      els: {
        required: true
      },
    },
    data () {
      return {
        hooperSettings: {
          wheelControl: false,
          mouseDrag: false,
          keysControl: false,
          centerMode: false,
          touchDrag: true,
          itemsToShow: 2,
          itemsToSlide: 2,
          breakpoints: {
            576: {
              itemsToShow: 3
            },
            768: {
              itemsToShow: 4
            },
            1200: {
              itemsToShow: 6
            },
          }
        },
        itemsShowed: 0,
        navigable: true,
      }
    },
    created () {
      window.addEventListener('resize', this.getItemsShowed)
    },
    destroyed () {
      window.removeEventListener('resize', this.getItemsShowed)
    },
    mounted () {
      this.getItemsShowed()
    },
    methods: {
      getItemsShowed (e) {
        this.$nextTick(() => {
          let _this = this,
            el = null
          _.each(_.sortBy(Object.keys(this.hooperSettings.breakpoints), function (a, b) {
            return b - a
          }), function (breakpoint) {
            if (window.matchMedia('(min-width: '.concat(breakpoint, 'px)')).matches) {
              _this.el = _this.hooperSettings.breakpoints[breakpoint].itemsToShow
            }
          })
          _this.itemsShowed = _this.el ? _this.el : _this.hooperSettings.itemsToShow
          _this.toggleNavigation()
        }, 100)
      },
      toggleNavigation () {
        this.navigable = this.els.length > this.itemsShowed
        this.$refs.hooper.$forceUpdate()
      }
    }
  }
</script>
