/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mouse': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 2c4.7 0 8.5 3.8 8.5 8.5v11c0 4.7-3.8 8.5-8.5 8.5s-8.5-3.8-8.5-8.5v-11C7.5 5.8 11.3 2 16 2zm0-2C10.2 0 5.5 4.7 5.5 10.5v11C5.5 27.3 10.2 32 16 32s10.5-4.7 10.5-10.5v-11C26.5 4.7 21.8 0 16 0z"/><path pid="1" d="M17.5 8.5a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 17.5 8.5z"/>'
  }
})
