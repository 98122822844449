/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'xclose': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M32 1.9L30.1 0 16 14.1 1.9 0 0 1.9 14.1 16 0 30.1 1.9 32 16 17.9 30.1 32l1.9-1.9L17.9 16z"/>'
  }
})
