<template>
  <div class="readmore">
    <div class="text-16 tablet:text-20 leading-relaxed normalize font-secondary paragraphs" v-html="showing ? text : truncate">{{ showing ? text : truncate }}</div>
    <div class="readmore__action callout-link" @click="toggle()" v-if="this.text.length > this.limit">
      <slot name="separator"></slot>
      <slot name="more" v-if="!showing"></slot>
      <slot name="less" v-else></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadMore',
  props: {
    text: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 180,
    },
    moreText: {
      type: String,
      default: 'Show more',
    },
    lessText: {
      type: String,
      default: 'Show less',
    },
  },
  data () {
    return {
      showing: false,
    }
  },
  computed: {
    truncate () {
      if (this.text.length < this.limit) { return this.text }
      return this.$options.filters.truncate(this.text, this.limit)
    },
  },
  methods: {
    toggle () {
      this.showing = !this.showing
    },
  },
}
</script>
