/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 363.025,
    height: 363.024,
    viewBox: '0 0 363.025 363.024',
    data: '<path pid="0" d="M181.512 0C81.422 0 0 81.424 0 181.513c0 100.088 81.422 181.512 181.512 181.512 100.089 0 181.513-81.424 181.513-181.512C363.025 81.424 281.601 0 181.512 0zm0 351.314c-93.626 0-169.802-76.175-169.802-169.802S87.886 11.71 181.512 11.71c93.627 0 169.803 76.176 169.803 169.803s-76.176 169.801-169.803 169.801z" _fill="#030303"/><path pid="1" _fill="#030303" d="M187.368 111.25h-11.71v64.407H111.25v11.711h64.408v64.407h11.71v-64.407h64.408v-11.711h-64.408z"/>'
  }
})
