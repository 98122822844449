<template>
  <div class="flex flex-wrap flex-row justify-center gap-x-32 lg:gap-x-64 w-full bg-blue-500 text-white font-bold p-y-16">
    <div class="flex flex-col items-center justify-center">
      <h5 class="text-5xl lg:text-7xl">{{ days | twoDigits }}</h5>
      <p class="text-xs lg:text-base uppercase">{{ (days > 1 || days == 0) ? 'giorni' : 'giorno' }}</p>
    </div>
    <div class="flex flex-col items-center justify-center">
      <h5 class="text-5xl lg:text-7xl">{{ hours | twoDigits }}</h5>
      <p class="text-xs lg:text-base uppercase">{{ (hours > 1 || hours == 0) ? 'ore' : 'ora' }}</p>
    </div>
    <div class="flex flex-col items-center justify-center">
      <h5 class="text-5xl lg:text-7xl">{{ minutes | twoDigits }}</h5>
      <p class="text-xs lg:text-base uppercase">{{ (minutes > 1 || minutes == 0) ? 'minuti' : 'minuto' }}</p>
    </div>
    <div class="flex flex-col items-center justify-center">
      <h5 class="text-5xl lg:text-7xl">{{ seconds | twoDigits }}</h5>
      <p class="text-xs lg:text-base uppercase">{{ (seconds > 1 || seconds == 0) ? 'secondi' : 'secondo' }}</p>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

let interval = null

export default {
  name: 'Countdown',
  props: {
    deadline: {
      type: Number,
    },
  },
  data() {
    return {
      now: moment().unix(),
      diff: 0,
    }
  },
  created() {
    interval = setInterval(() => {
      this.now = moment().unix()
    }, 1000)
  },
  computed: {
    seconds() {
      return Math.trunc(this.diff) % 60
    },
    minutes() {
      return Math.trunc(this.diff / 60) % 60
    },
    hours() {
      return Math.trunc(this.diff / 60 / 60) % 24
    },
    days() {
      return Math.trunc(this.diff / 60 / 60 / 24)
    },
  },
  watch: {
    now() {
      this.diff = this.deadline - this.now
      if (this.diff <= 0) {
        this.diff = 0
        clearInterval(interval)
      }
    },
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },
  },
  destroyed() {
    clearInterval(interval)
  },
}
</script>