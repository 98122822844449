<template>
  <article class="masthead">
    <div class="masthead__item">
      <div class="masthead__image-container" v-if="el.picture">
        <div class="gradient-overlay"></div>
        <picture class="masthead__image" :style="{ backgroundImage: 'url(' + el.picture + ')' }"></picture>
      </div>
      <div class="masthead__container">
        <div class="masthead__claim">
          <template v-if="el.interior">
            <a :href="el.interior.route">
              <div class="masthead__claim__interior" v-if="el.interior.svg">
                <svgicon :name="el.interior.svg" class="icon is-fit"></svgicon>
              </div>
              <div class="masthead__claim__interior" v-else>
                {{ el.interior.title }}
              </div>
            </a>
          </template>
          <template v-if="el.title && el.title.svg">
            <h1 class="masthead__claim__title">
              <svgicon :name="el.title.svg" class="icon is-fit" :original="el.title.svg_original ? true : false"></svgicon>
            </h1>
          </template>
          <template v-else>
            <span v-if="special" class="masthead__claim__title--specialmarker ">
                {{ el.special.marker }}
            </span>
            <h1 class="masthead__claim__title" :class="{ 'masthead__claim__title--elegant': elegant, 'masthead__claim__title--special': special }">
              {{ el.title }}
            </h1>
          </template>
          <h3 class="masthead__claim__strapline"  v-if="el.raw_strapline" v-html="el.raw_strapline"></h3>
          <h3 class="masthead__claim__strapline" v-if="el.strapline">
            {{ el.strapline }}
          </h3>
          <template v-if="special">
            <div class="masthead__claim__partnerline">
              <template v-if="el.special.credits">
                <div class="credits">
                  {{ el.special.credits }}
                </div>
              </template>
              <template v-if="el.special.logo">
                <div class="credits__logo">
                  <a :href="el.special.logo_url" target="_blank">
                    <img :src="el.special.logo" alt="logo" class="masthead__claim__partnerline__logo">
                  </a>
                </div>
              </template>
            </div>
          </template>
        </div>
        <template v-if="footer">
          <div class="masthead__footer">
            <div class="masthead__footer__item">
              <div class="scroll-indicator-mouse">
                <svgicon name="mouse" class="icon icon--white"></svgicon>
              </div>
              <div class="scroll-indicator-arrows">
                <svgicon name="chevron-down" class="icon icon--white"></svgicon>
                <svgicon name="chevron-down" class="icon icon--white"></svgicon>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </article>
</template>

<script>

  export default {

    inherit: true,
    name: 'MastHead',
    props: ['el', 'elegant', 'special', 'footer'],

    data () {
      return {}
    },


  }

</script>
