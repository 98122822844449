<template>
  <div class="block h-full">
    <div class="flex flex-col flex-wrap h-full">
      <vueper-slides
        ref="BooksAndGuidesSlider"
        :arrows="false"
        :bullets="true"
        :bulletsOutside="false"
        :parallax="false"
        :touchable="false"
        class="h-full"
        :breakpoints="breakpoints"
      >
        <vueper-slide
          v-for="(el, i) in elements"
          :key="el.id"
        >
          <template #content>
            <div class="m-x-auto w-192 md:w-256 lg:w-320">
              <a class="block"
                 :href="el.link"
                 target="_blank">
                <img class="object-cover rounded-8 shadow-md lazyload h-full w-full"
                     :data-src="el.media.src"
                     :alt="el.title"
                >
              </a>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>

<script>

import { VueperSlide, VueperSlides } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'BooksAndGuidesSlider',
  props: {
    elements: {
      required: true,
    }
  },
  data () {
    return {
      breakpoints: {
        767: {
          fixedHeight: '270px'
        },
        991: {
          fixedHeight: '360px'
        },
        9999: {
          fixedHeight: '540px'
        }
      }

    }
  },
  mounted () {
  },
  computed: {},
  components: { VueperSlides, VueperSlide },
}

</script>
