<template>
  <form name="nl_frm" accept-charset="UTF-8" method="post" @submit.prevent="frmSubmit">
    <template v-if="showintro">
      <div class="margin-bottom-8">
        Inizia il tuo viaggio, iscriviti <strong>gratuitamente</strong> alla <a href="/newsletter">Newsletter</a> di
        Lonely Planet Italia e <strong>ricevi il 30% di sconto per il tuo prossimo acquisto digitale</strong>.
      </div>
    </template>
    <input type="text" v-validate="'required|email'" v-model="email" placeholder="Email" name="email" class="property__text-box has-mb-4" style="width: 99%; max-width: 99%;"/>
    <div class="property__error" v-show="errors.has('email')">{{ errors.first('email') }}</div>
    <div class="margin-bottom-12 margin-top-12">
      Cliccando ci autorizzi al trattamento dei dati inseriti secondo le finalità indicate nella
      <a href="https://www.lonelyplanetitalia.it/legal/privacy">informativa sulla Privacy</a>.
      <br/>
      Potrai disicriverti in ogni momento cliccando sul link presente nella <a href="/newsletter">newsletter</a>.
    </div>
    <button class="button button--solid button--extra-small" :disabled="!email">
      Iscriviti
    </button>
    <loading :active.sync="this.isLoading"
             :can-cancel="this.canCancel"
             :is-full-page="this.isFullPage"
             color="#297cbb"></loading>
    <generic-modal-dialog :message="popup_message"
                          title="Iscrizione alla Newsletter"
                          :show="showPopup"></generic-modal-dialog>
  </form>
</template>

<script>

import {eventsbus} from '../mixins/EventsBus.js'

export default {

  inherit: true,
  name: 'NewsletterForm',
  props: {
    callback: {
      type: String,
      required: true,
    },
    showintro: {
      default: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isFullPage: false,
      canCancel: false,
      email: '',
      showPopup: false,
      popup_message: '',
    }
  },
  mounted() {
    eventsbus.$on('generic-modal-closed', function() {
      this.showPopup = false
    }.bind(this))
  },
  methods: {
    frmSubmit: function(e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoading = true
          axios.post(this.callback, {email: this.email}).then((response) => {
            this.isLoading = false
            this.popup_message = response.data.msg
            this.showPopup = true
          }).catch((error) => {
            console.log(error)
          })
        }
      })
    },
  },
}

</script>
