<template>
  <div :class="[isVisible ? 'bottom-0' : 'bottom-neg256']"
  class="w-full max-w-full transition-all duration-400 fixed z-100">
    <div class="flex flex-wrap justify-end">
      <div class="p-x-20 p-y-6 bg-black cursor-pointer" @click="close">
        <span class="font-bold text-xs text-white">X Chiudi il player</span>
      </div>
    </div>
    <div class="flex bg-black">
      <div class="container p-y-24">
        <template v-if="el && el.id">
          <iframe :src="resource"
                  height="128px"
                  width="100%"></iframe>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import { eventsbus } from '../mixins/EventsBus.js'

export default {
  name: 'PodcastPlayer',
  data () {
    return {
      el: {},
      isVisible: false,
    }
  },
  mounted () {
    let _this = this
    eventsbus.$on('SendPodcastPlayer', function (e) {
      _this.el = JSON.parse(e)
      _this.isVisible = true
    })
  },
  computed: {
    resource: function () {
      return this.el ? 'https://widget.spreaker.com/player?playlist=false&theme=dark&episode_id=' + this.el.id : null
    },
  },
  methods: {
    close () {
      this.isVisible = false
    }
  }
}

</script>
