/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'party': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M155.115 127.06l-21.213 21.213 18.618 18.618L4.734 450.441l60.817 60.817 283.55-147.785 18.618 18.618 21.213-21.213zm96.487 138.914H134.708l40.051-76.843zM67.967 394.026H225.57L71.201 474.482 41.509 444.79zm215.163-30H83.603l35.469-68.052h162.53l45.259 45.259zM289.072 148.29c6.265 3.552 13.214 6.149 20.802 7.78-6.846 17.661-17.757 33.77-31.787 46.454l20.119 22.253c19.903-17.994 34.776-41.52 42.876-67.128 54.971-4.866 104.644-46.202 118.999-99.596l-28.971-7.789c-5.481 20.386-18.154 39.465-35.686 53.723-14.246 11.586-30.952 19.382-48.021 22.548 1.566-17.035-.556-30.934-6.478-42.464-8.826-17.185-26.394-27.577-43.722-25.851-21.026 2.091-37.738 21.965-37.254 44.304.397 18.269 11.556 35.806 29.123 45.766zm11.1-60.217c.263-.026.529-.039.798-.039 4.766 0 10.341 4.043 13.27 9.744 3.324 6.471 2.951 15.221 2.951 29.172-5.008-.979-9.46-2.567-13.321-4.757-8.167-4.631-13.764-12.797-13.928-20.32-.139-6.437 4.928-13.272 10.23-13.8zM463.949 240.422c-40.128-21.226-92.649-13.434-124.888 18.525l21.121 21.305c22.79-22.594 61.372-28.316 89.739-13.311zM441.935 321.333h30v30h-30zM336.659 0h30v30h-30zM449.657 153.81l46.081-18.284 11.07 27.9-46.081 18.284zM191.658 36h30v45.333h-30z"/>'
  }
})
