/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 16,
    height: 16,
    viewBox: '0 0 254.1 256.7',
    data: '<path pid="0" d="M232.9 120c-11.3-18.5-26.5-33.3-45.5-44.5-19.1-11.2-39.2-16.8-60.4-16.8s-41.3 5.6-60.4 16.8c-19.1 11.2-34.2 26-45.5 44.5-1.6 2.8-2.4 5.6-2.4 8.3 0 2.7.8 5.5 2.4 8.3 11.3 18.5 26.5 33.3 45.5 44.5C85.7 192.4 105.9 198 127 198c21.2 0 41.3-5.6 60.4-16.7 19.1-11.2 34.2-26 45.5-44.5 1.6-2.8 2.4-5.6 2.4-8.3.1-2.9-.7-5.7-2.4-8.5zM101.1 86.9c7.2-7.2 15.9-10.8 25.9-10.8 1.6 0 3 .6 4.1 1.7 1.1 1.1 1.7 2.5 1.7 4.1 0 1.6-.6 3-1.7 4.1-1.1 1.1-2.5 1.7-4.1 1.7-6.9 0-12.9 2.5-17.8 7.4s-7.4 10.8-7.4 17.8c0 1.6-.6 3-1.7 4.1-1.1 1.1-2.5 1.7-4.1 1.7-1.6 0-3-.6-4.1-1.7-1.1-1.1-1.7-2.5-1.7-4.1.1-10.1 3.7-18.7 10.9-26zm78.5 80.9c-16.2 9.8-33.7 14.7-52.5 14.7-18.9 0-36.4-4.9-52.5-14.7-16.2-9.8-29.6-22.9-40.3-39.5 12.2-19 27.6-33.2 46.1-42.7C75.5 94 73 103 73 112.8c0 14.9 5.3 27.7 15.9 38.3S112.1 167 127 167c14.9 0 27.7-5.3 38.3-15.9s15.9-23.3 15.9-38.3c0-9.8-2.5-18.8-7.4-27.2 18.5 9.4 33.8 23.7 46.1 42.7-10.7 16.6-24.2 29.7-40.3 39.5z"/>'
  }
})
