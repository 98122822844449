<template>
  <div class="max-w-screen-xl relative text-sm m-x-auto">
    <div class="flex items-center justify-between cursor-pointer border-b-1 border-black-200 p-y-16 p-x-24"
         @click="toggle">
      <slot name="label"></slot>
      <template v-if="open">
        <svg class="w-32 h-32 text-black-300">
          <use xlink:href="#close"></use>
        </svg>
      </template>
      <template v-else>
        <svg class="w-32 h-32 text-black-300">
          <use xlink:href="#circle-down"></use>
        </svg>
      </template>
    </div>
    <div :class="[ open ? 'max-h-screen' : 'max-h-0' ]"
         class="overflow-hidden transition-all duration-200">
      <ol class="" @click="toggle">
        <slot name="contents"></slot>
      </ol>
      <div class="flex justify-center m-y-24">
        <a class="flex items-center" href="#top">
          <svg class="w-16 h-16 text-blue-500 m-r-8">
            <use xlink:href="#arrow"></use>
          </svg>
          <span class="text-blue-500 font-bold">
          Torna all’inizio
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      open: false
    }
  },
  computed: {},
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    toggle () {
      this.open = !this.open
    },
    helloworld () {
      console.log('helloworld')
    }
  },
}
</script>
