<template>
  <div class="landing-consent-download">
    <input type="text"
           v-validate="'required|email'"
           v-model="email"
           placeholder="Email"
           name="email"
           class="property__text-box m-y-16 w-full max-w-full"
           autocomplete="off" />
    <div class="text-xs m-b-16">
      Lasciando la tua email dichiari di aver preso visione dell’<a href="https://www.lonelyplanetitalia.it/legal/privacy" target="_blank" class="underline">informativa sulla privacy</a> di EDT Srl.
    </div>
    <template v-if="consentData">
      <div v-for="(el, key) in consentData.checkbox">
        <input type="checkbox"
               :id="key"
               :value="key"
               v-model="consent" />
        <label :for="key">{{ el.label }}
          <span v-if="el.readmore"> - <a :href="el.readmore.link" target="_blank" class="underline">{{ el.readmore.label }}</a></span>
        </label>
        <div class="h-12"></div>
      </div>
    </template>

    <div class="m-t-24">
      <template v-if="!mybutton">
        <template v-if="email">
          <div @click.prevent="submit" class="lpbutton">{{ consentData.button.label }}</div>
        </template>
        <template v-else>
          <div class="lpbutton lpbutton-disabled">{{ consentData.button.label }}</div>
        </template>
        <a :href="downloadUrl" ref="downloadBtn" v-show="false"></a>
      </template>
      <template v-else>
        <div class="flex flex-row w-full justify-center items-center">
          <template v-if="email">
            <div @click.prevent="submitSlot" class="lpbutton">{{ consentData.button.label }}</div>
          </template>
          <template v-else>
            <div class="lpbutton lpbutton-disabled">{{ consentData.button.label }}</div>
          </template>
        </div>
        <transition name="fade" appear>
          <div v-if="valid" class="m-t-32">
            <div class="flex flex-col gap-y-16 w-full lg:w-10/12 m-x-auto">
              <div class="flex flex-row w-full border-1 border-black-200" v-for="(item, key) in items" :key="key">
                <div class="flex flex-row items-center gap-x-16 p-24">
                  <img :src="item.picture"
                       class="hidden md:block md:w-3/12 rounded-8 shadow-lg">
                  <div class="flex-1">
                    <h5 class="text-base md:text-lg leading-snug text-left lg:text-left">
                      {{ item.title }}
                    </h5>
                    <p class="text-sm hidden md:block text-black-400">
                      {{ item.info }}
                    </p>
                  </div>
                </div>
                <a @click="downloadItem(item)"
                   class="flex-1 bg-blue-500 p-8 md:p-16 block flex flex-col items-center justify-center"
                   target="_blank">
                  <div class="lpbutton text-center block">
                    <span>SCARICA</span>
                    <svg class="fill-current h-32 w-32 m-x-auto m-t-4">
                      <use xlink:href="#download"></use>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </transition>
      </template>
    </div>

    <loading :active.sync="this.isLoading"
             :can-cancel="this.canCancel"
             :is-full-page="this.isFullPage"
             color="#297cbb"></loading>

    <generic-modal-dialog :message="popup_message"
                          title="Iscrizione alla Newsletter"
                          :show="showPopup"></generic-modal-dialog>
  </div>

</template>

<script>

import { eventsbus } from '../mixins/EventsBus.js'

export default {

  inherit: true,
  name: 'LandingConsent',
  props: {
    consentData: {
      type: Object,
      required: true,
    },
    mybutton: false,
    items: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      isLoading: false,
      isFullPage: false,
      canCancel: false,
      email: '',
      consent: [],
      downloadUrl: '',
      showPopup: false,
      popup_message: '',
      valid: false,
      token: null,
      hrefUrl: null,
    }
  },
  mounted () {
    eventsbus.$on('generic-modal-closed', function () {
      this.showPopup = false
    }.bind(this))
  },
  methods: {
    submit: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoading = true
          //Call CONSENT
          axios.post('/api/speciale/landing-consent', {
            email: this.email,
            consent: JSON.stringify(this.consent),
            code: this.consentData.key,
          }).then((response) => {
            this.isLoading = false
            if (response.data.error === false) {
              this.downloadUrl = this.consentData.target + '?token=' + response.data.token
              this.clickFunction()
              this.isLoading = false
            }
          }).catch((err) => {
          })
        }
      })
    },
    submitSlot: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoading = true
          axios.post('/api/speciale/landing-consent', {
            email: this.email,
            consent: JSON.stringify(this.consent),
            code: this.consentData.key,
          }).then((response) => {
            this.isLoading = false
            if (response.data.error === false) {
              this.token = response.data.token
              this.valid = true
            }
          }).catch((err) => {
          })
        }
      })
    },
    downloadItem: function (item) {
      window.open(item.route + '?token=' + this.token, '_blank')
    },
    clickFunction: function () {
      this.$nextTick(() => {
        this.$refs.downloadBtn.click()
      })
    }
  },
}

</script>
