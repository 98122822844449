/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mask': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M0 0v232.406C0 358.796 83.316 471.5 206.766 506.77l18.3 5.23 18.301-5.23c121.274-34.649 206.766-145.727 206.766-274.364V0zm317.234 30.008l-32.152 48.765-32.152-48.765zm27.868 12.254l32.152 48.765h-64.305zm-87.883 48.765h-64.305l32.152-48.765zm-60.02-61.02l-32.152 48.766-32.152-48.765zm-60.015 61.02H72.879l32.152-48.765zM30.008 30.007h47.156l-40.23 61.02h-6.926zm0 202.4V121.034h175.164L170.879 241.07H30.168c-.098-2.879-.16-5.765-.16-8.664zm195.058-71.759l42.168 147.594-42.168 21.082-42.171-21.082zm0 320.141c-5.953-2.121-39.578-8.695-78.742-34.266 22.852-16.48 50.219-25.402 78.742-25.402 28.524 0 55.891 8.922 78.739 25.402-39.36 25.704-72.942 32.2-78.739 34.266zM410.61 301.086h-77.511v30h67.004a257.524 257.524 0 01-15.032 30.02h-51.972v30h31.425a257.968 257.968 0 01-35.691 36.8c-29.336-23.808-65.707-36.793-103.766-36.793-38.062 0-74.43 12.985-103.77 36.793a257.919 257.919 0 01-35.69-36.793h34.43v-30.008H65.062a257.362 257.362 0 01-15.032-30.007h70.004v-30.012h-79.95v2a253.904 253.904 0 01-7.093-32.008h129.313l-15.106 52.863 77.867 38.934 77.864-38.934-15.102-52.87h129.309a253.858 253.858 0 01-6.528 30.015zm9.512-68.68c0 2.899-.062 5.785-.16 8.664H279.254l-34.297-120.035h175.164zm0-141.379h-6.926l-40.23-61.02h47.156zm0 0"/><path pid="1" d="M49.41 191.664c19.492 19.492 37.555 29.238 55.621 29.238 18.063 0 36.13-9.746 55.621-29.238l10.614-10.613-10.614-10.61c-38.988-38.984-72.254-38.984-111.242 0l-10.613 10.61zm78.328-10.613c-16.61 13.187-28.804 13.187-45.418 0 16.614-13.184 28.809-13.184 45.418 0zm0 0M289.477 170.441l-10.61 10.61 10.61 10.613c19.496 19.492 37.558 29.238 55.625 29.238 18.062 0 36.128-9.746 55.62-29.238l10.61-10.613-10.61-10.61c-38.988-38.984-72.257-38.984-111.245 0zm32.914 10.61c16.613-13.184 28.804-13.184 45.418 0-16.614 13.187-28.805 13.187-45.418 0zm0 0"/>'
  }
})
