/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-share-whatsapp': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" _fill="#20B038" d="M440.5 180.2c-5-11.9-11.2-23.2-18.4-33.9-7.2-11-15.5-21.2-25-30.6-9.4-9.4-19.6-17.7-30.6-25-10.7-7.2-22-13.3-33.9-18.4-11.9-5-24.2-8.8-36.7-11.3-12.9-2.5-25.9-3.8-39.1-3.8-27.3 0-53.1 5.2-77.2 15.5-24.2 10.4-45.2 24.5-63.1 42.4S84.3 154 73.6 178.2C63.2 202.5 58 228.4 58 256.1c0 18.8 2.6 37.1 7.8 54.9 5.2 17.7 12.6 34.6 22.4 50.6l4.7 7.5-19.8 73.5 75.4-19.8 7.5 3.8c15.1 9.4 31.2 16.5 48.3 21.2 17.1 4.7 34.6 7.1 52.5 7.1 27.3 0 53.1-5.2 77.2-15.5 24.2-10.7 45.2-25 63.1-42.9s32.2-38.9 42.9-63.1c10.4-24.2 15.5-49.9 15.5-77.2 0-13.2-1.3-26.2-3.8-39.1-2.4-12.7-6.2-24.9-11.2-36.9zM378.3 323c-.3 5.3-1.9 11.3-4.7 17.9-2.5 7.2-8.4 13.7-17.7 19.3-9.3 5.7-16.9 9-22.8 9.9-5.3.3-11.1.5-17.2.5s-13-1.1-20.5-3.3c-4.4-1.6-9.4-3.3-15.1-5.2-5.7-1.9-11.9-4.2-18.8-7.1-14.8-6.6-28.3-14.9-40.5-25-12.2-9.7-22.8-19.3-31.6-28.7-8.8-9.4-15.7-17.7-20.7-25-5.3-6.9-8.3-10.8-8.9-11.8-1.6-1.9-6-8.9-13.4-21.2-7.4-12.2-11.1-25.9-11.1-41 0-14.8 2.7-26 8.2-33.7 5.5-7.7 9.7-13.1 12.5-16.3 2.8-2.8 5.7-4.6 8.7-5.4 3-.8 5.4-1.2 7.3-1.2h11.3c1.9 0 4 .2 6.4.7 2.4.5 4.9 3.7 7.8 9.7 2.5 5.7 6 14.1 10.6 25.4 4.5 11.3 7 17.6 7.3 18.8.9 1.6 1.6 3.2 1.9 4.9.3 1.7 0 3.5-.9 5.4-.9 1.9-1.8 3.6-2.6 5.2s-2.1 3-4 4.2c-1.6 1.9-3.1 3.8-4.5 5.9-1.4 2-2.8 3.5-4 4.5-1.6 1.9-2.9 3.7-4 5.4-1.1 1.7-.7 4 1.2 6.8 1.9 3.5 5.7 9.3 11.5 17.7 5.8 8.3 13 16.3 21.4 23.8 11.9 10.4 22.2 17.7 30.9 21.9 8.6 4.2 14.4 7 17.2 8.2 3.5 1.3 6 1.7 7.8 1.4 1.7-.3 3.5-1.4 5.4-3.3 1.9-1.9 5-5.5 9.4-10.8 4.4-5.3 7.5-9.6 9.4-12.7 1.9-2.8 4-4.2 6.4-4.2 2.4 0 4.6.5 6.8 1.4 2.8.9 9.7 4.2 20.5 9.7 10.8 5.5 17.8 8.9 21 10.1 2.8 1.6 5.2 2.8 7.1 3.8 1.9.9 3.3 1.9 4.2 2.8.8 1.8 1.1 5.2.8 10.6z"/>'
  }
})
