/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ui': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<circle pid="0" cx="255.998" cy="256" r="255.998" _fill="#55ace5"/><path pid="1" d="M512 256c0 51.743-15.349 99.913-41.754 140.183-11.358 4.138-23.625 6.384-36.414 6.384H95.433c-21.368 0-41.472-5.402-59.026-14.931C13.284 349.173.003 304.138.003 256c0-22.758 2.967-44.826 8.547-65.828 22.371-22.005 53.039-35.558 86.883-35.558 1.578 0 3.156.042 4.723.105 26.916-65.054 91-110.832 165.783-110.832 87.499 0 160.359 62.683 176.148 145.585 26.321 2.017 49.977 13.584 67.5 31.253A258.448 258.448 0 01512 256z" _fill="#f2f2f2"/><path pid="2" d="M149.736 384.846h25.736c8.12 0 14.702-6.582 14.702-14.702V251.129c0-9.158 6.424-17.112 15.405-18.908 16.301-3.259 33.164-4.965 50.423-4.965s34.122 1.706 50.423 4.965c8.981 1.795 15.405 9.75 15.405 18.908v119.016c0 8.12 6.582 14.702 14.702 14.702h25.736c7.878 0 11.713 9.634 5.998 15.046l-98.523 93.246c-7.711 7.304-19.769 7.304-27.481 0l-98.523-93.246c-5.716-5.412-1.881-15.047 5.997-15.047z" _fill="#f14881"/>'
  }
})
