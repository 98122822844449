/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'best-in-travel/2019/kathmandu': {
    width: 16,
    height: 16,
    viewBox: '0 0 150 150',
    data: '<path pid="0" d="M41.3 32.4L38.4 34l2.9 1.6 1.6 3 1.6-3 3-1.6-3-1.6-1.6-3zM122.7 74.8l-1.1 2-2 1.1 2 1.1 1.1 2 1.1-2 2-1.1-2-1.1zM110.7 84.7c-4.2-10.1-12-18-22.1-22.2-2.3-.9-4.6-1.7-6.9-2.2v-6.6H64v6.6c-2.3.5-4.5 1.2-6.7 2.1C47.2 66.6 39.1 74.7 35 84.7l-.8 1.9h77.3l-.8-1.9zm-44-28.2H79v3.3c-4.1-.6-8.2-.6-12.3 0v-3.3zM38.4 83.9c4-8.5 11.2-15.3 19.9-18.9 9.4-3.8 19.8-3.8 29.2.1 8.8 3.6 15.8 10.3 19.8 18.8H38.4zM29.8 89.3h86.1v2.8H29.8zM24 94.4h97.6v2.8H24z"/><path pid="1" d="M136.2 63c-5.2-25.6-25-45.4-50.6-50.6l-.5 2.7c20.5 4.1 37.1 18 44.8 36.9-17.7-.4-34.9-7.1-48.2-18.6-1-.9-2-1.8-3-2.8V16.5h-4.4V5h-2.8v11.5H67v14C53.3 43.7 34.8 51.4 15.8 51.9c1.5-3.6 3.3-7 5.5-10.4 9.1-13.8 23.1-23.1 39.3-26.4l-.5-2.7C43.1 15.8 28.5 25.6 19 40S6.1 71.6 9.5 88.5c6.1 30.7 33.2 51.9 63.3 51.9 4.3 0 8.5-.4 12.8-1.3 17-3.4 31.5-13.2 41.1-27.6 9.5-14.3 12.9-31.6 9.5-48.5zm-5.2-8.3c.5 1.5 1 2.9 1.4 4.5-8.4-1.7-16.5-4.5-24.1-8.1 7.3 2.3 14.9 3.5 22.7 3.6zm2.1 7.3c.1.6.2 1.1.3 1.6.3 1.7.6 3.3.8 5-9.9-4.4-19.3-9.6-28.1-15.6 8.5 4.1 17.6 7.1 27 9zM69.7 19.2h6.2V29h-6.2v-9.8zm8.9 30.1H67.1l4.1-17.5h3.3l4.1 17.5zm-64 5.4c7.9-.1 15.6-1.3 22.9-3.6-7.7 3.6-15.8 6.4-24.3 8.1.4-1.5.9-3 1.4-4.5zm-2.1 7.4c9.2-1.8 18-4.7 26.4-8.6-8.7 5.7-17.9 10.8-27.5 15.1.2-2.2.6-4.3 1.1-6.5zm4.9 41h46.4v6.2H20.9c-1.3-2-2.5-4.1-3.5-6.2zm5.4 9h38.5l-21.1 16.2c-6.8-4.2-12.7-9.7-17.4-16.2zm19.9 17.7l19.6-15.1-7.8 20.2c-4.1-1.3-8.1-3-11.8-5.1zm42.3 6.7c-4.1.8-8.2 1.2-12.2 1.2-5.3 0-10.6-.7-15.6-2l9.2-23.6h13.1l9.2 23.6c-1.3.3-2.5.6-3.7.8zm6.1-1.6l-7.8-20.2 19.7 15.1c-3.7 2.1-7.7 3.8-11.9 5.1zm14.4-6.5l-21.2-16.3h38.6c-4.7 6.6-10.6 12.1-17.4 16.3zm19.3-19.1H81.9v-6.2h46.4c-1.1 2.1-2.2 4.2-3.5 6.2zm4.7-8.9H79.2v8.9H66.5v-8.9H16.1c-1.7-3.9-3-8.1-3.9-12.4-1.1-5.4-1.5-10.9-1.1-16.3C32.4 62.4 51.5 49.5 68 33.4l-3.7 15.9h-3.8v2.8h25v-2.8h-3.9l-3.7-15.8c16.5 16 35.6 28.9 56.7 38.2.6 9.9-1.1 19.7-5.1 28.7z"/>'
  }
})
