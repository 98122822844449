<template>
  <l-map ref="map"
         :options="mapOptions"
         :zoom.sync="zooming">
    <l-tile-layer :url="url" :attribution="attribution" ref="layer"/>
    <l-feature-group ref="features">
      <l-marker v-for="(marker, index) in JSON.parse(markers)"
                :lat-lng="mapMarker(marker)"
                :key="index"
                @add="selfOpen($event, marker)" @click="clickOnMarker($event, marker)">
        <l-icon
          :icon-url="markerUrl(marker, index)"
          :icon-size="[28, 28]"
          :icon-anchor="[14, 14]"/>
        <template v-if="letClickOnMarkers">
          <l-popup :options="{ autoClose: autoClose, closeOnClick: false }">
            <leaflet-dynamic-map-card-template :el="marker"></leaflet-dynamic-map-card-template>
          </l-popup>
        </template>
      </l-marker>
    </l-feature-group>
  </l-map>
</template>

<script>
import { latLng } from 'leaflet'
import { LFeatureGroup, LIcon, LMap, LMarker, LPopup, LTileLayer } from 'vue2-leaflet'
import LeafletDynamicMapCardTemplate from '../templates/LeafletDynamicMapCardTemplate'

export default {
  name: 'LeafletDynamicMap',
  props: {
    markers: {
      required: true,
      type: String
    },
    zoom: {
      default: null
    },
    autoshow: {
      default: false
    },
    dragging: {
      default: true
    },
    zoomControl: {
      default: true
    },
    letClickOnMarkers: {
      default: false
    }

  },
  components: {
    LeafletDynamicMapCardTemplate,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LFeatureGroup,
    LPopup
  },
  data () {
    return {
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false,
        worldCopyJump: true,
        dragging: this.dragging,
        zoomControl: this.zoomControl
      },
      zooming: 2,
      ModalLazyLoad: false
    }
  },
  watch: {},
  mounted () {
    this.$nextTick(() => {
      this.initialZoom()
    })
  },
  computed: {
    autoClose: function () {
      return !!(this.letClickOnMarkers)
    }
  },
  methods: {
    async initialZoom () {
      let map = this.$refs.map,
        bounds = this.$refs.features.mapObject.getBounds()
      map.fitBounds(bounds)
      if (this.zoom) {
        map.mapObject.setZoom(Number(this.zoom))
      }
    },
    mapMarker (marker) {
      return marker.coordinates.latitude && marker.coordinates.longitude ? latLng(this.latCalc(marker.coordinates.latitude), this.lngCalc(marker.coordinates.longitude)) : null
    },
    latCalc (el) {
      let lat = this.numberize(el, 8)
      return lat + (Math.sign(lat) < 0 ? -.01 : .01)
    },
    lngCalc (el) {
      let lng = this.numberize(el, 8)
      return lng + (Math.sign(lng) < 0 ? -.025 : .025)
    },
    markerUrl (marker, index) {
      let mark = marker.icon === 'number' ? 'numbers/marker-number-' + (index + 1) : marker.icon
      return '/vendor/markers/' + mark + '.png'
    },
    selfOpen (event, marker) {
      if (this.autoshow && marker.autoshow) {
        this.$nextTick(function () {
          event.target.openPopup()
        })
      }
    },
    clickOnMarker (event, marker) {
      if (this.letClickOnMarkers) {
        return this.$nextTick(function () {
          event.target.openPopup()
        })
      }
      return false
    },
  }
}
</script>
