<template>
  <div class="accordion">
    <div class="accordion__wrapper">
      <div class="accordion__item" :class="{ 'accordion__item--collapsed': !show }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

  import {eventsbus} from '../mixins/EventsBus.js'

  export default {

    name: 'collapsibleTarget',
    props: {
      showed: {
        default: true,
      },
      refs: {
        default: null,
      },
    },
    data() {
      return {
        show: this.showed,
      }
    },
    mounted() {
      eventsbus.$on('collapsible', function(e) {
        if (e === this.refs) {
          this.toggle()
        }
      }.bind(this))
    },
    methods: {
      toggle() {
        this.show = !this.show
      },
    },
  }

</script>
