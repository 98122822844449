/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gallery': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M446.44 416.44V65.56H95.56V0h-30v65.56H0v30h65.56v350.88h350.88V512h30v-65.56H512v-30h-65.56zM95.56 95.56h320.88v209.573l-53.14-40.356-61.382 46.542-102.038-87.752L95.56 300.82V95.56zm320.881 320.881H95.56V338.15l102.923-76.218 102.251 87.936 62.554-47.431 53.153 40.367v73.637z"/><path pid="1" d="M301.837 130.248c-25.411 0-46.085 20.674-46.085 46.086 0 25.411 20.674 46.085 46.085 46.085 25.412 0 46.086-20.674 46.086-46.085 0-25.412-20.674-46.086-46.086-46.086zm0 62.171c-8.869 0-16.085-7.216-16.085-16.085 0-8.87 7.216-16.086 16.085-16.086 8.87 0 16.086 7.216 16.086 16.086 0 8.869-7.216 16.085-16.086 16.085z"/>'
  }
})
