<template>
  <div class="list list--articles">
    <transition-group name="list" tag="div" class="list__wrapper">
      <div class="list__item" v-for="article in articles" :key="article.id">
        <a class="list__item__container row" :href="article.route">
          <div class="list__item__info column">
            <div class="list__item__eyebrow">
              <div v-if="article.advertorial" class="list__item__sponsored-logo">
                <span>{{ article.advertorial.label }}</span>
                <img :src="article.advertorial.logo" :alt="article.advertorial.name" />
              </div>
              <span v-else>{{ article.inspiration.label }}</span>
            </div>
            <h3 class="list__item__title">
              {{ article.title }}
            </h3>
            <p class="list__item__description" :inner-html.prop="article.description | truncate(250, '...')"></p>
            <div class="list__item__additionals-infos">{{ article.publish_date }}</div>
          </div>
          <div class="list__item__image column" v-if="article.picture && Object.keys(article.picture).length !== 0">
            <figure :style="{ 'background-image': 'url(' + article.picture.image + ')' }"></figure>
          </div>
        </a>
      </div>
    </transition-group>
    <div class="d-flex justify-content-center" v-if="pagination.isPaginated && (pagination.currentPage < pagination.lastPage) && api_url">
      <a @click="nextSet()" class="button button--link button--more">
        Leggi altri articoli
        <svgicon name="chevron-down" class="icon icon--white"></svgicon>
      </a>
    </div>
  </div>
</template>

<script>

  export default {

    inherit: true,
    name: 'ArticleList',
    props: {
      els: {
        required: true
      },
      api_url: {
        type: String,
      }
    },
    data() {
      return {
        loading: false,
        articles: this.els.data ? Object.values(this.els.data) : this.els,
        pagination: {
          isPaginated: !!(!!this.els.data && this.els.last_page > 1),
          currentPage: this.els.current_page ? this.els.current_page : null,
          lastPage: this.els.last_page ? this.els.last_page : null,
          requestPage: this.els.current_page ? 1 : null
        },
        helpers: [],
      }
    },
    methods: {
      fetchData() {
        this.loading = true
        let _this = this
        setTimeout(() => {
            axios.get(_this.api_url + '?page=' + _this.pagination.requestPage)
              .then((response) => {
                for (let i = 0; i < response.data.data.length; i++) {
                  _this.articles.push(response.data.data[i])
                }
                _this.pagination = {
                  isPaginated: !!(response.data.current_page < response.data.last_page),
                  currentPage: response.data.current_page,
                  lastPage: response.data.last_page,
                  requestPage: response.data.current_page,
                }
                _this.updateParams()
                _this.loading = false
              })
              .catch((error) => {
                console.log(error)
              })
          },
          200
        )
      },
      updateParams() {
        this.updatePageTracking()
      },
      nextSet() {
        this.pagination.currentPage < this.pagination.lastPage ? this.pagination.requestPage++ : null
        this.fetchData()
      },
      prevSet() {
        this.pagination.currentPage > 1 ? this.pagination.requestPage-- : null
        this.fetchData()
      }
    },
  }

</script>
