<template>
  <div class="block h-full">
    <div class="flex flex-col flex-wrap h-full">
      <vueper-slides ref="StoriesSlider" :arrows="false" :breakpoints="breakpoints" :bullets="false"
        :bulletsOutside="false" :gap="gap" :parallax="false" :touchable="false" :visible-slides="4" :infinite="false"
        class="h-full" slide-multiple disable-arrows-on-edges @next="logEvents('next', $event)"
        @previous="logEvents('previous', $event)">
        <!-- Article image cards -->
        <template v-if="this.type === 'article'">
          <vueper-slide v-for="(section, i) in els" :key="i">
            <template #content>
              <div class="flex flex-col flex-wrap h-full justify-between">
                <article v-for="(el, k) in section" :key="el.id"
                  class="relative flex items-center rounded-16 p-16 hover:shadow-md focus:shadow-md bg-blue-100 border-1 border-blue-200 lg:h-144 relative w-full cursor-pointer"
                  @click="linkToUrl(el.link)">
                  <div class="flex-none m-r-16 rounded-16 overflow-hidden w-2/12 max-w-96">
                    <template v-if="el.media">
                      <div class="h-full relative w-full aspect-ratio-w-1 aspect-ratio-h-1">
                        <img class="absolute w-full h-full top-0 left-0 object-cover rounded-16 lazyload"
                          :data-src="el.media.src" :alt="el.media.caption" width="140" height="140" />
                      </div>
                    </template>
                  </div>
                  <div class="flex-auto w-0">
                    <p class="relative text-xs font-semibold text-black-400 uppercase">
                      <span>{{ el.frameless }}</span>
                    </p>
                    <h4 class="relative text-base md:text-lg font-display leading-tight font-semibold line-clamp-2 m-y-8">
                      <a :href="el.link" class="block">
                        {{ el.title }}
                      </a>
                    </h4>
                    <p class="text-xs text-black-400">
                      {{ el.publishdate }} • {{ el.reading }} lettura
                    </p>
                  </div>
                  <!-- <a class="block" :href="el.link">
                    <template v-if="el.media">
                      <img class="max-w-full max-h-192 lg:max-h-auto object-cover rounded-16 lazyload"
                        :data-src="el.media.src" :alt="el.media.caption" width="500" height="500" />
                    </template>
                    <p class="relative m-t-16 text-xs md:text-sm text-black-400 font-semibold z-10">
                      {{ el.frameless }}</p>
                    <h4 class="relative text-base md:text-lg font-display leading-tight font-semibold line-clamp-3 m-y-8">
                      {{ el.title }}</h4>
                    <p class="hidden lg:block relative m-t-12 lg:line-clamp-4 text-base text-black">
                      {{ el.content }}
                    </p>
                  </a> -->
                </article>
              </div>
            </template>
          </vueper-slide>
        </template>
        <template v-else>
          <vueper-slide v-for="(el, i) in els" :key="el.id">
            <template #content>
              <!-- Pictorial (DST) image cards -->
              <template v-if="type === 'pictorial'">
                <article class="relative overflow-hidden flex-none w-full cursor-pointer">
                  <template v-if="el.media">
                    <a :href="el.link" class="relative block">
                      <img class="max-w-full object-cover rounded-16 z-10 bg-black-100 aspect-w-1 aspect-h-1 lazyload"
                        :data-src="el.media.src" :alt="el.media.caption" width="600" height="600">
                    </a>
                  </template>
                  <template v-else>
                    <a :href="el.link" class="relative block">
                      <div class="w-full aspect-ratio-w-1 aspect-ratio-h-1 rounded-16 overflow-hidden z-10 bg-black-200">
                        <div class="w-full h-full flex flex-col items-center justify-center">
                          <svg class="w-64 h-64 text-black-300">
                            <use xlink:href="#place:cityspace"></use>
                          </svg>
                        </div>
                      </div>
                    </a>
                  </template>
                  <div class="m-t-24">
                    <h3 class="text-xl font-semibold line-clamp-2 w-10/12 md:w-11/12">
                      <a :href="el.link">
                        {{ el.name }}
                      </a>
                    </h3>
                    <template v-if="el.place || el.parent">
                      <p class="uppercase text-sm font-semibold text-black-400 line-clamp-2 overflow-hidden m-t-8">
                        <span v-if="el.place">{{ el.place.name }}</span>
                        <span v-if="el.parent">{{ el.parent.name }}</span>
                      </p>
                    </template>
                    <template v-if="el.content">
                      <p class="relative line-clamp-3 m-t-8">
                        {{ striptags(el.content) }}
                      </p>
                    </template>
                  </div>
                  <!-- <div
                  class="absolute top-0 left-0 w-full h-full z-20 flex flex-col justify-end items-center p-y-32">
                  <a :href="el.link" class="w-10/12 m-x-auto text-center text-white">
                    <h3 class="text-2xl font-semibold">{{ el.name }}</h3>
                    <template v-if="el.thingtodo && el.place">
                      <p class="text-sm whitespace-nowrap line-clamp-2 overflow-hidden">{{ el.place.name }}</p>
                    </template>
                  </a>
                </div> -->
                </article>
              </template>
              <!-- Toolkit cards -->
              <template v-else-if="type === 'planning'">
                <article class="relative h-320 flex-none p-24 border-1 bg-yellow-100 border-black-200 rounded-8">
                  <a class="flex flex-col justify-between cursor-pointer h-full" :href="el.link">
                    <svg class="w-48 h-48 text-black-400">
                      <use :xlink:href="el.icon"></use>
                    </svg>
                    <div class="block relative">
                      <p class="relative text-xl font-semibold z-10 tracking-tight leading-tight">{{ el.title }}</p>
                      <template v-if="el.content">
                        <p class="relative m-y-16 text-sm text-black-400 line-clamp-3 z-10">
                          {{ el.content }}
                        </p>
                      </template>
                      <a class="link cursor-pointer">
                        <span class="flex items-center">
                          Leggi tutto
                          <svg class="w-12 h-12 m-l-6 text-black-300 transform rotate-90 m-t-1">
                            <use xlink:href="#arrow"></use>
                          </svg>
                        </span>
                      </a>
                    </div>
                  </a>
                </article>
              </template>
            </template>
          </vueper-slide>
        </template>
      </vueper-slides>
      <div class="items-center justify-center lg:justify-end m-t-16"
        :class="[this.type === 'article' ? 'hidden lg:flex' : 'flex']">
        <div class="flex items-center">
          <template v-if="label">
            <a class="cta cta--trans inline m-r-24" :href="cta" target="_self">
              {{ label }}
            </a>
          </template>
          <button @click="$refs.StoriesSlider.previous()" class="text-black-300 hover:text-black-400 m-r-8"
            aria-label="previous slide">
            <svg width="40" height="40" viewBox="0 0 40 40" class="fill-current" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 1.25C16.2916 1.25 12.6665 2.34967 9.58307 4.40994C6.49964 6.47022 4.09641 9.39857 2.67727 12.8247C1.25812 16.2508 0.886812 20.0208 1.61028 23.6579C2.33376 27.2951 4.11952 30.636 6.74176 33.2583C9.36399 35.8805 12.7049 37.6663 16.3421 38.3897C19.9792 39.1132 23.7492 38.7419 27.1753 37.3227C30.6014 35.9036 33.5298 33.5004 35.5901 30.4169C37.6503 27.3335 38.75 23.7084 38.75 20C38.75 15.0272 36.7746 10.2581 33.2583 6.74175C29.742 3.22544 24.9728 1.25 20 1.25ZM20 36.25C16.7861 36.25 13.6443 35.297 10.972 33.5114C8.29969 31.7258 6.21689 29.1879 4.98697 26.2186C3.75704 23.2493 3.43524 19.982 4.06225 16.8298C4.68926 13.6776 6.23692 10.7821 8.50952 8.50951C10.7821 6.23691 13.6776 4.68925 16.8298 4.06224C19.982 3.43523 23.2493 3.75703 26.2186 4.98696C29.1879 6.21688 31.7258 8.29969 33.5114 10.972C35.297 13.6443 36.25 16.7861 36.25 20C36.2434 24.3077 34.5292 28.4371 31.4832 31.4832C28.4372 34.5292 24.3077 36.2434 20 36.25Z">
              </path>
              <path
                d="M23.3994 12.7501C23.2832 12.6329 23.145 12.5399 22.9926 12.4764C22.8403 12.413 22.6769 12.3803 22.5119 12.3803C22.3469 12.3803 22.1835 12.413 22.0312 12.4764C21.8789 12.5399 21.7406 12.6329 21.6244 12.7501L14.8494 19.1001C14.6113 19.339 14.4775 19.6627 14.4775 20.0001C14.4775 20.3374 14.6113 20.6611 14.8494 20.9001L21.6244 27.3001C21.7353 27.4125 21.8677 27.5015 22.0138 27.5616C22.1598 27.6218 22.3165 27.6518 22.4744 27.6501C22.6481 27.6528 22.8205 27.6185 22.98 27.5496C23.1394 27.4806 23.2824 27.3785 23.3994 27.2501C23.5158 27.136 23.6083 26.9999 23.6714 26.8496C23.7346 26.6994 23.7671 26.538 23.7671 26.3751C23.7671 26.2121 23.7346 26.0508 23.6714 25.9005C23.6083 25.7503 23.5158 25.6141 23.3994 25.5001L17.4994 20.0001L23.3494 14.5001C23.4691 14.3894 23.5656 14.2559 23.633 14.1074C23.7005 13.959 23.7377 13.7986 23.7423 13.6356C23.747 13.4726 23.719 13.3103 23.6602 13.1582C23.6013 13.0062 23.5126 12.8674 23.3994 12.7501Z">
              </path>
            </svg>
          </button>
          <button @click="$refs.StoriesSlider.next()" class="text-black-300 hover:text-black-400" aria-label="next slide">
            <svg width="40" height="40" viewBox="0 0 40 40" class="fill-current" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 38.75C23.7084 38.75 27.3335 37.6503 30.4169 35.5901C33.5004 33.5298 35.9036 30.6014 37.3227 27.1753C38.7419 23.7492 39.1132 19.9792 38.3897 16.3421C37.6663 12.7049 35.8805 9.36399 33.2583 6.74176C30.636 4.11952 27.2951 2.33376 23.6579 1.61028C20.0208 0.886812 16.2508 1.25812 12.8247 2.67727C9.39857 4.09641 6.47022 6.49964 4.40994 9.58307C2.34967 12.6665 1.25 16.2916 1.25 20C1.25 24.9728 3.22544 29.742 6.74175 33.2583C10.2581 36.7746 15.0272 38.75 20 38.75ZM20 3.75001C23.2139 3.75001 26.3557 4.70305 29.028 6.48863C31.7003 8.2742 33.7831 10.8121 35.013 13.7814C36.243 16.7507 36.5648 20.018 35.9378 23.1702C35.3107 26.3224 33.7631 29.2179 31.4905 31.4905C29.2179 33.7631 26.3224 35.3108 23.1702 35.9378C20.018 36.5648 16.7507 36.243 13.7814 35.013C10.8121 33.7831 8.27419 31.7003 6.48862 29.028C4.70305 26.3557 3.75 23.214 3.75 20C3.75661 15.6923 5.47078 11.5629 8.51682 8.51683C11.5629 5.47079 15.6923 3.75662 20 3.75001Z">
              </path>
              <path
                d="M16.6006 27.25C16.7176 27.3785 16.8606 27.4806 17.0201 27.5496C17.1795 27.6185 17.3519 27.6528 17.5256 27.65C17.6835 27.6518 17.8402 27.6217 17.9862 27.5616C18.1323 27.5015 18.2647 27.4125 18.3756 27.3L25.1506 20.9C25.3887 20.6611 25.5225 20.3374 25.5225 20C25.5225 19.6627 25.3887 19.339 25.1506 19.1L18.3756 12.7C18.2594 12.5829 18.1211 12.4899 17.9688 12.4264C17.8165 12.363 17.6531 12.3303 17.4881 12.3303C17.3231 12.3303 17.1597 12.363 17.0074 12.4264C16.855 12.4899 16.7168 12.5829 16.6006 12.7C16.4842 12.8141 16.3917 12.9502 16.3286 13.1005C16.2654 13.2507 16.2329 13.4121 16.2329 13.575C16.2329 13.738 16.2654 13.8993 16.3286 14.0496C16.3917 14.1998 16.4842 14.336 16.6006 14.45L22.5006 20L16.6506 25.5C16.5309 25.6107 16.4344 25.7442 16.367 25.8927C16.2995 26.0411 16.2624 26.2015 16.2577 26.3645C16.253 26.5275 16.281 26.6898 16.3399 26.8419C16.3987 26.9939 16.4874 27.1327 16.6006 27.25Z">
              </path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { VueperSlide, VueperSlides } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'CarouselModule',
  props: {
    items: {
      required: true,
    },
    type: {
      required: true
    },
    cta: {
      required: false,
    },
    label: {
      required: false
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  computed: {
    breakpoints: function () {
      if (this.type === 'article') {
        return {
          562: {
            fixedHeight: '560px',
            visibleSlides: 1
          },
          991: {
            fixedHeight: '600px',
            visibleSlides: 1
          },
          9999: {
            fixedHeight: '640px',
            visibleSlides: 1,
          }
        }
      }
      if (this.type === 'pictorial') {
        return {
          562: {
            fixedHeight: '600px',
            visibleSlides: 1
          },
          991: {
            fixedHeight: '600px',
            visibleSlides: 2
          },
          1130: {
            fixedHeight: '600px',
            visibleSlides: 1
          },
          9999: {
            fixedHeight: '580px',
            visibleSlides: 3,
            gap: 2
          }
        }
      }
      if (this.type === 'planning') {
        return {
          991: {
            fixedHeight: '320px',
            visibleSlides: 2
          },
          1130: {
            fixedHeight: '320px',
            visibleSlides: 3
          },
          9999: {
            fixedHeight: '320px',
            visibleSlides: 4
          }
        }
      }
      return {
      }
    },
    gap: function () {
      if (this.type === 'article') return 6
      if (this.type === 'pictorial') return 3
      return 2
    },
    els: function () {
      if (this.type === 'article') return _.chunk(this.items, 4)
      else return this.items
    },
    articlesHack: function () {
      return this.type === 'article'
    }
  },
  methods: {
    linkToUrl: function (string) {
      return window.location.href = string
    }
  },
  components: { VueperSlides, VueperSlide },
}

</script>
