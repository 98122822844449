/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logout': {
    width: 16,
    height: 16,
    viewBox: '0 0 385 385',
    data: '<path pid="0" d="M192.5 342.7c0 5.5 4.5 10 10 10h140.2c5.5 0 10-4.5 10-10V42.3c0-5.5-4.5-10-10-10H202.5c-5.5 0-10 4.5-10 10s4.5 10 10 10h130.2v280.4H202.5c-5.5 0-10 4.5-10 10z"/><path pid="1" d="M35.2 199.8l69.1 70.1c3.9 4 10.2 4 14.2 0 3.9-3.9 3.9-10.3 0-14.3l-52.1-52.8h206.2c5.5 0 10-4.5 10-10.1s-4.5-10.1-10-10.1H66.3l52.1-52.8c3.9-3.9 3.9-10.4 0-14.3-3.9-3.9-10.2-4-14.2 0l-69.1 70.1c-3.8 3.8-3.8 10.3.1 14.2z"/>'
  }
})
