<template>
  <div class="position-relative width-full height-0 overflow-hidden shadow-md rounded-4 padding-bottom-ratio-1/1 desktop:padding-bottom-ratio-16/9">
    <template v-if="hasHoverSlot">
      <div class="position-absolute inset-0 z-50">
        <slot name="hover"></slot>
      </div>
    </template>
    <div v-for="(item, key, index) in data"
         class="position-absolute inset-0 transition-all ease-in-out duration-2500 opacity-0"
         :class="{ 'opacity-100': key===currentIndex }">
      <img :data-src="item.src"
           :key="key"
           :id="index"
           :class="{ 'opacity-100': key===currentIndex }"
           class="object-cover width-full height-full rounded-4 shadow-md lazy"
           :alt="[item.caption, item.credits].filter(Boolean).join(' ')"
      />
    </div>
  </div>
</template>

<script>

import LazyLoad from 'vanilla-lazyload'

export default {
  name: 'PosterComponent',
  props: {
    data: {
      type: Array,
      required: true
    },
    speed: {
      type: Number,
      default: 7000
    }
  },
  data () {
    return {
      currentIndex: 0,
      lazyLoader: false,
      timer: null,
    }
  },
  mounted () {
    this.lazy()
    this.start()
  },
  computed: {
    hasHoverSlot() {
      return !!this.$slots.hover
    }
  },
  methods: {
    lazy () {
      this.lazyLoader = new LazyLoad({
        class_loaded: 'lazy__silentloaded',
        class_loading: 'lazy__loading',
        elements_selector: '.lazy',
        load_delay: 0,
        use_native: false,
        unobserve_entered: true,
      })
    },
    start () {
      this.timer = setInterval(this.next, this.speed)
    },
    next () {
      this.currentIndex = this.currentIndex < (this.data.length) - 1 ? this.currentIndex += 1 : 0
    },
  }
}
</script>

