<template>
  <form class="" ref="registration" accept-charset="UTF-8" method="post" @submit.prevent="onSubmit" :action="route">
    <input type="hidden" name="_token" :value="token" />
    <div class="property property--full">
      <label class="property__name" for="email">Nome e cognome</label>
      <div class="property__value">
        <input type="text" class="property__text-box" data-vv-validate-on="change" v-validate="'required|alpha_spaces'" name="nominativo" v-model="user.name" />
      </div>
      <div class="property__error" v-show="errors.has('nominativo')">{{ errors.first('nominativo') }}</div>
    </div>
    <div class="property property--full">
      <label class="property__name" for="email">Email</label>
      <div class="property__value">
        <input type="text" class="property__text-box" data-vv-validate-on="change" v-validate="'required|email'" name="email" v-model="user.email" />
      </div>
      <div class="property__error" v-show="errors.has('email')">{{ errors.first('email') }}</div>
    </div>
    <div class="property property--full">
      <label class="property__name">Password</label>
      <div class="property__value">
        <input :type="pswdisibility ? 'text' : 'password'" class="property__text-box" data-vv-validate-on="change" v-validate="'required|length:8'" name="password" v-model="user.password" />
        <span class="property__icon-inside" :class="{'icon--crossed': pswdisibility}" @click="passwordVisibility()"><svgicon name="eye" class="icon icon--darkgrey"></svgicon></span>
      </div>
      <div class="property__error" v-show="errors.has('password')">{{ errors.first('password') }}</div>
    </div>
    <hr class="property--divider" />
    <div class="property property--full">
      <div class="property__checkbox">
        <input type="checkbox" id="newsletter" class="property__checkbox__input">
        <label class="property__checkbox__box" for="newsletter">
          <div class="property__checkbox__indicator"></div>
          <div class="property__checkbox__label">Desidero ricevere newsletter etc...</div>
        </label>
        <div class="property__error" v-show="errors.has('password')">{{ errors.first('password') }}</div>
      </div>
    </div>
    <hr class="property--divider" />
    <div class="property property--full text-center">
      <div class="property__error" v-if="logs">{{ logs }}</div>
    </div>
    <div class="property property--full property--footer text-center">
      <button class="button button--next">
        Registrati
      </button>
    </div>
  </form>
</template>

<script>

  export default {

    inherit: true,
    name: 'RegistrationForm',
    props: {
      route: '',
      token: '',
      logs: '',
    },
    data() {
      return {
        pswdisibility: false,
        user: {
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          newsletter: 0,
          stats: 0,
          partner: 0,
        },
        privacy: false
      }
    },

    methods: {
      onSubmit: function(e) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$refs.login.submit()
          }
        })
      },
      passwordVisibility() {
        this.pswdisibility = !this.pswdisibility
      }
    },
  }

</script>
