<template>
  <a class="shopping-bag" @click="openCart()">
    <svgicon name="shopping-bag" class="icon"></svgicon>
    <span class="badge badge--success" v-if="counter > 0">{{ counter }}</span>
  </a>
</template>

<script>

  import { eventsbus } from '../mixins/EventsBus.js'

  export default {

    name: 'Cart',
    data () {
      return {
        el: {},
        cart: this.$cookies.get(process.env.CART_REMOTE_COOKIE_ITEMS),
      }
    },
    mounted () {
      eventsbus.$on('AddedToCart', function (e) {
        this.el = JSON.parse(e)
      }.bind(this))
    },
    computed: {
      counter: function () {
        return this.el.counter ? this.el.counter : this.cart
      },
    },
    methods: {
      openCart () {
        eventsbus.$emit('ShowCart')
      },
    }
  }

</script>
