<template>
  <form name="newsletter_subscribe" accept-charset="UTF-8" method="post" @submit.prevent="frmSubmit">
    <input class="border-1 black rounded-full p-x-32 p-y-12 m-y-20 placeholder-black-200"
           type="email"
           v-model="email"
           placeholder="Indirizzo email"
           value=""/>
    <p class="text-xs leading-normal m-x-auto max-w-80vw lg:m-x-0 lg:max-w-full">
      Cliccando ci autorizzi al trattamento dei dati inseriti secondo le finalità indicate nella <a
      href="https://www.lonelyplanetitalia.it/legal/privacy">informativa sulla Privacy</a>. Potrai disicriverti in ogni
      momento cliccando sul link presente nella newsletter.
    </p>
    <button type="submit"
            :disabled="!email"
            class="inline-block p-x-24 p-y-12 m-t-24 text-sm font-semibold rounded-full transition-all duration-300 bg-blue-500 text-white cursor-pointer">
      Iscriviti
    </button>
    <loading :active.sync="this.isLoading"
             :can-cancel="this.canCancel"
             :is-full-page="this.isFullPage"
             color="#297cbb"></loading>
    <generic-modal-dialog :message="popup_message"
                          title="Iscrizione alla Newsletter"
                          :show="showPopup"></generic-modal-dialog>
  </form>
</template>

<script>
import { eventsbus } from '../mixins/EventsBus.js'

export default {
  inherit: true,
  name: 'NewsletterSubscribe',
  props: {
    callback: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      isFullPage: false,
      canCancel: false,
      email: '',
      showPopup: false,
      popup_message: ''
    }
  },
  mounted () {
    eventsbus.$on('generic-modal-closed', function () {
      this.showPopup = false
    }.bind(this))
  },
  methods: {
    frmSubmit: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoading = true
          axios.post(this.callback, { email: this.email }).then((response) => {
            this.isLoading = false
            this.popup_message = response.data.msg
            this.showPopup = true
          })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
  },
}

</script>
