<template>
  <div class="block">
    <div :class="classify">
      <slot></slot>
    </div>
    <div class="flex justify-end cursor-pointer m-t-8"
         @click.prevent="toggle">
      <a class="text-black-400 hover:text-blue-500 hover:underline flex flex-row items-center gap-x-4">
        <template v-if="isOpen">
          <span class="text-xs m-b-1">
            mostra meno ...
          </span>
          <svg class="w-16 h-16">
            <use xlink:href="#circle-chevron-up"></use>
          </svg>
        </template>
        <template v-else>
          <span class="text-xs m-b-1">
            ... visualizza tutto
          </span>
          <svg class="w-16 h-16">
            <use xlink:href="#circle-chevron-down"></use>
          </svg>
        </template>
      </a>
    </div>
  </div>

</template>

<script>

export default {
  name: 'LineClamp',
  props: {
    lines: {
      required: false,
      default: 3
    },
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    classify: function () {
      return !this.isOpen ? 'line-clamp-' + this.lines : 'line-clamp-50'
    },
  },
  methods: {
    toggle: function () {
      this.isOpen = !this.isOpen
    }
  }
}

</script>
