/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shopping-bag': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M477.511 491.424l-26.48-348.832c-.624-8.336-7.584-14.784-15.952-14.784H76.391c-8.368 0-15.328 6.448-15.952 14.8l-26.704 352a16.013 16.013 0 0 0 4.224 12.08 15.996 15.996 0 0 0 11.728 5.12h412.432c8.832 0 16-7.168 16-16.016 0-1.504-.208-2.976-.608-4.368zM66.935 479.808l24.288-320h329.024l24.288 320h-377.6z"/><path pid="1" d="M255.735 0c-70.576 0-128 57.44-128 128.032v63.776c0 8.832 7.168 16 16 16s16-7.168 16-16v-63.776c0-52.96 43.056-96.032 96-96.032s96 43.072 96 96.032v63.776c0 8.832 7.168 16 16 16s16-7.168 16-16v-63.776C383.735 57.44 326.311 0 255.735 0z"/>'
  }
})
