/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-share-pinterest': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" _fill="#C92619" d="M177.7 206.3c0-10 1.2-19 3.6-27.2 2.4-8.1 6.3-15.7 11.8-22.6 4.8-6.6 10.4-11.8 16.7-15.4 6.3-3.6 13.1-5.4 20.4-5.4 5.7 0 10.9.9 15.4 2.7 4.5 1.8 8.4 4.8 11.8 9.1 3 3.9 5.4 8.4 7.2 13.4 1.8 5 2.7 10.5 2.7 16.5 0 3.6-.4 7.6-1.1 12-.8 4.4-1.9 9.1-3.4 14.3-1.2 5.4-2.6 11.2-4.1 17.4-1.5 6.2-3.5 12.8-5.9 19.7-2.1 7.2-4.1 13.6-5.9 19-1.8 5.4-3.2 10.3-4.1 14.5-1.8 7.2-2 14-.7 20.4 1.4 6.3 4.3 12.2 8.8 17.7 4.5 4.8 9.7 8.6 15.6 11.3 5.9 2.7 12.4 4.1 19.7 4.1 12.7 0 24.2-3.5 34.6-10.6 10.4-7.1 19.7-17.7 27.8-31.9 8.1-14.5 14.3-30.3 18.3-47.5 4.1-17.2 6.1-36.1 6.1-56.6 0-15.4-2.5-29.4-7.5-42.1s-12.4-24-22.4-34c-10-9.4-22-16.5-36-21.5s-30.1-7.5-48.2-7.5c-20.2 0-38.5 3.2-54.8 9.5-16.3 6.3-30.6 16.1-43 29.4-12.7 12.7-22.2 26.7-28.5 42.1-6.3 15.4-9.5 32.1-9.5 50.2 0 10.9 1.5 20.8 4.5 29.9 3 9.1 7.5 17.2 13.6 24.4 2.1 2.1 3.5 4.5 4.1 7.2.6 2.7.7 5.4.5 8.1-.9 2.1-1.8 5-2.7 8.6-.9 3.6-1.8 8-2.7 13.1-.3 1.8-.9 3.3-1.8 4.5-.9 1.2-2.1 2.1-3.6 2.7-1.2.3-2.5.5-3.8.5s-2.8-.1-4.3-.5c-9.4-3.9-17.5-9.1-24.4-15.6-6.9-6.5-13-14.6-18.1-24.2-4.8-9.4-8.5-19.5-10.9-30.3-2.4-10.9-3.6-22.5-3.6-34.9 0-8.1.7-16.1 2-24 1.4-7.8 3.4-15.8 6.1-24s6-16.1 9.7-23.8c3.8-7.7 8.4-15.2 13.8-22.4 5.4-7.5 11.5-14.6 18.1-21 6.6-6.5 13.6-12.7 20.8-18.8 7.5-5.7 15.9-10.9 25.1-15.6 9.2-4.7 19.5-8.8 31-12.4 10.9-3 22.1-5.4 33.7-7.2 11.6-1.8 23.6-2.7 36-2.7 13 0 25.4 1.1 37.3 3.4 11.9 2.3 23.3 5.7 34.2 10.2 10.9 3.9 21 8.8 30.3 14.7 9.4 5.9 17.7 12.4 24.9 19.7 7.5 7.5 14.2 15.5 19.9 23.8 5.7 8.3 10.9 17.3 15.4 26.9 3.9 9.4 7 19 9.3 29s3.4 19.9 3.4 29.9c0 27.2-3.4 52-10.2 74.5-6.8 22.5-17.3 42.6-31.5 60.4-13.9 18.1-29.7 31.5-47.5 40.3-17.8 8.8-37.6 13.1-59.3 13.1-7.2 0-14.3-.7-21-2.3-6.8-1.5-13.4-4.1-19.7-7.7-6.3-3-11.8-6.6-16.3-10.6s-8.1-8.7-10.9-13.8c-4.8 21.1-8.8 38-12 50.7-3.2 12.7-5.5 21.1-7 25.3-2.7 10.3-7.2 21.7-13.6 34.4s-14.8 26.6-25.3 41.6h-19c-1.8-18.4-2.5-35.3-2-50.7.5-15.4 2-29.3 4.8-41.6l35.3-149.4c-2.7-5.7-4.8-12.3-6.1-19.7-1.2-7.5-1.9-15.7-1.9-24.7z"/>'
  }
})
