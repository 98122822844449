<template>
  <div>
    <button  class="button button--solid button--offbeat" v-show="!mouseover" @mouseenter.prevent="toggleMouseover">
      <svgicon name="user" style="margin-bottom: 0.2rem; margin-right: 0.2rem"></svgicon> 
      <span style="text-transform: lowercase">{{label}}</span>
    </button>
    <button class="button button--solid" style="text-transform: capitalize" v-show="mouseover" @mouseleave.prevent="toggleMouseover" @click.prevent="confirm">
      <svgicon name="user" style="margin-bottom: 0.2rem; margin-right: 0.2rem"></svgicon>
      Cambia <span style="text-transform: lowercase">{{label}}</span>
    </button>
    <div v-if="showconfirm">
      <transition name="modal-fade">
        <div class="modal__backdrop">
          <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <div class="modal__container">
              <section class="modal__body" id="modalDescription">
                <header class="modal__header" id="modalTitle">
                  Cambia e-mail
                </header>
                <button class="modal__close" @click.prevent="close()" v-if=" !((response.err == false) && (response.msg != '')) ">
                  <svgicon name="xclose" class="icon"></svgicon>
                </button>
                <div class="grid grid__row">
                  <div class="width-full">
                    <form name="update_email_frm" accept-charset="UTF-8" method="post" @submit.prevent="updateaccount" v-if=" (response.err == false) && (response.msg == '') ">
                      <input type="text" v-validate="'required|email'" data-vv-name="E-mail" v-model="email" placeholder="Il tuo indirizzo e-mail" name="email" class="property__text-box" style="width: 99%; max-width: 99%;" />
                      <div class="property__error" v-show="errors.has('E-mail')">{{ errors.first('E-mail') }}</div>
                      <input type="text" v-validate="validationRuleNewMail()" data-vv-name="Nuovo indirizzo e-mail" v-model="new_email" placeholder="Nuovo indirizzo e-mail" name="new_email" class="property__text-box" style="width: 99%; max-width: 99%; margin-top: .5rem;" />
                      <div class="property__error" v-show="errors.has('Nuovo indirizzo e-mail')">{{ errors.first('Nuovo indirizzo e-mail') }}</div>
                      <input type="text" v-validate="validationRuleNewMailConfirm()" data-vv-name="Conferma nuovo indirizzo e-mail" v-model="new_email_confirm" placeholder="Conferma indirizzo e-mail" name="new_email_confirm" class="property__text-box" style="width: 99%; max-width: 99%; margin-top: .5rem;" />
                      <div class="property__error" v-show="errors.has('Conferma nuovo indirizzo e-mail')">{{ errors.first('Conferma nuovo indirizzo e-mail') }}</div>
                      
                      <button class="button button--solid button--small mt-2">Conferma</button>  
                    </form>
                    <div v-else-if="response.err == true">
                      {{ response.msg }} <br/>
                      <button class="button button--solid button--small mt-2" @click.prevent="retry()">Riprova</button>
                    </div>
                    <div v-else>
                      {{ response.msg }} <br/>
                      <button class="button button--solid button--small mt-2" @click.prevent="refresh()">Fatto</button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </transition>

      <loading :active.sync="this.isWorking"
             :can-cancel="false"
             :is-full-page="true"
             color="#297cbb"></loading>

    </div>

    
    
  </div>
</template>

<script>
  import ValidateLocaleIt from 'vee-validate/dist/locale/it'
  export default {
    name: 'UpdateEmailAccount',
    props: {
      callback: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showconfirm: false,
        email: "",
        new_email: "",
        new_email_confirm: "",
        isWorking: false,
        mouseover: false,
        response: { 'err': false, 'msg': ''}
      }
    },
    mounted: function() {
      this.$validator.localize('it', ValidateLocaleIt)
    },
    methods: {
      confirm: function() {
        this.showconfirm = true
      },
      updateaccount: function() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.isWorking = true
            axios.post(this.callback, { email: this.email, new_email: this.new_email }).then((response) => {
              this.isWorking = false
              this.response = response.data
            })
            .catch((error) => {
              console.log(error)
            })
          }
        })
      },
      close() {
        this.showconfirm = false
      },
      retry() {
        this.response = { 'err': false, 'msg': ''}
      },
      refresh() {
        location.href = location.href
      },
      toggleMouseover() {
        this.mouseover = !this.mouseover
      },
      validationRuleNewMail() {
        return 'required|email|is_not:' + this.email
      },
      validationRuleNewMailConfirm() {
        return 'required|email|is:' + this.new_email
      },
     
    },
  }

</script>
