/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cutlery': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M353.285 254.273c23.8 0 47.613-9.066 65.738-27.187L512 134.106 377.898 0l-92.98 92.98c-26.031 26.032-33.367 63.79-22.012 96.426l-22.972 21.715L47.148 18.336 34.781 30.69c-46.375 46.383-46.375 121.86 0 168.235l105.973 105.972L38.96 401.156l71.883 71.895L222.117 355.25l111.375 117.793 71.895-71.887-119.77-113.133 36.828-38.988a93.203 93.203 0 0030.84 5.238zm-47.164-140.082l71.774-71.773 16.203 16.203-55.496 55.496 21.203 21.207 55.5-55.5 18.36 18.36-54.99 54.988 21.208 21.207 54.988-54.988 14.715 14.714-71.777 71.778c-24.551 24.55-64.5 24.55-89.059 0l-2.629-2.63c-24.555-24.562-24.555-64.507 0-89.062zm27.969 315.836l-23.281-24.632 26.91-26.91 24.644 23.28zm-43.906-46.425l-79.07-83.641 21.109-21.113 83.683 79.043zm-62.657-142.473l-54.062 54.062L55.992 177.723C24.305 146.03 21.566 96.199 47.766 61.37zM110.238 430.027l-28.261-28.273 24.628-23.277 26.915 26.91zm43.883-46.457l-.004.004-25.715-25.715 33.567-31.75 11.496 11.496 16.426-16.421 11.586 12.246zm109.695-116.14l-10.379-9.797 16.504-16.504-8.785-8.785 16.703-15.805a92.447 92.447 0 007.059 7.918l2.629 2.629a91.69 91.69 0 007.808 6.957zm0 0"/>'
  }
})
